import React from "react";
import { Box, Image } from "@chakra-ui/react";

export default function SingleImageBlocker(record) {
  return (
    <Box textAlign="center">
      <Image src={record?.image?.url} alt="" />
    </Box>
  );
}
