/* Built In Imports */
/* External Imports */
import cache from 'memory-cache';

/* Internal Imports */
/* Components */
/* Services */
import { consoleLog } from '@components/Utility/Shared/SharedService';
import { HttpService } from './HttpService';

/**
 *
 */
export class BaseService {
  http;

  constructor(url_prefix = '', rootUrl, defaultConfig) {
    this.http = new HttpService(url_prefix, rootUrl, defaultConfig);
  }

  async getAll(path, isCache, axiosConfig) {
    const cachedResponse = cache.get(path);
    if (cachedResponse) {
      return cachedResponse;
    }
    let response;
    try {
      response = await this.http.get(`${path}`, axiosConfig);
      // const hours = 24;
      // cache.put(url, response, hours * 1000 * 60 * 60);
    } catch (e) {
      consoleLog(e);
    }
    return response;
  }

  async get(id, isCache, axiosConfig) {
    const cacheUrl = `${this.http.rootUrl}${
      this.http.url_prefix
    }${id}?${new URLSearchParams(axiosConfig.params).toString()}`;
    const cachedResponse = cache.get(cacheUrl);
    if (isCache && cachedResponse) {
      return cachedResponse;
    }
    consoleLog(`Calling >> ${cacheUrl} << on ${new Date().toString()}`);
    let response = [];
    try {
      response = await this.http.get(`${id}`, axiosConfig);
      const hours = 24;
      cache.put(cacheUrl, response, hours * 1000 * 60 * 60);
    } catch (e) {
      consoleLog(e);
    }
    return response;
  }

  async post(path, body) {
    let response;
    try {
      response = await this.http.post(`${path}`, body);
    } catch (e) {
      consoleLog(e);
    }
    return response;
  }

  async update(id, body) {
    let response;
    try {
      response = await this.http.put(`/${id}`, body);
    } catch (e) {
      consoleLog(e);
    }
    return response;
  }

  async delete(id) {
    let response;
    try {
      response = await this.http.remove(`/${id}`);
    } catch (e) {
      consoleLog(e);
    }
    return response;
  }
}
