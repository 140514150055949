/* Built In Imports */
/* External Imports */
import forEach from 'lodash/forEach';

/* Internal Imports */
/* Components */
import config from '@config';

export const consoleLog = (msg, ...manyMoreArgs) => {
  const type = 'log';
  if (config.IS_LOG) {
    switch (type) {
      case 'log':
        console.log(msg);
        if (manyMoreArgs?.length) {
          console.group('');
          forEach(manyMoreArgs, function (msg) {
            console.log(msg);
          });
          console.groupEnd();
        }
        break;
      case 'info':
        console.info(msg);
        break;
      case 'warn':
        console.warn(msg);
        break;
      case 'error':
        console.error(msg);
        break;
      default:
        console.log(msg);
    }
  }
};

export const consoleGroup = (heading, msgArray) => {
  if (config.IS_LOG) {
    console.group(heading);
    forEach(msgArray, function (msg) {
      consoleLog(msg);
    });
    console.groupEnd();
  }
};

export const searchByFieldId = (sectionContent, fieldId) => {
  return sectionContent?.find(el => el?.fieldId === fieldId);
};

export const searchByModelApiKey = (sectionContent, _modelApiKey) => {
  return sectionContent.find(el => el._modelApiKey === _modelApiKey);
};

export const shuffleArray = array => {
  for (let i = array.length - 1; i > 0; i--) {
    const j = Math.floor(Math.random() * (i + 1));
    [array[i], array[j]] = [array[j], array[i]];
  }
  return array;
};

export const isInViewport = element => {
  const rect = element.getBoundingClientRect();
  return (
    rect?.top >= 0 &&
    rect?.left >= 0 &&
    rect?.bottom <=
      (window.innerHeight || document.documentElement.clientHeight) &&
    rect.right <= (window.innerWidth || document.documentElement.clientWidth)
  );
};

export const isVisInViewport = element => {
  const rect = element?.getBoundingClientRect();
  // console.log(
  //   'rect',
  //   // rect,
  //   window.innerHeight,
  //   rect.top + rect?.height
  //   // rect.bottom,
  //   // rect?.height,
  //   // (rect?.height * 90) / 100,
  //   // rect?.top <= (rect?.height * 90) / 100 &&
  //   //   rect?.top + rect?.height > 0 &&
  //   //   rect?.left >= 0
  // );
  return rect
    ? window.innerHeight / 1.5 > rect.top &&
        rect?.top + rect?.height > 0 &&
        rect?.left >= 0
    : false;
};

const uniqueLanguage = [];
config.msrlanguage?.map(locale => {
  uniqueLanguage?.push(locale);
});
export const getLocale = (region, language, site) => {
  let setregion = '';
  if (site === 'msr') {
    uniqueLanguage.find(itemlist => {
      if (itemlist.language_sf === language) {
        setregion = itemlist?.region_sf;
      }
      return;
    });
    return setregion === '' ? language : `${language}-${setregion}`;
  } else {
    return region === 'global'
      ? language
      : `${language}-${region?.toUpperCase()}`;
  }
};

if (typeof window !== 'undefined') {
  document.addEventListener('DOMContentLoaded', function () {
    const lazyImages = [].slice.call(
      document.querySelectorAll('img.lazy-image')
    );

    if ('IntersectionObserver' in window) {
      const lazyImageObserver = new IntersectionObserver(function (entries) {
        entries.forEach(function (entry) {
          if (entry.isIntersecting) {
            const lazyImage = entry.target;
            lazyImage.src = lazyImage.dataset.src;
            // lazyImage.srcset = lazyImage.dataset.srcset;
            lazyImage.classList.remove('lazy');
            lazyImageObserver.unobserve(lazyImage);
          }
        });
      });

      lazyImages.forEach(function (lazyImage) {
        lazyImageObserver.observe(lazyImage);
      });
    }
  });
}
