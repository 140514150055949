/* Built In Imports */
import { useRouter } from 'next/router';
/* External Imports */
import { Box } from '@chakra-ui/react';

/* Internal Imports */
/* Components */
/* Services */

/**
 * Renders the Mid Container component
 *
 * @param {object} props - Layout Props
 * @param props.w
 * @param props.mx
 * @param props.my
 * @param props.px
 * @param props.children
 * @returns {ReactElement} Mid Container component
 */
const MidContainer = ({ w, mx, my, px, children, ...props }) => {
  const router = useRouter();
  const usdonationUrl = router.asPath.includes('/us/en/donation');
  return (
    <Box as="section"
      w={{ base: '100%' }}
      maxW={usdonationUrl ? '100%' : w || '944px'}
      mx={mx || 'auto'}
      my={my}
      className="embedBox"
      px={px}
      {...props}
      marginInlineStart="auto"
      marginInlineEnd="auto"
    >
      {children}
    </Box>
  );
};
export default MidContainer;
