/* Built In Components */
import { Fragment, useEffect } from 'react';

/* External Components */
/* Components */
// Internal Imports
/* Services */
/* Styles */

/**
 * Renders the Build Time Comment Component
 * @param {Object} props
 * @returns {ReactElement} - Build Time Comment Component
 */
const BuildTimeComment = props => {
  useEffect(() => {
    const comment = document.createComment(
      `Build was done at ${process.env.BUILD_DATE} ${process.env.BUILD_TIME}`
    );
    document.appendChild(comment);
  }, []);
  return <Fragment />;
};

export default BuildTimeComment;
