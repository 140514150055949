/* Built In Imports */

/* External Imports */
import axios from 'axios';
import forEach from 'lodash/forEach';
import qs from 'qs';

/* Internal Imports */
/* Components */
import config from '@config';
import { tryGetPreviewData } from 'utils';

/* Services */
import { getLocale } from '@msr/components/Utility/Shared/SharedService';
import { IsoBackendHttpService } from 'utils/IsoBackendHttpService';
import { getLatestBuild } from './commonService';

let isCache = false;
const API_PREFIX = '/content/fetchiso';

export const getLandingPaths = async (
  level,
  url,
  contentType,
  notMatchText,
  matchType
) => {
  const data = config.BUILD_URLS?.MSR?.enabled
    ? config.BUILD_URLS.MSR
    : await getLatestBuild(
        process.env.NEXT_PUBLIC_BUILD_ID ||
          process.env.env_var_msr_build_id ||
          process.env.env_var_build_id
      );
  let urlArr = filterBuildUrls(
    contentType || 'mahashivratri_child',
    level,
    url,
    notMatchText,
    data,
    matchType
  );
  return urlArr ? urlArr : [];
};

export const filterBuildUrls = (
  section,
  level,
  url,
  notMatchText,
  data,
  matchType = 'contains'
) => {
  let urls = [];
  // only urls with these patterns will be built
  // let isRegexFilters = ['/en/wisdom/article', '/sg/en/'];
  // to build everything , keep isRegexFilters empty
  // let isRegexFilters = [];
  const levelUrlArr = {
    lvl0: 4,
    L1: 5,
    L2: 6,
    L3: 7,
    L4: 8,
  };
  if (level === 'lvl0' || level === 'home_page') {
    if (data?.[level]?.length) {
      forEach(data[level], function (data) {
        if (data?.length < 200 && data.indexOf(url) > -1) {
          if (data?.split('/')?.length === levelUrlArr[level]) {
            let isAvailable = false;
            if (notMatchText && data.indexOf(notMatchText) > -1) {
              return;
            } else if (Array.isArray(notMatchText)) {
              // consoleLog('Array', notMatchText);
              forEach(notMatchText, function (link) {
                if (link && data.indexOf(link) > -1) {
                  isAvailable = true;
                }
              });
            }
            if (!isAvailable) {
              // consoleLog('data', data);
              urls.push(encodeURI(data));
            }
          }
        }
      });
    }
  } else if (
    level === 'L1' ||
    level === 'L2' ||
    level === 'L3' ||
    level === 'L4'
  ) {
    let buildSection = data && data[section];
    if (buildSection?.[level]?.length) {
      // consoleLog('if buildSection', buildSection?.[level]);
      forEach(buildSection[level], function (data, index) {
        if (data?.split('/')?.length === levelUrlArr[level]) {
          let isAvailable = false;
          if (matchType === 'contains') {
            if (data.indexOf(url) > -1) {
              if (notMatchText && data.indexOf(notMatchText) > -1) {
                return;
              } else if (Array.isArray(notMatchText)) {
                // consoleLog('Array', notMatchText, index);
                forEach(notMatchText, function (link) {
                  if (link && data.indexOf(link) > -1) {
                    isAvailable = true;
                  }
                });
              }
              if (!isAvailable) {
                // consoleLog('data', data);
                urls.push(encodeURI(data));
              }
            }
          } else if (matchType === 'startswith') {
            if (data.startsWith(url)) {
              if (notMatchText && data.indexOf(notMatchText) > -1) {
                return;
              } else if (Array.isArray(notMatchText)) {
                // consoleLog('Array', notMatchText, index);
                forEach(notMatchText, function (link) {
                  if (link && data.indexOf(link) > -1) {
                    isAvailable = true;
                  }
                });
              }
              if (!isAvailable) {
                // consoleLog('data', data);
                urls.push(encodeURI(data));
              }
            }
          } else if (matchType === 'endswith') {
            // consoleLog('matchType', matchType, url);
            if (data.endsWith(url)) {
              if (notMatchText && data.indexOf(notMatchText) > -1) {
                return;
              } else if (Array.isArray(notMatchText)) {
                // consoleLog('Array', notMatchText, index);
                forEach(notMatchText, function (link) {
                  if (link && data.indexOf(link) > -1) {
                    isAvailable = true;
                  }
                });
              }
              if (!isAvailable) {
                // consoleLog('data', data);
                urls.push(encodeURI(data));
              }
            }
          }
        }
      });
    }
  } else if (section === 'home_page') {
    const buildSection = data && data[section];
    if (buildSection?.length) {
      forEach(buildSection, function (data) {
        urls.push(encodeURI(data));
      });
    }
  }
  // consoleLog('urls', urls);
  return urls;
};

export const fetchDetails = async (region, lang, slug, previewData) => {
  if (previewData?.preview) {
    return await tryGetPreviewData(previewData, getLocale(region, lang, 'msr'));
  }
  const pageData = await new IsoBackendHttpService().get(
    `${API_PREFIX}/content`,
    !isCache,
    {
      params: {
        format: 'json',
        sitesection: 'msr',
        slug: slug ? `${slug}` : `/`,
        region,
        lang,
      },
    }
  );
  return pageData;
};

export const fetchShivaBlogs = async (topic,lang,contentType,start = 0,limit = 12,sortby = 'oldest') => {
  const pageData = await new IsoBackendHttpService().get(
    `${API_PREFIX}/content`,
    !isCache,
    {
      params: {
        format: 'json',
        sitesection: 'msr',
        slug: 'shiva',
        topic,
        lang,
        contentType,
        sortby,
        start,
        limit,
      },
    }
  );
  return pageData;
};

// Get Likes
export const fetchLikes = async uuid => {
  const response = await new IsoBackendHttpService().get(
    `${config.FACADE_BASE_PATH}/content/likes/read`,
    !isCache,
    {
      params: {
        format: 'json',
        sitesection: 'msr',
        uuid,
      },
    }
  );
  return response;
};

// Post Likes
export const postLikes = async (uuid, action, contenttype, language) => {
  const response = await axios({
    method: 'POST',
    headers: {
      'Content-Type': 'application/x-www-form-urlencoded',
    },
    url: `${config.FACADE_BASE_PATH}/content/likes/write`,
    data: qs.stringify(uuid, action, contenttype, language),
  });
  return response?.data;
};

export const addSubscription = async formValues => {
  const request = await axios({
    method: 'POST',
    url: `${config.FACADE_BASE_PATH}/content/subscription/add`,
    data: formValues,
  });

  return request?.data;
};

export const fetchLiveStreamVideo = async lang => {
  try {
    const response = await axios.get(
      `${config.MSR_VIDEO_BUCKET_URL}/${lang}.json`
    );
    return response;
  } catch (error) {
    console.error(error);
  }
};

export const fetchPopUpData = async (url, isMobile) => {
  const fetchUrl = `${config.MSR_VIDEO_BUCKET_URL}/popup/msr-${
    isMobile ? 'mobile' : 'desktop'
  }.json`;
  let pageData = await fetch(fetchUrl);
  if (pageData.status === 500) {
    return { status: 'failure' };
  } else {
    pageData = await pageData.json();
  }
  return pageData;
};
