/* Built In Imports */
import NextLink from 'next/link';
import { useRouter } from 'next/router';
import { useState } from 'react';

/* External Imports */
import {
    Box,
    Image,
    Input,
    Link,
    ListItem,
    Stack,
    UnorderedList,
    useDisclosure
} from '@chakra-ui/react';
import filter from 'lodash/filter';

/* Internal Imports */
/* Components */
import MenuLI from '@msr/components/UI/MenuLI';
import { refineUrlDomain } from '@msr/components/Utility/utils';

/* Services */
/* Styles */
import classes from '@styles/components/Header.module.css';

/**
 * Renders the Desktop Menu component
 *
 * @param {object} headerMenuData - data
 * @param {string} region - country
 * @param {string} language language
 * @param {boolean} isStaticPage
 * @returns {ReactElement} Desktop Menu component
 */

const DesktopMenu = ({
  headerMenuData,
  languagelisting,
  region,
  language,
  isStaticPage,
  deskSearchHandler,
  isOpenDeskSearch,
}) => {
  const { isOpen, onOpen, onClose } = useDisclosure();
  const router = useRouter();
  const url = router.asPath;
  const [searchInput, setSearchInput] = useState('');

  // const generateUrl = itemurl => {
  //   let linkURL;
  //   if (itemurl.indexOf('https://') >= 0) {
  //     linkURL = itemurl;
  //   } else if (itemurl != '#') {
  //     linkURL = `/mahashivratri/${itemurl}`;
  //   }
  //   return linkURL;
  // };
  const handleSearchInputChange = e => {
    setSearchInput(e.target.value);
  };

  const handleSearchInputSubmit = () => {
    localStorage.setItem('searchInput', searchInput);
  };

  const renderMenu = () => {
    if (headerMenuData && headerMenuData?.allIsoMainMenus?.length > 0) {
      // create array for second level menu
      const secondlevelMenu = headerMenuData.allIsoMainMenus;
      const secondlevelMenuData = [];
      secondlevelMenu.map(item => {
        const subMenuItems = item.submenuItems;
        secondlevelMenuData[item.key] = subMenuItems;
      });

      return headerMenuData?.allIsoMainMenus.map((menuItem, index) => {
        const subMenuItems = [];
        let subSubMenuLength;
        let subURL;
        subURL = refineUrlDomain(menuItem?.url, '', language);

        if (menuItem.submenuItems && menuItem.submenuItems.length > 0) {
          menuItem.submenuItems.map((item, ind) => {
            const subSubMenuItems = [];
            if (item.submenuItems && item.submenuItems.length > 0) {
              item.submenuItems.map((item, i) => {
                let subSubURL;
                subSubURL = refineUrlDomain(item?.url, '', language);
                subSubMenuItems.push(
                  <MenuLI
                    key={i}
                    textFormat={item.textFormat}
                    text={item.title}
                    subtext={item.subtext}
                    url={refineUrlDomain(subSubURL, '', language)}
                    className=""
                  ></MenuLI>
                );
              });
            }

            const listItem =
              subSubMenuItems.length > 0 ? (
                ((subSubMenuLength = 1),
                (
                  <>
                    <Box key={ind} className="sublevelmenu">
                      <UnorderedList key={ind} className="submenutitle">
                        <MenuLI
                          textFormat={item.textFormat}
                          text={item.title}
                          subtext={item.subtext}
                          url={refineUrlDomain(item?.url, '', language)}
                          key={item.title}
                        />
                        {subSubMenuItems}
                      </UnorderedList>
                    </Box>
                  </>
                ))
              ) : (
                <>
                  <MenuLI
                    text={item?.title}
                    subtext={item?.subtext}
                    url={refineUrlDomain(item?.url, '', language)}
                    key={ind}
                    className=""
                    textFormat={item?.textFormat}
                  />
                </>
              );
            subMenuItems.push(listItem);
          });
        }

        return <>
          {menuItem.key != 'language-switcher' &&
            menuItem.key != 'search' &&
            menuItem.key != 'msr-logo' &&
            menuItem.key != 'donate-to-annadanam' &&
            menuItem.key != 'register-for-rudraksha' && (
              <MenuLI
                key={index}
                text={menuItem.title}
                url={refineUrlDomain(subURL, '', language)}
                height="93px"
              >
                {subMenuItems.length > 0 && (
                  <Box display="inline-flex" width="6px">
                    <svg
                      width="8"
                      height="6"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                      transform="scale(1.4,1.4)"
                    >
                      <path
                        d="M3.634 3.677a.8.8 0 0 1-1.268 0L.527 1.288A.8.8 0 0 1 1.161 0H4.84a.8.8 0 0 1 .634 1.288l-1.84 2.389Z"
                        fill="#ECB11B"
                      />
                    </svg>
                  </Box>
                )}
                {subMenuItems.length > 0 && (
                  <Box
                    className="drop_block"
                    display="none"
                    position="absolute"
                    top="92px"
                    background="#FFF7E9"
                    padding="16px"
                  >
                    <Box className="block coloum" width="202px">
                      <UnorderedList
                        className="submenu"
                        marginInlineStart="0"
                      >
                        {subMenuItems}
                      </UnorderedList>
                    </Box>
                  </Box>
                )}
              </MenuLI>
            )}
          {menuItem.key == 'language-switcher' && (
            <ListItem className="languagelisting">
              {languagelisting()}
            </ListItem>
          )}
          {menuItem.key == 'search' && (
            <ListItem className="searchicon">
              <Box
                as="span"
                cursor="pointer"
                w="40px"
                h="67px"
                bgPos="50% 40%"
                bgRepeat="no-repeat"
                m="12px 15px 0 0"
                p="3px 0 48px 0"
                display="block"
                borderBottom="solid 7px transparent"
                bgImg={menuItem?.icon?.url}
                onClick={deskSearchHandler}
              />
            </ListItem>
          )}
          {menuItem.key == 'donate-to-annadanam' && (
            <ListItem className="nohover btn-donate">
              <NextLink
                href={refineUrlDomain(menuItem?.url, region, language)}
                passHref
                scroll={false}
                legacyBehavior>
                <Link
                  className="orange-button"
                  display="block"
                  fontWeight={'500'}
                  fontFamily={'FedraSansStd-book'}
                  fontSize={'12px'}
                  bgColor="#ECB11B"
                  h={'44px'}
                  textAlign="center"
                  padding={'6px 5px'}
                  borderRadius="5px"
                  color="black"
                  minW="102px"
                  textDecoration="none"
                  lineHeight={'125%'}
                  _hover={{
                    bg: '#DC8513',
                    color: '#000000',
                  }}
                  alignItems="center"
                  scroll={false}
                >
                  {menuItem?.title}
                </Link>
              </NextLink>
            </ListItem>
          )}
          {menuItem.key == 'register-for-rudraksha' && (
            <ListItem className="nohover btn-register">
              <NextLink
                href={refineUrlDomain(menuItem?.url, region, language)}
                passHref
                scroll={false}
                legacyBehavior>
                <Link
                  display="block"
                  fontWeight="500"
                  fontFamily={'FedraSansStd-book'}
                  fontSize={'12px'}
                  textAlign="center"
                  padding={'5px 4px'}
                  borderRadius="5px"
                  borderColor="#ECB11B"
                  borderWidth="1px"
                  color="fulvous"
                  textDecoration="none"
                  minW="130px"
                  lineHeight={'125%'}
                  _hover={{
                    borderColor: 'yellow.100',
                    color: 'yellow.100',
                  }}
                  scroll={false}
                >
                  {menuItem?.title}
                </Link>
              </NextLink>
            </ListItem>
          )}
        </>;
      });
    }
  };

  const searchBox = () => {
    return (
      <>
        <form method="get" title="Search Form" action="/mahashivratri/search">
          <Stack display="block" flexDir="row">
            <Input
              type="search"
              width="94%"
              height="90px"
              pl="145px"
              border="none"
              py="26px"
              placeholder="SEARCH"
              color="#333333"
              fontFamily="FedraSansStd-book"
              fontSize="26px"
              id="q"
              name="q"
              _focus={{ border: 'none' }}
              _placeholder={{
                color: '#D4CBB9',
              }}
              display="inline-block"
            />
            <Box
              display="inline-block"
              width="70px"
              height="20px"
              backgroundImage="url('/assets/images/mobile-menu-close.svg')"
              backgroundSize="20px"
              backgroundRepeat="no-repeat"
              backgroundPosition="center"
              cursor="pointer"
              onClick={deskSearchHandler}
            ></Box>
          </Stack>
        </form>
      </>
    );
  };

  const logoObj = filter(
    headerMenuData?.allIsoMainMenus,
    function (menu, index) {
      return menu?.key === 'msr-logo';
    }
  )[0];

  return (
    <Box
      float="left"
      w="100%"
      h="90px"
      bgColor="#000000"
      mr="auto"
      ml="auto"
      pl="calc((100% - 1300px) / 2)"
      pr="calc((100% - 1300px) / 2)"
      className={`${classes.nav} container`}
      display={{ base: 'none', xl: 'block' }}
    >
      <Box
        position="fixed"
        width="100%"
        top="0"
        backgroundColor="#FFF7E9"
        zIndex="99"
        transition="all 0.6s ease"
        overflow="auto"
        left={` ${isOpenDeskSearch ? '0' : 'inherit'}`}
        display={` ${isOpenDeskSearch ? 'block' : 'none'}`}
      >
        <Box display="block" alignItems="center">
          {searchBox()}
        </Box>
      </Box>
      <Box
        m="0 auto"
        padding="0 15px"
        w="100%"
        maxW="1300px"
        display="flex"
        alignItems="center"
      >
        <Box
          width="152px"
          marginLeft="0"
          height="25px"
          my="26px"
          cursor="pointer"
        >
          <NextLink
            href={refineUrlDomain(logoObj?.url, '', language)}
            passHref
            scroll={false}
            legacyBehavior>
            <Link scroll={false}>
              <Image
                src={logoObj?.icon?.url}
                width="152px"
                maxW="none"
                alt="logo"
                height="24px"
              />
            </Link>
          </NextLink>
        </Box>
        <Box display="flex" ml="5%" className="menu menu_L1">
          {headerMenuData && headerMenuData?.allIsoMainMenus?.length > 0 && (
            <UnorderedList
              margin="0"
              padding="0"
              marginInlineStart="0"
              display="flex"
              alignItems="center"
              maxH="90px"
            >
              {renderMenu()}
            </UnorderedList>
          )}
        </Box>
      </Box>
      <style global jsx>
        {`
          .menu_L1 ul li {
            list-style: none;
            // padding-top: 23px;
          }
          .menu_L1 ul li:last-child a {
            border-right: 0;
          }
          .menu_L1 ul li:hover a {
            text-decoration: none;
          }
          .menu_L1 ul li:hover .drop_block {
            display: block;
          }
          .menu_L1 ul li.languagelisting:hover .languagelist {
            display: block;
          }
          .menu_L1 ul li:nth-child(3) .drop_block .block {
            width: 460px;
          }
          .menu_L1 ul li:nth-child(3) .drop_block .block ul {
            margin-inline-start: 0;
          }
          .menu_L1 .sublevelmenu {
            float: left;
            width: calc(450px / 2);
            padding: 0;
            margin-left: 0;
          }
          .menu_L1 .sublevelmenu:nth-child(2) {
            width: 235px;
          }
          .menu_L1 ul li .drop_block ul li {
            margin-right: 0px;
            margin-left: 0px;
            width: 100%;
            padding: 0px;
            display: block;
          }
          .menu_L1 ul li.languagelisting {
            width: 90px;
            margin-left: 15px;
            // padding-top: 10px;
          }
          .menu_L1 ul li .submenu li:hover {
            background: #f1e0c1;
            border-radius: 5px;
            border-bottom: 4px solid transparent !important;
          }
          .menu_L1 ul li {
            width: 110px;
            margin-right: 10px;
            margin-left: 10px;
            margin: 0;
          }
          .menu_L1 ul li a {
            #padding-right: 8px;
            padding-left: 15px;
            border-right: none;
            // padding-top: 6px;
            // padding-bottom: 23px;
            word-break: break-word;
          }
          // .menu_L1 ul li:last-child {
          //   margin-left: 50px;
          // }
          .menu_L1 ul li:nth-child(2) {
            width: 125px;
          }
          .menu_L1 ul li.searchicon {
            width: 39px;
            margin-right: 0px;
            margin-left: 20px;
            // padding-bottom: 10px;
            max-height: 90px;
          }
          .menu_L1 ul li.searchicon a {
            height: 50px;
          }
          .menu_L1 ul li.nohover {
            margin-left: 5px;
            margin-right: 5px;
            // padding-bottom: 10px !important;
          }
          .menu_L1 ul li.btn-register a {
            color: #ffffff;
          }
          .menu_L1 ul li.btn-register a:hover {
            color: #000000;
            background: #dc8513;
          }
          .menu_L1 ul li.btn-donate {
            width: auto !important;
            max-width: 152px;
          }
          .menu_L1 ul li.btn-donate a {
            display: flex;
            place-content: center;
            align-self: center;
            padding-top: 5px;
          }
          .menu_L1 ul li.searchicon:hover,
          .menu_L1 ul li.nohover:hover,
          .menu_L1 ul li.languagelisting:hover {
            border-bottom: 4px solid transparent;
          }
          .menu_L1 ul li.nohover a {
            border-right: 2px solid var(--chakra-colors-fulvous);
          }
          .menu_L1 ul li {
            display: flex;
            align-items: center;
          }
          .menu_L1 ul li {
            border-bottom: 4px solid transparent;
            // word-break: break-word;
          }
          .menu_L1 ul li:hover {
            border-bottom: 4px solid #ffa735;
            // cursor: pointer;
          }
          .menu_L1 ul li .submenu a {
            color: #000000;
          }
          .menu_L1 ul li .submenu li a {
            padding: 6px 10px;
            display: block;
          }
          .submenutitle {
            // white-space: nowrap;
            overflow: hidden;
          }
          .menu_L1 ul li .drop_block ul.submenutitle li:first-child a {
            color: #988970;
            font-weight: bold;
            cursor: default !important;
          }
          .menu_L1 ul li .drop_block ul.submenutitle li:first-child:hover {
            background: transparent;
          }
        `}
      </style>
    </Box>
  );
};

export default DesktopMenu;
