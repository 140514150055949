/* Built In Imports */
/* External Imports */
/* Internal Imports */
/* Components */
import config from '@config';
import { HttpHeaders } from 'constants/index';
/* Services */
import { BaseService } from './BaseService';

const PREVIEW_URL = config.FACADE_BASE_PATH;
const COMMON_PREFIX = '/content/fetchiso';

/**
 * 
 */
export class PreviewHttpService extends BaseService {
  constructor() {
    super(`${COMMON_PREFIX}`, PREVIEW_URL, {
      params: {},
      headers: {
        [HttpHeaders.PREVIEW_HEADER]: true,
      },
    });
  }
}
