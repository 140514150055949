const DownloadMessage = [
  {
    language: 'en',
    downloadmsgdesktop: 'Right click on Image to Download',
    downloadmsgmobile: 'Press and Hold the Image to Share',
  },
  {
    language: 'hi',
    downloadmsgdesktop: 'इस तस्वीर को डाउनलोड करने के लिए राईट क्लिक करें',
    downloadmsgmobile:
      'इस तस्वीर को शेयर करने के लिए, इसे कुछ समय तक प्रेस करें',
  },
];

export default DownloadMessage;
