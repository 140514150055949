'use client';
/* Built In Imports */
import { useEffect, useState } from 'react';

/* External Imports */
import forEach from 'lodash/forEach';

/* Internal Imports */
/* Components */
import config from '@config';

/* Services */
import { PreviewHttpService } from './PreviewHttpService';

const isCache = false;

export const getUrlVars = currentUrl => {
  const vars = [];
  // hash;
  const hashes = currentUrl.slice(currentUrl.indexOf('?') + 1)?.split('&');
  forEach(hashes, function (hash) {
    hash = hash?.split('=');
    vars.push(hash[0]);
    vars[hash[0]] = hash[1];
  });

  // for (let i = 0; i < hashes.length; i++) {
  //   hash = hashes[i].split('=');
  //   vars.push(hash[0]);
  //   vars[hash[0]] = hash[1];
  // }
  return vars;
};

export const getCookie = cname => {
  const name = cname + '=';
  if (typeof window !== 'undefined') {
    const decodedCookie = document.cookie && decodeURIComponent(document.cookie);
    if (decodedCookie) {
      const ca = decodedCookie?.split(';');
      forEach(ca, function (c) {
        // let c = ca[i];
        while (c.charAt(0) == ' ') {
          c = c.substring(1);
        }
        if (c.indexOf(name) == 0) {
          return c.substring(name.length, c.length);
        }
      });
    }
  }
  return '';
};

export const createCookie = (name, value) => {
  // const [cookie, setCookie, removeCookie] = useCookies([
  //   name
  // ]);

  // let expires = '';
  document.cookie = `${decodeURIComponent(name)}=${decodeURIComponent(
    value
  )};max-age=86400;domain=${config.COOKIE_DOMAIN
    };path=/;secure=true;samesite=true;`;

  console.log('cookie1111121', document.cookie)
  // consoleLog('createCookie', allCookies);
  // consoleLog('cookie',c);
  // setCookie(name, value, {
  //     path: '/',
  //     maxAge: 3600, // Expires after 1hr
  //     sameSite: true,
  //     domain: config.COOKIE_DOMAIN,
  //     secure:  true
  //   });
};

/**
 * @param reg_url
 * @param selector
 */
export const getUtmParameters = (reg_url, selector) => {
  const [currentUrl, setcurrentUrl] = useState('');
  // let path = window.location.pathname;

  let flag = 0;
  let urlParamCookie = '';
  let urlParamCookiePrm = '';
  let url = '';
  let urlParamPrm = '';
  let urlParamFnl = '';

  const urlParam = currentUrl || reg_url;
  const urlParamArr = getUrlVars(currentUrl);
  if (
    urlParamArr.includes('utm_source') ||
    urlParamArr.includes('utm_medium') ||
    urlParamArr.includes('utm_campaign') ||
    urlParamArr.includes('utm_content') ||
    urlParamArr.includes('utm_term')
  ) {
    flag = 1;
  }
  if (flag === 1) {
    if (urlParam !== '') {
      urlParamFnl = urlParam.slice(1);
      const allCookies = urlParamFnl?.split('&');
      forEach(allCookies, function (cookie) {
        const [name, value] = cookie?.slice(cookie?.indexOf('?') + 1)?.split('=');
        createCookie(name, decodeURIComponent(value));
      });
      urlParamPrm = (reg_url.indexOf('?') !== -1 ? '&' : '?') + urlParamFnl;
      url = reg_url + urlParamPrm;
    } else {
      url = reg_url;
    }
  } else if (flag === 0) {
    const utm_source = getCookie('utm_source');
    if (utm_source !== '' && utm_source !== undefined) {
      urlParamCookie = urlParamCookie + 'utm_source=' + utm_source + '&';
    }
    const utm_medium = getCookie('utm_medium');
    if (utm_medium !== '' && utm_medium !== undefined) {
      urlParamCookie = urlParamCookie + 'utm_medium=' + utm_medium + '&';
    }
    const utm_campaign = getCookie('utm_campaign');
    if (utm_campaign !== '' && utm_campaign !== undefined) {
      urlParamCookie = urlParamCookie + 'utm_campaign=' + utm_campaign + '&';
    }
    const utm_content = getCookie('utm_content');
    if (utm_content !== '' && utm_content !== undefined) {
      urlParamCookie = urlParamCookie + 'utm_content=' + utm_content + '&';
    }
    const utm_term = getCookie('utm_term');
    if (utm_term !== '' && utm_term !== undefined) {
      urlParamCookie = urlParamCookie + 'utm_term=' + utm_term + '&';
    }
    if (urlParamCookie !== '') {
      urlParamCookiePrm =
        (reg_url.indexOf('?') !== -1 ? '&' : '?') + urlParamCookie;
      // let urlParamCookiePrm = "?"+urlParamCookie;
      const lastChar = urlParamCookiePrm?.substring(urlParamCookiePrm.length - 1);
      if (lastChar === '&') {
        url = reg_url + urlParamCookiePrm.slice(0, -1);
      }
    } else {
      url = reg_url;
    }
  }
  //window.onload = function(){
  //  if (!selector){
  if (typeof window !== 'undefined') {
    const setObj = document.querySelector(selector);
    if (setObj !== null) {
      setObj.setAttribute('href', url);
    }
  }
  //  }
  // let addParamUrl = url + "/lang=" + lang + "/";
  //document.querySelector(selector).setAttribute('href', addParamUrl);
  //}
  // useEffect(() => {
  //   const currentUrl = window.location.search;
  //   setcurrentUrl(currentUrl);
  // });
  useEffect(() => {
    const currentUrl = window.location.search || window.location.hash;
    setcurrentUrl(currentUrl);
  }, [global?.window]);
};

export const useUtmParams = () => {
  getCookie();
};

export const acceptCookiesExists = () => {
  const acceptCookies = getCookie('acceptcookies');
  return (
    acceptCookies !== '' &&
    acceptCookies !== undefined &&
    acceptCookies === 'yes'
  );
};

export const tryGetPreviewData = async (previewData, locale) => {
  const { preview, data } = previewData;
  // consoleLog('Preview data is', { preview, data });
  if (preview) {
    try {
      return await new PreviewHttpService().get(`/content`, isCache, data);
    } catch (e) {
      console.error('Could not fetch content from preview mode', e);
      throw e;
    }
  }
  throw new Error('Preview mode is not enabled');
};

export const BUILD_TYPE_CONFIG = Object.freeze({
  menuType: {
    MSR: 'msrmainmenu',
    LINGABHAIRAVI: 'lb',
    DATOCMS_ILA: 'datocms_ilaMenu',
    DATOCMS_SW: 'datocms_swMenu',
  },
  buildType: {
    MSR: 'MSR',
    LINGABHAIRAVI: 'LINGABHAIRAVI',
    DATOCMS_ILA: 'DATOCMS_ILA',
    DATOCMS_SW: 'DATOCMS_SW',
  },
});

const checkIfEquals = menuType => item => item === menuType;

const { buildType, menuType } = BUILD_TYPE_CONFIG;

const isBuildType = mainBuildType => (menuTypeParam, nextBuildTypeParam) =>
  menuTypeParam === menuType[mainBuildType] ||
  nextBuildTypeParam === buildType[mainBuildType];

export const isBuildTypeMsr = isBuildType(buildType.MSR);
export const isBuildTypeLb = isBuildType(buildType.LINGABHAIRAVI);
export const isBuildTypeIla = isBuildType(buildType.DATOCMS_ILA);
export const isBuildTypeSw = isBuildType(buildType.DATOCMS_SW);

export function isBuildTypeIso(menuTypeParam, nextBuildTypeParam) {
  const nonIsoMenuTypes = Object.values(BUILD_TYPE_CONFIG.menuType);
  const nonIsoBuildTypes = Object.values(BUILD_TYPE_CONFIG.buildType);
  return !~(
    nonIsoMenuTypes.findIndex(checkIfEquals(menuTypeParam)) &&
    nonIsoBuildTypes.findIndex(checkIfEquals(nextBuildTypeParam))
  );
}
