/* Built In Imports */
/* External Imports */
import { Box, Flex, Text } from '@chakra-ui/react';
import parse from 'html-react-parser';

/* Components */
import LazyLoadImageComponent from '@components/UI/LazyLoadImageComponent';
import config from '@config';

/* Styles */
/* Services */

/**
 * Returns Quote Component
 *
 * @param sectionContent
 */

const Quotes = ({ sectionContent }) => {
  return <>
    <Box maxW={755} mx="auto" width="100%">
        <Box textAlign={'center'}>
          <Text
            fontSize={{ base: '16px', md: '28px' }}
            color="#000000"
            fontFamily="FedraSerifAStdBook"
            fontWeight="350"
            lineHeight={{ base: '29px', md: '40px' }}
            textAlign={'center'}
          >
            {sectionContent?.quote && (
              <Box as="blockquote" fontSize={{ base: '16px', md: '28px !important' }} fontFamily="FedraSerifAStdBook !important">
                {parse(sectionContent?.quote?.replace(/\n/g, '<span />'))}
              </Box>
            )}
          </Text>
          <LazyLoadImageComponent
            src={`${config.staticPath}/d/46272/1719491051-artboardty.png`}
            height={{ base: '28px', md: '42px' }}
            width={{ base: '136px', md: '211px' }}
          />
          <style>
            {`
            blockquote span {
              display: block;
              margin: 20px 0 0;
            }
            blockquote p {
              font-size: inherit !important;
              line-height: inherit !important;
              color: inherit !important;
              margin-bottom: 0 !important;
            }
            blockquote footer {
              font-weight: bold !important;
            }
          `}
          </style>
        </Box>
    </Box>
  </>;
};

export default Quotes;
