const BuildConstants = {
  section: {
    sadhguru: 'sadhguru',
    wisdom: 'wisdom',
    health: 'health',
    center: 'center',
    ym: 'yoga&meditation',
    events: 'events',
    landing_pages: 'landing_pages',
    others_section: 'others_section'
  },
  level: {
    L0: 'lvl0',
    L1: 'L1',
    L2: 'L2',
    L3: 'L3',
    L4: 'L4',
  },
};

export default BuildConstants;