/* Built In Imports */
import NextLink from 'next/link';
import { useRouter } from 'next/router';

/* External Imports */
import { Box, Image, Link, UnorderedList } from '@chakra-ui/react';

/* Internal Imports */
/* Components */
import NewMenuLI from '@components/UI/NewMenuLI';
import { refineUrlDomain } from '@components/Utility/utils';
import config from '@config';

/* Services */

/**
 * Renders the Desktop Menu component
 *
 * @param {object} headerMenuData - data
 * @param {string} region - country
 * @param {string} language language
 * @param {boolean} isStaticPage
 * @returns {ReactElement} Desktop Menu component
 */

const DesktopMenu = ({
  headerMenuData,
  headTopMenuData,
  region,
  language,
  isStaticPage,
}) => {
  // console.log('vipWidgetData', region);
  // Home key has been added in API which is only for mobile view and hence we need to remove that key for desktop view.
  headerMenuData?.allIsoMainMenus &&
    (headerMenuData.allIsoMainMenus = headerMenuData?.allIsoMainMenus?.filter(
      item => item.key !== 'home'
    ));
  const router = useRouter();
  const url = router.asPath;
  const isSatsangPage =
    url && url.indexOf('/events/monthly/sadhguru-satsang') !== -1;

  language = 'en';

  const renderMenu = () => {
    // create array for center menu
    if (headerMenuData?.allIsoMainMenus?.length > 0) {
      const centerMenu = headerMenuData.allIsoMainMenus[4]?.submenuItems;
      const centerMenuItem = [];
      centerMenu?.map(item => {
        const subMenuItems = item.submenuItems;
        centerMenuItem[item.key] = subMenuItems;
      });
      // create array for second level menu
      const secondlevelMenu = headerMenuData.allIsoMainMenus;
      const secondlevelMenuData = [];
      secondlevelMenu.map(item => {
        const subMenuItems = item.submenuItems;
        secondlevelMenuData[item.key] = subMenuItems;
      });
      // create array for health menu
      const healthMenuItem = [];
      if (headerMenuData.allIsoMainMenus[5]?.submenuItems) {
        const healthMenu = headerMenuData.allIsoMainMenus[5].submenuItems;

        healthMenu.map(item => {
          const subMenuItems = item.submenuItems;
          healthMenuItem[item.key] = subMenuItems;
        });
      }

      if (region === 'us' || region === 'ca') {
        // console.log('ususus', region);
        headerMenuData.allIsoMainMenus = headerMenuData?.allIsoMainMenus.filter(
          menuItem => {
            return menuItem?.title !== 'Health';
          }
        );
      }

      return headerMenuData?.allIsoMainMenus.map((menuItem, index) => {
        let url;
        // if (menuItem?.url.indexOf('https://') >= 0) {
        //   url = menuItem?.url;
        // } else {
        url = refineUrlDomain(menuItem?.url, region, language);
        // }

        const subMenuItems = [];
        let subSubMenuLength;

        if (menuItem.submenuItems && menuItem.submenuItems.length > 0) {
          menuItem.submenuItems.map((item, ind) => {
            let subURL;
            const modifiedURL =
              item?.url &&
              item?.url.replace('/wisdom-intermediate', '/wisdom/type');
            // if (modifiedURL.indexOf('https://') >= 0) {
            //   subURL = modifiedURL;
            // } else {
            //   subURL = `/${region}/${language}${modifiedURL}`;
            // }
            // subURL = refineUrlDomain(modifiedURL, region, language);
            // if (
            //   subURL.indexOf('https://') < 0 &&
            //   subURL.indexOf(`/${region}/${language}/`) < 0
            // ) {
            //   subURL = `/${region}/${language}${modifiedURL}`;
            // } else if (subURL.indexOf('https://') >= 0) {
            //   subURL = modifiedURL;
            // }

            subURL = refineUrlDomain(modifiedURL, region, language);
            const subSubMenuItems = [];
            if (item.submenuItems?.length) {
              item.submenuItems.map((item, i) => {
                let subSubURL;
                // if (item?.url.indexOf('https://') >= 0) {
                //   subSubURL = item?.url;
                // } else if (item?.url != '#') {
                //   subSubURL = `/${region}/${language}${item?.url}`;
                // } else {
                //   subSubURL = item?.url;
                // }
                subSubURL = refineUrlDomain(item?.url, region, language);
                subSubMenuItems.push(
                  <NewMenuLI
                    key={i}
                    textFormat={item.textFormat}
                    text={item.title}
                    url={subSubURL}
                  ></NewMenuLI>
                );
              });
            }

            const listItem =
              subSubMenuItems.length > 0 ? (
                ((subSubMenuLength = 1),
                (
                  <>
                    <Box key={ind} className="menu menu_L1">
                      <UnorderedList key={ind} marginInlineStart="0">
                        <NewMenuLI
                          textFormat={item.textFormat}
                          text={item.title}
                          url={subURL}
                          key={item.title}
                          className={subURL != '#' ? 'title link' : 'title'}
                        />
                        {subSubMenuItems}
                      </UnorderedList>
                    </Box>
                  </>
                ))
              ) : (
                <>
                  {item.key == 'yoga' ||
                  item.key == 'childrens-programs' ||
                  item.key == 'become-a-teacher' ? (
                    <Box key={ind} className="menu menu_L1">
                      <UnorderedList key={ind}>
                        <NewMenuLI
                          textFormat={item.textFormat}
                          text={item.title}
                          url={subURL}
                          key={item.title}
                          className={subURL != '#' ? 'title link' : 'title'}
                        />
                        {subSubMenuItems}
                      </UnorderedList>
                    </Box>
                  ) : (
                    <NewMenuLI
                      text={item.title}
                      url={subURL}
                      key={ind}
                      className=""
                      textFormat={item.textFormat}
                    />
                  )}
                </>
              );
            subMenuItems.push(listItem);
          });
        }

        return (
          <NewMenuLI key={index} text={menuItem.title} url={url} className="">
            {menuItem.title == 'Health' &&
              region !== 'us' &&
              region !== 'ca' &&
              !isSatsangPage && (
                <Box
                  className="drop_block"
                  position="absolute"
                  width="200px"
                  top="74px"
                  paddingTop="2px"
                  zIndex="99"
                  display="none"
                >
                  <Box
                    className="block coloum"
                    float="left"
                    width="100%"
                    backgroundColor="#fff"
                    border="1px solid #f0f0f0"
                    boxShadow="2px 2px 10px rgb(0 0 0 / 50%)"
                  >
                    <Box className="menu menu_L1">
                      <UnorderedList key={index} margin="0" padding="0">
                        <NewMenuLI
                          text="Isha Health Solutions"
                          url={refineUrlDomain(
                            secondlevelMenuData.health[0]?.url,
                            region,
                            language
                          )}
                          key="Isha Health Solutions"
                          className={
                            secondlevelMenuData.health[0]?.url != '#'
                              ? 'title link'
                              : 'title'
                          }
                          textFormat={secondlevelMenuData.health[0]?.textFormat}
                        />
                        {healthMenuItem['isha-health-solutions']?.map(
                          (item, i) => {
                            // if (item?.url.indexOf('https://') >= 0) {
                            //   url = item?.url;
                            // } else if (item?.url != '#') {
                            //   url = `/${region}/${language}${item?.url}`;
                            // } else {
                            //   url = item?.url;
                            // }
                            url = refineUrlDomain(item?.url, region, language);
                            return (
                              <NewMenuLI
                                text={item.title}
                                url={url}
                                key={i}
                                className=""
                                textFormat={item.textFormat}
                              />
                            );
                          }
                        )}
                        <NewMenuLI
                          text="Therapies"
                          url={refineUrlDomain(
                            secondlevelMenuData.health[1]?.url,
                            region,
                            language
                          )}
                          key="Therapies"
                          className={
                            secondlevelMenuData.health[1]?.url != '#'
                              ? 'title link'
                              : 'title'
                          }
                        />
                        ;
                        {healthMenuItem['therapies'] &&
                          healthMenuItem['therapies'].map((item, v) => {
                            // if (item?.url.indexOf('https://') >= 0) {
                            //   url = item?.url;
                            // } else {
                            //   url = `/${region}/${language}${item?.url}`;
                            // }
                            refineUrlDomain(item?.url, region, language);
                            return (
                              <NewMenuLI
                                text={item.title}
                                url={url}
                                key={v}
                                className=""
                                textFormat={item.textFormat}
                              />
                            );
                          })}
                      </UnorderedList>
                    </Box>
                    <Box className="menu menu_L1">
                      <UnorderedList key={index} _last={{ borderRight: '0' }}>
                        <NewMenuLI
                          text="Residential Programs"
                          url={refineUrlDomain(
                            secondlevelMenuData.health[2]?.url,
                            region,
                            language
                          )}
                          // url={
                          //   secondlevelMenuData.health[2]?.url != '#'
                          //     ? `/${region}/${language}${secondlevelMenuData.health[2]?.url}`
                          //     : '#'
                          // }
                          key="Residential Programs"
                          className={
                            secondlevelMenuData.health[2]?.url != '#'
                              ? 'title link'
                              : 'title'
                          }
                          textFormat={secondlevelMenuData.health[2]?.textFormat}
                        />

                        {healthMenuItem['residential-programs'] &&
                          healthMenuItem['residential-programs'].map(
                            (item, v) => {
                              // if (item?.url.indexOf('https://') >= 0) {
                              //   url = item?.url;
                              // } else if (item?.url != '#') {
                              //   url = `/${region}/${language}${item?.url}`;
                              // } else {
                              //   url = item?.url;
                              // }
                              url = refineUrlDomain(
                                item?.url,
                                region,
                                language
                              );
                              return (
                                <NewMenuLI
                                  text={item.title}
                                  url={url}
                                  key={v}
                                  className=""
                                  textFormat={item.textFormat}
                                />
                              );
                            }
                          )}
                      </UnorderedList>
                    </Box>
                    <Box className="menu menu_L1">
                      <UnorderedList>
                        <NewMenuLI
                          text="Non-Residential Programs"
                          url={refineUrlDomain(
                            secondlevelMenuData.health[3]?.url,
                            region,
                            language
                          )}
                          key="Non Residential Programs"
                          className={
                            secondlevelMenuData.health[3]?.url != '#'
                              ? 'title link'
                              : 'title'
                          }
                          textFormat={secondlevelMenuData.health[3]?.url}
                        />

                        {healthMenuItem['non-residential-programs'] &&
                          healthMenuItem['non-residential-programs'].map(
                            (item, v) => {
                              // if (item?.url.indexOf('https://') >= 0) {
                              //   url = item?.url;
                              // } else {
                              //   url = `/${region}/${language}${item?.url}`;
                              // }
                              url = refineUrlDomain(
                                item?.url,
                                region,
                                language
                              );
                              return (
                                <NewMenuLI
                                  text={item.title}
                                  url={url}
                                  key={v}
                                  className=""
                                  textFormat={item.textFormat}
                                />
                              );
                            }
                          )}
                      </UnorderedList>
                    </Box>
                  </Box>
                </Box>
              )}

            {menuItem.title == 'Centers' && !isSatsangPage && (
              <Box
                className="drop_block"
                position="absolute"
                width="200px"
                top="74px"
                paddingTop="2px"
                zIndex="99"
                display="none"
              >
                <Box
                  className="block coloum"
                  float="left"
                  width="100%"
                  backgroundColor="#fff"
                  border="1px solid #f0f0f0"
                  boxShadow="2px 2px 10px rgb(0 0 0 / 50%)"
                >
                  <Box className="menu menu_L1">
                    <UnorderedList margin="0" padding="0" marginInlineStart="0">
                      <NewMenuLI
                        // text="Residential Centers (Ashram)"
                        text={secondlevelMenuData.centers[0]?.title}
                        url={refineUrlDomain(
                          secondlevelMenuData.centers[0]?.url,
                          region,
                          language
                        )}
                        // key="Residential Centers (Ashram)"
                        key={secondlevelMenuData.centers[0]?.key}
                        className={
                          secondlevelMenuData.centers[0]?.url != '#'
                            ? 'title link'
                            : 'title'
                        }
                        textFormat={secondlevelMenuData.centers[0]?.textFormat}
                      />
                      {centerMenuItem['residential-centers-ashram']?.map(
                        (item, v) => {
                          // if (item?.url.indexOf('https://') >= 0) {
                          //   url = item?.url;
                          // } else {
                          //   url = `/${region}/${language}${item?.url}`;
                          // }
                          url = refineUrlDomain(item?.url, region, language);
                          return (
                            <NewMenuLI
                              text={item.title}
                              url={url}
                              key={v}
                              className="withdesc"
                              textFormat={item.textFormat}
                            />
                          );
                        }
                      )}
                      <NewMenuLI
                        // text="Regional offices near you"
                        text={secondlevelMenuData.centers[1]?.title}
                        url={refineUrlDomain(
                          secondlevelMenuData.centers[1]?.url,
                          region,
                          language
                        )}
                        // key="Regional offices near you"
                        key={secondlevelMenuData.centers[1]?.key}
                        className={
                          secondlevelMenuData.centers[1]?.url != '#'
                            ? 'title link'
                            : 'title'
                        }
                      />
                      ;
                      {centerMenuItem['regional-offices-near-you']?.map(
                        (item, v) => {
                          // if (item?.url.indexOf('https://') >= 0) {
                          //   url = item?.url;
                          // } else {
                          //   url = `/${region}/${language}${item?.url}`;
                          // }
                          url = refineUrlDomain(item?.url, region, language);
                          return (
                            <NewMenuLI
                              text={item.title}
                              url={url}
                              key={v}
                              className=""
                              textFormat={item.textFormat}
                            />
                          );
                        }
                      )}
                    </UnorderedList>
                  </Box>

                  <Box className="menu menu_L1">
                    <UnorderedList>
                      <NewMenuLI
                        text="International Centers"
                        url={refineUrlDomain(
                          secondlevelMenuData.centers[2]?.url,
                          region,
                          language
                        )}
                        key="International Centers"
                        className={
                          secondlevelMenuData.centers[2]?.url != '#'
                            ? 'title link'
                            : 'title'
                        }
                        textFormat={secondlevelMenuData.centers[2]?.textFormat}
                      />

                      {centerMenuItem['international-centers']?.map(
                        (item, v) => {
                          // if (item?.url.indexOf('https://') >= 0) {
                          //   url = item?.url;
                          // } else {
                          //   url = `/${region}/${language}${item?.url}`;
                          // }
                          url = refineUrlDomain(item?.url, region, language);
                          return (
                            <NewMenuLI
                              text={item.title}
                              url={url}
                              key={v}
                              className="withdesc"
                              textFormat={item.textFormat}
                            />
                          );
                        }
                      )}
                    </UnorderedList>
                  </Box>

                  <Box className="menu menu_L1">
                    <UnorderedList>
                      <NewMenuLI
                        text="Consecrated Spaces"
                        url={refineUrlDomain(
                          secondlevelMenuData.centers[3]?.url,
                          region,
                          language
                        )}
                        key="Consecrated Spaces"
                        className={
                          secondlevelMenuData.centers[3]?.url != '#'
                            ? 'title link'
                            : 'title'
                        }
                        textFormat={secondlevelMenuData.centers[3]?.textFormat}
                      />
                      <NewMenuLI
                        text={
                          centerMenuItem['consecrated-spaces']
                            ? centerMenuItem['consecrated-spaces'][0]?.title
                            : 'India'
                        }
                        url={refineUrlDomain(
                          secondlevelMenuData.centers[4]?.url,
                          region,
                          language
                        )}
                        key={
                          centerMenuItem['consecrated-spaces']
                            ? centerMenuItem['consecrated-spaces'][0]?.title
                            : 'India'
                        }
                        textFormat={secondlevelMenuData.centers[4]?.textFormat}
                        className={
                          secondlevelMenuData.centers[4]?.url != '#'
                            ? 'title link'
                            : 'title'
                        }
                      />
                      {centerMenuItem[
                        centerMenuItem['consecrated-spaces']
                          ? centerMenuItem['consecrated-spaces'][0]?.key
                          : 'india'
                      ]?.map((item, v) => {
                        // if (item?.url.indexOf('https://') >= 0) {
                        //   url = item?.url;
                        // } else {
                        //   url = `/${region}/${language}${item?.url}`;
                        // }
                        url = refineUrlDomain(item?.url, region, language);
                        return (
                          <NewMenuLI
                            text={item.title}
                            url={url}
                            key={v}
                            className=""
                            textFormat={item.textFormat}
                          />
                        );
                      })}
                      <NewMenuLI
                        text={
                          centerMenuItem['consecrated-spaces']
                            ? centerMenuItem['consecrated-spaces'][1]?.title
                            : 'USA'
                        }
                        url={refineUrlDomain(
                          secondlevelMenuData.centers[5]?.url,
                          region,
                          language
                        )}
                        key={
                          centerMenuItem['consecrated-spaces']
                            ? centerMenuItem['consecrated-spaces'][1]?.title
                            : 'USA'
                        }
                        textFormat={secondlevelMenuData.centers[5]?.textFormat}
                        className={
                          secondlevelMenuData.centers[5]?.url != '#'
                            ? 'title link'
                            : 'title'
                        }
                      />
                      ;
                      {centerMenuItem[
                        centerMenuItem['consecrated-spaces']
                          ? centerMenuItem['consecrated-spaces'][1]?.key
                          : 'usa'
                      ]?.map((item, v) => {
                        // if (item?.url.indexOf('https://') >= 0) {
                        //   url = item?.url;
                        // } else {
                        //   url = `/${region}/${language}${item?.url}`;
                        // }
                        url = refineUrlDomain(item?.url, region, language);
                        return (
                          <NewMenuLI
                            text={item.title}
                            url={url}
                            key={v}
                            textFormat={item.textFormat}
                          />
                        );
                      })}
                    </UnorderedList>
                  </Box>
                </Box>
              </Box>
            )}
            {subMenuItems.length > 0 &&
              menuItem.title !== 'Centers' &&
              menuItem.title !== 'Health' &&
              !isSatsangPage && (
                <Box
                  className="drop_block"
                  position="absolute"
                  width="200px"
                  top="74px"
                  paddingTop="2px"
                  zIndex="99"
                  display="none"
                >
                  <Box
                    className={`block ${subSubMenuLength > 0 ? 'coloum' : ''}`}
                    float="left"
                    width="100%"
                    backgroundColor="#fff"
                    border="1px solid #f0f0f0"
                    boxShadow="2px 2px 10px rgb(0 0 0 / 50%)"
                  >
                    <UnorderedList margin="0" padding="0" marginInlineStart="0">
                      {subMenuItems}
                    </UnorderedList>
                  </Box>
                </Box>
              )}
          </NewMenuLI>
        );
      });
    }
  };

  return (
    <Box
      float="left"
      w="100%"
      h="76px"
      bgColor="#464038 !important"
      mr="auto"
      ml="auto"
      pl="calc((100% - 1300px) / 2)"
      pr="calc((100% - 1300px) / 2)"
      className="nav container"
      display={{ base: 'none', xl: 'flex' }}
    >
      <Box m="0 auto" padding="0 15px" w="1200px">
        <Box float="left" w="62px" mt="8px" cursor="pointer">
          <NextLink
            // className={classes.logo}
            href={
              isStaticPage
                ? config.cdnPath
                : refineUrlDomain('/', region, language)
              // 'https://isha.sadhguru.org'
            }
            legacyBehavior
          >
            <Image
              loading="lazy"
              style={{ float: 'left' }}
              width="100%"
              // src={`${config.staticPath}/d/46272/1686731673-isha-logo-main-menu.svg`}
              src={headTopMenuData[11]?.icon?.url}
              alt=""
            />
          </NextLink>
        </Box>
        <Box float="left" ml="60px" className="menu menu_L1">
          {headerMenuData && headerMenuData?.allIsoMainMenus?.length > 0 && (
            <UnorderedList margin="0" padding="0" marginInlineStart="0">
              {renderMenu()}
            </UnorderedList>
          )}
        </Box>
        {headTopMenuData[10]?.rootMenuOrder == '11' && (
          <>
            <Link
              float="right"
              w="40px"
              h="67px"
              bgPos="50% 40%"
              bgSize="21px"
              bgRepeat="no-repeat"
              m="12px 15px 0 0"
              p="3px 0 58px 0"
              borderBottom="solid 7px transparent"
              bgImg={headTopMenuData[10]?.icon?.url}
              _hover={{
                bgImg: `${headTopMenuData[10]?.iconHover?.url}`,
              }}
              href={refineUrlDomain('/search', region, language)}
              // onClick={e => refreshPage(e?.target?.href)}
            />
          </>
        )}
        <Box
          w="3px"
          h="44px"
          float="right"
          borderRight={'solid 1px #A39D93'}
          m="18px 15px 0 0"
        />
        <Box
          float="right"
          w="110px"
          display={'flex'}
          m={'19px 0 0 0'}
          cursor="pointer"
        >
          <Link
            color="#B7AC96"
            fontSize="14px"
            lineHeight={'17px'}
            fontFamily="FedraSansStd-book, sans-serif"
            href={refineUrlDomain(headTopMenuData[9]?.url, region, language)}
            bgImg={headTopMenuData[9]?.icon?.url}
            bgPos="0 50%"
            bgSize="21px"
            bgRepeat="no-repeat"
            _hover={{
              textDecoration: 'none',
              color: '#FEFDFC',
              bgImg: `${headTopMenuData[9]?.iconHover?.url}`,
            }}
            margin="5px 10px 0 0"
            paddingLeft="30px"
          >
            {/* <Image
              src={headTopMenuData[9]?.iconHover?.url}
              display="none !important"
            /> */}
            {/* <Image
              src={headTopMenuData[9]?.icon?.url}
              alignSelf={'baseline'}
              margin="5px 10px 0 19px"
              float="left"
            /> */}
            {headTopMenuData[9]?.title}
          </Link>
        </Box>
      </Box>
      <style global jsx>
        {`
          .menu_L1 ul li:last-child a {
            border-right: 0;
          }
        `}
      </style>
    </Box>
  );
};

export default DesktopMenu;
