/* External Imports */
import { Box, Container, Grid, Image, Link } from '@chakra-ui/react';
import { nanoid } from 'nanoid';

/* Internal Imports */
/* Components */
import { refineUrlDomain } from '@msr/components/Utility/utils';

/**
 *
 * @param {Object} popUpData
 * @returns
 */
export const SubHeaderList = ({ popUpData, getDevice, lang }) => {
  const MsrDesktopTop = () => {
    return (
      getDevice === 'msr-desktop' && (
        <Box bgColor="#000">
          <Container maxW="100%" position="relative" p="0">
            <Grid width="100%" alignItems="baseline">
              {popUpData?.['msr-desktop-top']?.map(
                item =>
                  item.generic_type === 'Top Sticky-Desktop' && (
                    <Link
                      href={refineUrlDomain(item?.url, lang, '')}
                      key={nanoid(4)}
                    >
                      <Image src={item?.image?.url} alt="" width="100%" />
                    </Link>
                  )
              )}
            </Grid>
          </Container>
        </Box>
      )
    );
  };

  const MsrMobileSticky = () => {
    return (
      getDevice === 'msr-mobile' && (
        <Box width="100%">
          {popUpData?.['msr-desktop-top']?.map(
            item =>
              item.generic_type === 'Top Sticky-Mobile' && (
                <Link
                  w="100%"
                  height="auto"
                  textAlign={{
                    base: 'left',
                    md: 'center',
                  }}
                  href={refineUrlDomain(item?.url, lang, '')}
                  key={nanoid(4)}
                >
                  <Image src={item?.image?.url} alt="" width="100%" />
                </Link>
              )
          )}
        </Box>
      )
    );
  };

  return popUpData?.status !== 'failure' ? (
    <>
      <MsrMobileSticky />
      <MsrDesktopTop />
    </>
  ) : (
    <></>
  );
};
