/* Built In Imports */
/* External Imports */
import { Box } from '@chakra-ui/react';

/* Internal Imports */
/* Components */
import LazyLoadImageComponent from '@components/UI/LazyLoadImageComponent';

/* Services */

/**
 * Renders the Single Image Blocker Component
 * @param {Object} record data
 * @returns {ReactElement} Single Image Blocker component
 */
export default function SingleImageBlocker(record) {
  return (
    <Box textAlign="center">
      <LazyLoadImageComponent src={record.image.url} alt={record.image.alt} title={record.image.title} />
    </Box>
  );
}
