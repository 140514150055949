/* Built In Imports */
import Image from 'next/image';
import NextLink from 'next/link';

/* External Imports */
import { HamburgerIcon } from '@chakra-ui/icons';
import { Box, Flex, Link, List, ListItem, VStack } from '@chakra-ui/react';
import { nanoid } from 'nanoid';
import { useMemo, useState } from 'react';
import { useMediaQuery } from 'react-responsive';

/* Internal Imports */
/* Components */
import config from '@config';
import MobileNav from '@lb/components/Header/MobileNav';
import TopMenu from '@lb/components/Header/TopMenu';
import { refineUrlDomain } from '@lb/components/Utility/utils';

/* Styles */
import styles from '@lb/components/Header/style.module.css';

/* Services */

/**
 *
 * @param headerMenuData
 * @param topMenuData
 * @param region
 * @param language
 */

const LBHeader = ({
  headerMenuData,
  topMenuData,
  region,
  language,
  pageData,
}) => {
  const [isOpen, setIsOpen] = useState(false);
  const modalHandler = () => {
    setIsOpen(!isOpen);
  };

  const handleClick = () => {
    setIsOpen(!isOpen);
  };

  useMemo(() => {
    if (isOpen) {
      handleClick();
    }
  }, []);
  const belowEleven = useMediaQuery({ maxWidth: 1060 });
  const NavItem = ({ children, url, region, language }) => {
    return (
      <ListItem fontSize={{ lg: '13px', xl: 'body3' }} userSelect="none">
        <NextLink
          // was not working so
          href={refineUrlDomain(url, region, language)}
          // href={url} // this
          passHref
          legacyBehavior
        >
          <Link
            color="#937D7D"
            // href={url}
            textDecor="none"
            _hover={{ color: '#000', borderBottom: '4px solid #A9392D' }}
            fontWeight="600"
            pb="13px"
          >
            {children}
          </Link>
        </NextLink>
      </ListItem>
    );
  };

  return (
    <>
      <Box
        width={{ lg: '99%', xl: '100%' }}
        height="auto"
        padding="10px 0px 10px"
      >
        <Box
          maxW="1330px"
          margin="0 auto"
          px={{ base: '16px', md: '16px', lg: '0', xl: '0px', '2xl': '80px' }}
        >
          <Flex
            flexWrap="wrap"
            alignItems={{ base: 'self-start', lg: 'center' }}
            justifyContent="space-between"
            p={{ base: '0', lg: '0 0 0 16px', xl: '0' }}
            maxW="1170px"
            mx="auto"
          >
            <Box width={{ lg: !belowEleven ? '18%' : '15%', xl: '20%' }}>
              <NextLink
                href={refineUrlDomain('/', region, language)}
                passHref
                legacyBehavior
              >
                <Link p="0" m="0">
                  <Box
                    w={{ base: '112px', lg: '150px', xl: '196px' }}
                    h={{ base: '54.71px', lg: '96px' }}
                  >
                    <Image
                      alignSelf="center"
                      cursor="pointer"
                      // objectFit="contain"
                      // fill
                      width={196}
                      height={96}
                      priority
                      userSelect="none"
                      src={`${config.imgPath}/d/46272/1681884350-lingabhairavi-1.svg`}
                      alt="LingaBhairavi"
                    />
                  </Box>
                </Link>
              </NextLink>
            </Box>

            <Box
              className={styles.menuIcon}
              fontSize="25px"
              pt="0px"
              textAlign="center"
              cursor="pointer"
              onClick={modalHandler}
              px="4px"
            >
              <Box>
                <HamburgerIcon />
              </Box>
            </Box>
            <Box w={{ lg: '82%', xl: '80%' }} className={styles.LBHeader}>
              <VStack width="100%" alignItems="flex-end">
                <TopMenu
                  topMenuData={topMenuData}
                  region={region}
                  language={language}
                  pageData={pageData}
                />
                <Flex width="100%">
                  <List
                    width="100%"
                    display="flex"
                    alignItems="center"
                    justifyContent="flex-end"
                    listStyleType="none"
                    textTransform="uppercase"
                    fontFamily="fonts.body"
                    fontStyle="normal"
                    fontWeight="500"
                    fontSize="body-3"
                    lineHeight="18px"
                    pt="28px"
                    pb="40px"
                    gridGap={!belowEleven ? '20px' : '15px'}
                  >
                    {headerMenuData?.allIsoMainMenus.map(menu => {
                      return (
                        <NavItem
                          key={nanoid()}
                          url={menu?.url}
                          region={region}
                          language={language}
                        >
                          {menu.title}
                        </NavItem>
                      );
                    })}
                  </List>
                </Flex>
              </VStack>
            </Box>
          </Flex>
        </Box>
      </Box>

      {isOpen && (
        <MobileNav
          headerMenuData={headerMenuData}
          topMenuData={topMenuData}
          handleClick={handleClick}
          region={region}
          language={language}
        />
      )}
    </>
  );
};

export default LBHeader;
