/* Built In Imports */
/* External Imports */
import { Box } from '@chakra-ui/react';

/* Internal Imports */
/* Components */
import LazyLoadImageComponent from '@components/UI/LazyLoadImageComponent';

/* Styles */
/* Services */

/**
 * Renders SadhguruSignatureLoveGrace Component
 *
 * @param {object} Card
 * @returns {ReactElement} SadhguruSignatureLoveGrace Component
 */

const SadhguruSignatureLoveGrace = ({ card }) => {
  return (
    <Box
      width={{ base: '100%', md: '288px' }}
      my="20px"
    >
      <Box fontSize="16px" color={'#000000'} mb="38px">
        {card?.text?.json?.text}
      </Box>
      <Box maxW={{base:'122px', md:'179px'}}>
      {card?.text?.json?.signImageURL && (
        <LazyLoadImageComponent
          src={card?.text?.json?.signImageURL}
          alt="Sadhguru Signature"
          objectFit="cover"
        />
      )}
      </Box>
    </Box>
  );
};

export default SadhguruSignatureLoveGrace;
