/* Built In Imports */
/* External Imports */
import { Box, Link, ListItem, OrderedList } from '@chakra-ui/react';
import { nanoid } from 'nanoid';

/* Internal Imports */

/* Styles */
/* Services */

/**
 * Renders BlogTableOfContentIndex Component
 *
 * @param {object} sectionContent
 * @returns {ReactElement} BlogTableOfContentIndex Component
 */

const BlogTableOfContentIndex = ({ sectionContent }) => {
  return (
    <Box
      pos="relative"
      mb={{ base: '10px', md: '0' }}
      fontFamily="FedraSerifAStdBook"
      maxW="657px"
      width="100%"
      m="0"
    >
      <Box
        w={{ base: '342px', md: '696px' }}
        color="#000000"
        fontSize={{ base: '18px', md: '26px' }}
        lineHeight="140%"
        fontFamily="'FedraSerifAStdBook'"
        fontWeight="600"
        // as="h3"
      >
        {sectionContent?.title}
      </Box>
      <OrderedList>
        {sectionContent?.tableItems
          ? Object?.keys(sectionContent?.tableItems)?.map(item => {
              return (
                <ListItem
                  key={nanoid()}
                  p="0.35em"
                  marginLeft={{ md: '5px', base: '5px' }}
                  fontFamily={'FedraSansStd-book'}
                  fontSize={{base:'16px !important', md:'18px !important'}}
                  lineHeight={'33.66px'}
                  fontWeight="400"
                  textDecoration="underline"
                  color="#000000"
                  marginTop="16px"
                  className='toc_link'
                >
                  <Link
                    href={`#${sectionContent.tableItems[item]}`}
                  >
                    {item}
                  </Link>
                </ListItem>
              );
            })
          : null}
      </OrderedList>
      <style global jsx>
        {`
        .shivablogbody .toc_link a {
            border-bottom: none !important;
            text-decoration: none !important;
            font-size: 18px;
            line-height: 33.64px;
          }
        `}
      </style>
    </Box>
  );
};

export default BlogTableOfContentIndex;
