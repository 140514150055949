export const styles = {
  global: {
    fontSizes: {
      md: "0.8rem",
      lg: "1rem"
    },
    fontTypes: {
      medium: 'FedraSansStd-medium'
    },

  }  
};
