/* Built In Imports */
import NextLink from 'next/link';
import { useRouter } from 'next/router';
import { useContext } from 'react';

/* External Imports */
import { Box, Link, ListItem, UnorderedList } from '@chakra-ui/react';

/* Internal Imports */
/* Components */
import { refineUrlDomain } from '@components/Utility/utils';
import config from '@config';
import { AuthContext } from '@store/auth-context';

/* Services */
/* Styles */
import classes from '@styles/components/Header.module.css';

/**
 * Renders the Logged In Component
 *
 * @param {boolean} isOpenProfile
 * @param {string} region - Region for page
 * @param {Sting} lang - Language for page
 * @returns {ReactElement} LoggedIn component
 */

const LoggedIn = ({
  profileDropdownHandler,
  isOpenProfile,
  isDropdownActive,
  type,
  region,
  language,
  headTopMenuData,
  page,
}) => {
  const router = useRouter();
  const logoutURL = `${config.FACADE_BASE_PATH}/logout?request_url=${config.BASE_PATH}${router.asPath}`;
  const authCtx = useContext(AuthContext);
  const { userName } = authCtx;
  const logoutHandler = () => {
    authCtx.logout();
  };
  const loggedInMenu = headTopMenuData[8]?.submenuItems;
 
  return (
    <>
      {type == 'mobile' ? (
        <>
          <Box float="left" mt="5px" ml="10px" className="usermenu">
            <Box
              float="left"
              width="100%"
              id="profileDropdownMobile"
              onClick={profileDropdownHandler}
            >
              <Box
                float="left"
                fontFamily="'FedraSansStd-medium', sans-serif"
                fontSize="18px"
                color="#464038"
                textDecoration="none"
                p="18px 30px 0 0"
                // bgImage={`url(${config.staticPath}/assets/images/angle-down.svg)`}
                bgImage={`url(${config.staticPath}/d/46272/${
                  isOpenProfile
                    ? '1667474630-icon_angle-up'
                    : '1667474632-icon_angle-down'
                }.svg)`}
                backgroundSize="18px"
                backgroundRepeat="no-repeat"
                backgroundPosition="100% 80%"
                textTransform="capitalize"
                cursor="pointer"
                width="140px"
                whiteSpace="nowrap"
                overflow="hidden"
                textOverflow="ellipsis"
              >
                {userName}
              </Box>
            </Box>
            <Box
              position="absolute"
              width="180px"
              right="0"
              top="68px"
              left="0"
              pt="2px"
              border="4"
              zIndex="9"
              borderRadius="4"
              display="none"
              className={`${isOpenProfile ? 'open' : ''}`}
            >
              <Box
                float="left"
                width="100%"
                backgroundColor="#fff"
                border="solid 1px #f0f0f0"
                boxShadow="0 0 5px rgba(0, 0, 0, 0.2)"
                className={classes.block}
              >
                <UnorderedList m="0" p="0" className="user_navigation">
                  <ListItem display="block">
                    <NextLink
                      // href={`${config.MY_PROFILE_URL}/profile?cb=${config.BASE_PATH}${router.asPath}`}
                      href={`${refineUrlDomain(
                        loggedInMenu && loggedInMenu[0]?.url,
                        region,
                        language
                      )}/profile?cb=${config.BASE_PATH}${router.asPath}`}
                      prefetch={false}
                      display="block"
                      fontFamily="'FedraSansStd-book', sans-serif"
                      fontSize="12px"
                      color="#464038"
                      textDecoration="none"
                      p="8px 9px"
                      m="5px"
                      fontWeight="300"
                      _hover={{
                        bgColor: '#e4ded4',
                        color: '#cf4520',
                      }}
                      legacyBehavior
                    >
                      {loggedInMenu?.[0]?.title ?? 'My Profile'}
                    </NextLink>
                  </ListItem>
                  {/* We don't show Recurring Donations for US and Canada Regions */}
                  {region !== 'us' && region !== 'ca' && (
                    <ListItem display="block">
                      <NextLink
                        // href={`/en/contribute/my-account/recurring-donations`}
                        href={`${refineUrlDomain(
                          loggedInMenu && loggedInMenu[1]?.url,
                          region,
                          language
                        )}`}
                        prefetch={false}
                        legacyBehavior
                      >
                        <Link
                          display="block"
                          fontFamily="'FedraSansStd-book', sans-serif"
                          fontSize="12px"
                          color="#cf4520"
                          textDecoration="none"
                          p="8px 9px"
                          m="5px"
                          fontWeight="300"
                          _hover={{
                            bgColor: '#464038',
                          }}
                        >
                          {loggedInMenu && loggedInMenu[1]?.title}
                        </Link>
                      </NextLink>
                    </ListItem>
                  )}
                  <ListItem display="block">
                    <NextLink href={logoutURL} prefetch={false} legacyBehavior>
                      <Link
                        // onClick={logoutHandler}
                        display="block"
                        fontFamily="'FedraSansStd-book', sans-serif"
                        fontSize="12px"
                        color="#cf4520"
                        textDecoration="none"
                        p="8px 9px"
                        m="5px"
                        fontWeight="300"
                        _hover={{
                          bgColor: '#464038',
                        }}
                      >
                        {loggedInMenu && loggedInMenu[2]?.title}
                      </Link>
                    </NextLink>
                  </ListItem>
                </UnorderedList>
              </Box>
            </Box>
          </Box>
        </>
      ) : (
        <Box float="right" position="relative">
          <Link float="left" width="auto">
            <Box float="left" width="100%">
              <Box
                onClick={profileDropdownHandler}
                float="left"
                fontFamily="'FedraSansStd-medium', sans-serif"
                fontSize="12px"
                color={page === 'Samskriti' ? '#ffffff' : '#464038'}
                textDecoration="none"
                p="13px 20px 13px 11px"
                // bgImage={`${config.imgPath}/d/46272/1667332321-down-arrow.png`}
                bgImage={`url(${config.staticPath}/d/46272/${
                  isOpenProfile
                    ? '1667474630-icon_angle-up'
                    : '1667474632-icon_angle-down'
                }.svg)`}
                backgroundSize="13px"
                backgroundRepeat="no-repeat"
                backgroundPosition="100% 55%"
                _hover={{
                  color: '#cf4520',
                  // bgImage: `url('${config.imgPath}/d/46272/1666775830-angle-down-h.svg')`,
                }}
              >
                {userName}{' '}
              </Box>
            </Box>
          </Link>
          <Box
            position="absolute"
            width="149px"
            right="0"
            top="37px"
            left="0"
            pt="2px"
            borderRadius="4px"
            zIndex="9"
            display="none"
            className={`${isOpenProfile ? 'open' : ''}`}
          >
            <Box
              float="left"
              width="100%"
              backgroundColor="#fff"
              border="solid 1px #f0f0f0"
              boxShadow="0 0 5px rgba(0, 0, 0, 0.2)"
            >
              <UnorderedList m="0" p="0" className="user_navigation">
                <ListItem display="block">
                  <Box
                    display="block"
                    fontFamily="'FedraSansStd-book', sans-serif"
                    fontSize="12px"
                    color="#cf4520"
                    textDecoration="none"
                    p="8px 0px"
                    m="5px 0"
                    fontWeight="300"
                  >
                    <NextLink
                      // href={`${config.MY_PROFILE_URL}/profile?cb=${config.BASE_PATH}${router.asPath}`}
                      href={`${refineUrlDomain(
                        loggedInMenu && loggedInMenu[0]?.url,
                        region,
                        language
                      )}/profile?cb=${config.BASE_PATH}${router.asPath}`}
                      prefetch={false}
                      _hover={{
                        bgColor: '#e4ded4',
                        color: '#cf4520',
                      }}
                      legacyBehavior
                    >
                      {loggedInMenu?.[0]?.title ?? 'My Profile'}
                    </NextLink>
                  </Box>
                </ListItem>
                {/* We dont show Recurring Donations for US and Canada Regions */}
                {region !== 'us' && region !== 'ca' && (
                  <ListItem display="block">
                    <NextLink
                      // href={`/en/contribute/my-account/recurring-donations`}
                      href={`${refineUrlDomain(
                        loggedInMenu && loggedInMenu[1]?.url,
                        region,
                        language
                      )}`}
                      prefetch={false}
                      legacyBehavior
                    >
                      <Link
                        display="block"
                        fontFamily="'FedraSansStd-book', sans-serif"
                        fontSize="12px"
                        color="#cf4520"
                        textDecoration="none"
                        p="8px 9px"
                        m="5px"
                        fontWeight="300"
                        _hover={{
                          bgColor: '#e4ded4',
                          color: '#cf4520',
                        }}
                      >
                        {loggedInMenu && loggedInMenu[1]?.title}
                      </Link>
                    </NextLink>
                  </ListItem>
                )}
                <ListItem display="block">
                  <NextLink href={logoutURL} prefetch={false} legacyBehavior>
                    <Link
                      // onClick={logoutHandler}
                      display="block"
                      fontFamily="'FedraSansStd-book', sans-serif"
                      fontSize="12px"
                      color="#cf4520"
                      textDecoration="none"
                      p="8px 9px"
                      m="5px"
                      fontWeight="300"
                      _hover={{
                        bgColor: '#e4ded4',
                        color: '#cf4520',
                      }}
                    >
                      {loggedInMenu && loggedInMenu[2]?.title}
                    </Link>
                  </NextLink>
                </ListItem>
              </UnorderedList>
            </Box>
          </Box>
          <style jsx global>{`
            .open {
              display: block !important;
            }
            .user_navigation a {
              color: #525252;
            }
            .user_navigation li:hover {
              background: #e4ded4;
            }
            .user_navigation li:hover {
              background: #e4ded4;
            }
            .user_navigation li a {
              font-family: FedraSansStd-book, sans-serif;
              font-size: 12px;
              padding: 8px 9px;
              margin: 5px;
            }
            .user_navigation li:first-child {
              margin-top: 10px;
            }
            .user_navigation li:hover {
              background: #e4ded4;
            }
            .user_navigation li a {
              font-family: FedraSansStd-book, sans-serif;
              font-size: 12px;
              padding: 8px 9px;
              margin: 5px;
            }
            .user_navigation li:first-child {
              margin-top: 10px;
            }
            .user_navigation a:hover {
              color: #cf4520;
            }
            .user_navigation li a {
              font-family: FedraSansStd-book, sans-serif;
              font-size: 12px;
              padding: 8px 9px;
              margin: 5px;
              color: #464038;
            }
            .user_navigation li:first-child {
              margin-top: 10px;
            }
            .user_navigation a:hover {
              color: #cf4520;
            }
          `}</style>
        </Box>
      )}
    </>
  );
};

export default LoggedIn;
