/* Built In Imports */
import { useState } from 'react';

/* External Imports */
import { Box, Link, Text } from '@chakra-ui/react';

/* Internal Imports */
/* Components */
import config from '@config';

/* Styles */
/* Services */

/**
 * Renders LinkItem component
 *
 * @param {string} url
 * @param {string} title
 * @returns LinkItem component
 */

const LinkItem = ({ url, title }) => {
  const [visible, setVisible] = useState(false);
  return (
    <Link
      href={url}
      target={
        url?.indexOf('http') > -1 && url?.indexOf(config.cdnPath) < 0
          ? '_blank'
          : ''
      }
      transition="all 0.2s linear"
    >
      <Box
        p="0"
        m="0"
        w="auto"
        height="auto"
        display="flex"
        alignItems={{ base: 'flex-start', lg: 'center' }}
        justifyContent={{ base: 'flex-start', lg: 'center' }}
        flexDir="column"
        // onMouseEnter={e => setVisible(true)}
        // onMouseLeave={e => setVisible(false)}
        // onMouseOver={e => setVisible(true)}
        onMouseEnter={() => setVisible(true)}
        onMouseLeave={() => setVisible(false)}
        onMouseOver={() => setVisible(true)}
      >
        <Link
          color={visible ? '#DB3030' : '#8E8E8E'}
          // pb={{ base: '0', md: '20px' }}
          textDecor="none"
          lineHeight={{ base: '32px', md: '32px', lg: '18px' }}
          fontSize="body2"
          // transition="all 0.2s linear"
          // borderColor="#db3030"
          _hover={{
            color: '#DB3030',
            // borderBottom: '2px solid #DB3030',
            textDecor: 'none',
          }}
          // className="footerLink"

          fontFamily="FedraSansStd-book"
          userSelect="none"
          href={url}
          target={
            url?.indexOf('http') > -1 && url?.indexOf(config.cdnPath) < 0
              ? '_blank'
              : ''
          }
        >
          <Text as="span">{title}</Text>
        </Link>

        <Box
          transition="all 0.2s linear"
          pt={{ base: '0', md: '20px' }}
          display={{ base: 'none', lg: 'block' }}
          borderBottom={visible ? '2px solid #DB3030' : '2px solid transparent'}
          w="60px"
          h="2px"
        ></Box>
      </Box>
    </Link>
  );
};
export default LinkItem;
