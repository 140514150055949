/* Built In Imports */
import { useEffect, useRef, useState } from 'react';

/* External Imports */
import YouTube from 'react-youtube';

/* Internal Imports */
export default function LiteYouTubeEmbed(props) {
  const [player, setPlayer] = useState(null);
  const [opts, setOpts] = useState({});
  const [autoplay, setAutoPlay] = useState(false);

  useEffect(() => {
    window.addEventListener('scroll', listenToScroll);
    return () => window.removeEventListener('scroll', listenToScroll);
  }, []);
  const isVisInViewport = element => {
    const rect = element?.getBoundingClientRect();
    // console.log('rect', rect.top, Math.abs(rect.top), (rect.height / 1.3));
    return (
      // rect.top >= 0 &&
      rect?.left >= 0 && Math.abs(rect.top) <= rect.height
    );
  };
  const listenToScroll = () => {
    const scrollDiv = document.getElementById(props.boxId);
    const inViewPort = isVisInViewport(scrollDiv);

    if (inViewPort) {
      setAutoPlay(true);
    } else {
      setAutoPlay(false);
    }
  };

  const vidRef = useRef();
  const [preconnected, setPreconnected] = useState(false);
  const [iframe, setIframe] = useState(false);
  const videoId = encodeURIComponent(props.id);
  const videoTitle = props.title;
  const posterImp = props.poster || 'hqdefault';
  const announceWatch = props.announce || 'Watch';
  const format = props.webp ? 'webp' : 'jpg';
  const vi = props.webp ? 'vi_webp' : 'vi';
  const posterUrl =
    props.thumbnail ||
    `https://i.ytimg.com/${vi}/${videoId}/${posterImp}.${format}`;

  let ytUrl = props.noCookie
    ? 'https://www.youtube-nocookie.com'
    : 'https://www.youtube.com';
  ytUrl = props.cookie
    ? 'https://www.youtube.com'
    : 'https://www.youtube-nocookie.com';

  const activatedClassImp = props.activatedClass || 'lyt-activated';
  const adNetworkImp = props.adNetwork || false;
  const aspectHeight = props.aspectHeight || 9;
  const aspectWidth = props.aspectWidth || 16;
  const playerClassImp = props.playerClass || 'lty-playbtn';
  const wrapperClassImp = props.wrapperClass || 'yt-lite';
  const rel = props.rel ? 'prefetch' : 'preload';

  const warmConnections = () => {
    if (preconnected) return;
    setPreconnected(true);
  };

  const addIframe = () => {
    if (iframe) return;
    setIframe(true);
  };

  useEffect(() => {
    setOpts({
      height: '100%',
      width: '100%',
      playerVars: {
        autoplay: 1,
      },
    });
  }, [global?.window]);
  // const togglePlay = () => {
  //   setIsPlaying(!isPlaying);
  //   if (isPlaying) {
  //     player.pauseVideo();
  //   } else {
  //     player.playVideo()
  //   }
  // }
  const _onReady = event => {
    setPlayer(event.target);
    event.target.playVideo();
  };
  // const checkAuto = () => {
  //   if (props.scrollBehavior) { }
  //   if (props.autoplay) {
  //     player?.playVideo()
  //   } else {
  //     // setIsPlaying(false)
  //     player?.pauseVideo()
  //     // console.log(player, "player", props.autoplay)
  //   }
  // }
  useEffect(() => {
    if (props.scrollBehavior && player?.length) {
      if (player?.length && autoplay) {
        player?.playVideo();
      } else if (player?.length) {
        // setIsPlaying(false)
        player?.pauseVideo();
        // console.log(player, "player", props.autoplay)
      }
    }
  }, [player, autoplay]);
  function onPlayerStateChange(event) {
    // if (event.data == YT.PlayerState.PLAYING && !done) {
    //   setTimeout(stopVideo, 6000);
    //   done = true;
    // }
    console.log(event.data, vidRef, 'onplayer state change');
  }
  // function stopVideo() {
  //   player.stopVideo();
  // }
  return (
    <>
      <link rel={rel} href={posterUrl} as="image" />
      <>
        {preconnected && (
          <>
            <link rel="preconnect" href={ytUrl} />
            <link rel="preconnect" href="https://www.google.com" />
            {adNetworkImp && (
              <>
                <link rel="preconnect" href="https://static.doubleclick.net" />
                <link
                  rel="preconnect"
                  href="https://googleads.g.doubleclick.net"
                />
              </>
            )}
          </>
        )}
      </>
      <article
        onPointerOver={warmConnections}
        onClick={addIframe}
        className={`${wrapperClassImp} ${iframe ? activatedClassImp : ''}`}
        data-title={videoTitle}
        style={{
          backgroundImage: !iframe ? `url(${posterUrl})` : '#000',
          ...{
            '--aspect-ratio': `${(aspectHeight / aspectWidth) * 100}%`,
          },
        }}
      >
        <button
          type="button"
          className={playerClassImp}
          aria-label={`${announceWatch} ${videoTitle}`}
        />
        {iframe && (
          <YouTube
            onStateChange={onPlayerStateChange}
            videoId={props.embedUrl}
            onReady={_onReady}
            ref={vidRef}
            opts={opts}
            style={{
              position: 'absolute',
              width: '100%',
              height: '100%',
              top: '0',
              left: '0',
            }}
          />
        )}
      </article>
      {/* <button onClick={togglePlay}>{isPlaying ? "Pause" : "Play"}</button> */}
    </>
  );
}
