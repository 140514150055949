/* Built In Imports */
/* External Imports */
/* Internal Imports */
/* Components */
import config from '@config';

/* Services */
import { BaseService } from './BaseService';

const ISO_URL = config.FACADE_BASE_PATH;
// consoleLog('ISO_URL>>', ISO_URL);
const COMMON_PREFIX = '';

/**
 *
 */
export class IsoBackendHttpService extends BaseService {
  constructor() {
    super(`${COMMON_PREFIX}`, ISO_URL);
  }
}
