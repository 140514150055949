/* Built In Imports */
/* External Imports */
import { Box } from '@chakra-ui/react';
import map from 'lodash/map';

/* Internal Imports */
/* Components */
/* Services */

/**
 * Renders Static Looper Component
 * @param {Object} children - Child Elements
 * @param {Number} loopCount - Count of the Loop 
 * @returns {ReactElement} Static Looper Component
 */
export default function StaticLooper({ children, loopCount = 0 }) {
  let loopArray = [];

  for (let i = 0; i < loopCount; i++) {
    loopArray.push(i);
  }

  return (
    <>
      {map([...Array(loopCount)], index => (
        <Box key={index}>{children}</Box>
      ))}
    </>
  );
}
