const LangStyle = {
  ar: {
    alignRTL: 'right',
    float: 'left',
    flexRowRTL: 'row-reverse',
    fontSize: '24px',
    fontSizeQuote: '24px',
    headingFont: 'FedraSerifAStdBook',
    headingFontSize: '45px',
    summaryFont: 'FedraSansStd-book-i',
    summaryFontSize: '20px',
    articleBodyFont: 'FedraSansStd-book',
    articleBodyFontSize: '20px',
    pullQuoteFont: 'FedraSerifAStdBook',
    pullQuoteFontSize: '32px',
    pullQuoteColor: '#6f6358',
  },
  he: {
    alignRTL: 'right',
    float: 'left',
    flexRowRTL: 'row-reverse',
    fontSize: '24px',
    fontSizeQuote: '24px',
    headingFont: 'FedraSerifAStdBook',
    headingFontSize: '45px',
    summaryFont: 'FedraSansStd-book-i',
    summaryFontSize: '20px',
    articleBodyFont: 'FedraSansStd-book',
    articleBodyFontSize: '20px',
    pullQuoteFont: 'FedraSerifAStdBook',
    pullQuoteFontSize: '32px',
    pullQuoteColor: '#6f6358',
    direction: 'rtl',
    unicodeBidi: 'bidi-override',
  },
  fa: {
    alignRTL: 'right',
    float: 'left',
    flexRowRTL: 'row-reverse',
    fontSize: '24px',
    fontSizeQuote: '24px',
    headingFont: 'FedraSerifAStdBook',
    headingFontSize: '45px',
    summaryFont: 'FedraSansStd-book-i',
    summaryFontSize: '20px',
    articleBodyFont: 'FedraSansStd-book',
    articleBodyFontSize: '20px',
    pullQuoteFont: 'FedraSerifAStdBook',
    pullQuoteFontSize: '32px',
    pullQuoteColor: '#6f6358',
    direction: 'rtl',
    unicodeBidi: 'bidi-override',
  },
  bn: {
    titleFontWeight: '500',
    pullQuoteColor: '#28231e',
    flexRowRTL: 'row',
  },
  de: {
    titleFontWeight: '500',
    flexRowRTL: 'row',
  },
  en: {
    titleFontWeight: '500',
    flexRowRTL: 'row',
  },
  gu: {
    titleFontWeight: '500',
    pullQuoteColor: '#28231e',
    flexRowRTL: 'row',
  },
  hi: {
    fontFamily: 'Mukta',
    fontSize: '22px',
    fontSizeQuote: '22px',
    headingFont: 'Mukta',
    summaryFont: 'Mukta',
    articleFontFamily: 'Mukta',
    pullQuoteColor: '#28231e',
    flexRowRTL: 'row',
  },
  kn: {
    pullQuoteFont: 'FedraSerifAStdBook',
    pullQuoteFontSize: '32px',
    pullQuoteColor: '#6f6358',
    flexRowRTL: 'row',
  },
  ml: {
    fontSizeQuote: '18px',
  },
  mr: {
    titleFontWeight: '400',
    headingFont: 'FedraSerifAStdBook',
  },
  ta: {
    fontFamily: 'MuktaMalar',
    fontSize: '22px',
    fontSizeQuote: '22px',
    headingFont: 'MuktaMalar',
    summaryFont: 'MuktaMalar',
    articleFontFamily: 'MuktaMalar',
    pullQuoteColor: '#28231e',
    flexRowRTL: 'row',
    fontWeight: 'bold',
  },
};

export default LangStyle;
