/* Built In Imports */
import { useRouter } from 'next/router';

/* External Imports */
import { Box, Link, Text } from '@chakra-ui/react';

/* Internal Imports */
/* Components */
import config from '@config';

/* Services */

/**
 *
 * @param {Object} headTopMenuData
 * @returns
 */
const NotLoggedIn = ({ type, headTopMenuData, page }) => {
  // const loggedinMenu = headTopMenuData[7]?.submenuItems;
  const router = useRouter();
  const loginURL = `${config.FACADE_BASE_PATH}/login?request_url=${config.BASE_PATH}${router.asPath}&action=0`;
  const signupURL = `${config.FACADE_BASE_PATH}/login?request_url=${config.BASE_PATH}${router.asPath}&action=1`;

  return (
    <>
      {type == 'mobile' ? (
        <Box float="left" mt="25px" ml="20px">
          <Link
            href={loginURL}
            float="left"
            fontSize="14"
            color={page === 'Samskriti' ? '#ffffff' : '#464038'}
            fontFamily="FedraSansStd-medium,sans-serif"
            textDecoration="none"
            mr="8px"
          >
            {headTopMenuData[6]?.title}
          </Link>
          <Box
            as="span"
            color="#464038"
            float="left"
            position={'relative'}
            top="-2px"
          >
            |
          </Box>
          <Link
            href={signupURL}
            float="left"
            fontSize="14"
            color={page === 'Samskriti' ? '#ffffff' : '#464038'}
            fontFamily="FedraSansStd-medium,sans-serif"
            textDecoration="none"
            ml="8px"
            pos="relative"
          >
            {headTopMenuData[7]?.title}
          </Link>
        </Box>
      ) : (
        <Box mt="11px" pos="relative">
          <Link
            href={loginURL}
            float="left"
            width="auto"
            fontSize="12"
            color={page === 'Samskriti' ? '#ffffff' : '#464038'}
            fontFamily="FedraSansStd-medium,sans-serif"
            _hover={{ color: '#cf4824' }}
            pr="2px"
          >
            {headTopMenuData[6]?.title}
          </Link>
          <Text
            as="span"
            float="left"
            width="auto"
            fontSize="12"
            color={page === 'Samskriti' ? '#ffffff' : '#464038'}
            m="0 1px 0 0"
            fontWeight="bold"
            lineHeight="inherit"
          >
            &nbsp;|&nbsp;
          </Text>
          <Link
            href={signupURL}
            float="left"
            width="auto"
            fontSize="12"
            color={page === 'Samskriti' ? '#ffffff' : '#464038'}
            fontFamily="FedraSansStd-medium,sans-serif"
            _hover={{ color: '#cf4824' }}
            pl="2px"
          >
            {headTopMenuData[7]?.title}
          </Link>
        </Box>
      )}
    </>
  );
};

export default NotLoggedIn;
