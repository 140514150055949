/* Built In Imports */
/* External Imports */
import { Box, Text } from '@chakra-ui/react';
import { render } from 'datocms-structured-text-to-html-string';
import { nanoid } from 'nanoid';
import Slider from 'react-slick';

/* Internal Imports */
/* Components */
import config from '@config';
import LazyLoadImageComponent from '@components/UI/LazyLoadImageComponent';
import StructuredTextParser from '@msr/Utility/StructuredTextParser';

/* Styles */
import 'slick-carousel/slick/slick-theme.css';
import 'slick-carousel/slick/slick.css';

/**
 * Renders the ImageWithTextSliderGroup component.
 *
 * @param sectionContent.sectionContent
 * @param {object} sectionContent component.
 * @param {string} lang - Language of page
 * @param sectionContent.lang
 * @param sectionContent.structure
 * @returns {ReactElement} ImageWithTextSliderGroup component.
 */
const ImageWithTextSliderGroup = ({ sectionContent, lang, structure }) => {
  const NextArrow = props => {
    const { onClick } = props;
    return (
      <Box
        pos="absolute"
        top="50%"
        zIndex="10"
        cursor="pointer"
        bgSize="60px"
        bgColor="transparent"
        border="0"
        borderRadius="0"
        w={{base:'32px', md:'40px'}}
        h={{base:'32px', md:'40px'}}
        bgPos="center"
        bgRepeat="no-repeat"
        color="transparent"
        bgImage={`url(${config.imgPath}/d/46272/1719906048-icon_sliderrightarrow.png)`}
        right={{base:'-15px', md:'15px'}}
        onClick={onClick}
      >
      </Box>
    );
  };

  const PrevArrow = props => {
    const { onClick } = props;
    return (
      <Box
        pos="absolute"
        top="50%"
        zIndex="17"
        cursor="pointer"
        bgSize="60px"
        bgImage={`url(${config.imgPath}/d/46272/1719906063-icon_sliderleftarrow.png)`}
        bgColor="transparent"
        border="0"
        borderRadius="0"
        w={{base:'32px', md:'40px'}}
        h={{base:'32px', md:'40px'}}
        bgPos="center"
        bgRepeat="no-repeat"
        color="transparent"
        left={{base:'0px', md:'15px'}}
        onClick={onClick}
      >
      </Box>
    );
  };

  const settings = {
    dots: false,
    speed: 500,
    autoplay: false,
    autoplaySpeed: 8000,
    slidesToShow: 1,
    slidesToScroll: 1,
    centerMode: false,
    infinite: true,
    nextArrow: <NextArrow />,
    prevArrow: <PrevArrow />,
    responsive: [
      {
        breakpoint: 834,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          initialSlide: 1,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
    customPaging: function (i) {
      return (
        <Text
          cursor="pointer"
          display="inline-block"
          w="10px"
          className="dots-slider"
          h="10px"
          m={{ base: '40px 8px 0 8px', md: '25px 8px 0 8px' }}
          bgColor="#C0955F"
          borderRadius="50%"
        />
      );
    },
  };

  return (
    <Box
      m={{
        base: '40px auto 0 auto',
        md: '48px auto 0 auto',
        lg: '37px auto 0 auto',
      }}
      w={{
        base: '100%',
        sm: structure ? '100%' : '500px',
        md: structure ? '100%' : '558px',
      }}
      maxW="100%"
      overflow="hidden"
      p="0px 15px 30px 15px"
    >
      <Slider {...settings} m="0" mt={{ base: '40px', md: '48px', lg: '37px' }}>
        {sectionContent?.cards?.map((data, i) => {
          return (
            <>
              <Box
                overflow="hidden"
                p="0 5%"
                key={nanoid()}
                textAlign="center"
                fontFamily="FedraSansStd-book"
              >
                {data?.thumbnail?.url && (
                  <LazyLoadImageComponent
                    src={data?.thumbnail?.url}
                    alt={data?.thumbnail?.alt}
                    title={data?.thumbnail?.title}
                    width={{ base: '100%', sm: 'auto', md: 'auto' }}
                    h={{ base: 'auto', md: '468.54px' }}
                    verticalAlign="middle"
                    minW={{ base: '321px', md: '370px' }}
                    pb="1vw"
                  />
                )}

                {data?.title && (
                  <Box
                    as="h3"
                    fontSize="26px"
                    lineHeight="33.15px"
                    mt="50px"
                    fontFamily="FedraSerifAStdBook"
                  >
                    {data?.title}
                  </Box>
                )}

                {data?.description && (
                  <Box
                    fontSize="16px"
                    mt="15px"
                    fontFamily="FedraSansStd-book"
                    textAlign="center"
                  >
                    <StructuredTextParser
                      str={render(data.description)}
                      region={''}
                      lang={lang}
                      textAlign="center"
                    />
                  </Box>
                )}
              </Box>
            </>
          );
        })}
      </Slider>
      <style>
        {`
            .structured-content-dark {
              margin-bottom: 0;
            }
            .testimonial-thevaram h2{
                color:white;
                text-align:center;
          font-style:normal;
          font-weight:500;
          font-size:40px;
          line-height:120%;
          color:#38281D;
          font-family:FedraSerifAStdBook;
          margin-bottom:16px;
            }
            .testimonial-thevaram p{
              font-style: normal;
font-weight: 400;
font-size: 16px;
line-height: 150%;
text-align:center;
            }
            @media only screen and (max-width: 400px) {
              .testimonial-thevaram h2 {
    text-align:left;
    margin-bottom:14px;
  }
  .testimonial-thevaram p {
    text-align:left;
  }
}

                .slick-active .dots-slider{
                  border:2px solid #38281D;
                }
    
           
       
            `}
      </style>
    </Box>
  );
};

export default ImageWithTextSliderGroup;
