/* Built In Imports */
import dynamic from 'next/dynamic';
import NextLink from 'next/link';

/* External Imports */
import { Box, Flex, Link, List, ListItem, Text } from '@chakra-ui/react';
import { nanoid } from 'nanoid';

/* Components */
import LazyLoadImageComponent from '@components/UI/LazyLoadImageComponent';
import LinkItem from '@lb/components/Footer/LinkItem';
// import SubscribeBox from '@lb/components/Subscribe/SubscribeBox';
import { refineUrlDomain } from '@lb/components/Utility/utils';
import config from 'config';
/* Styles */
/* Services */
const SubscribeBox = dynamic(() =>
  import('@lb/components/Subscribe/SubscribeBox')
);

/**
 * Returns LBFooter
 *
 * @param region
 * @param language
 * @param footerData
 * @param pageConfig
 * @param page
 */

const LBFooter = ({ region, language, footerData,
  // pageConfig, page
}) => {
  const footerLogo = footerData?.allIsoMainMenus[7]?.submenuItems;
  let signUp = {};

  const signUpNew =
    footerData?.allIsoMainMenus[8]?.submenuItems[0].customComponent[0]?.content
      ?.json;
  if (signUpNew?.customComponent) {
    signUp =
      footerData?.allIsoMainMenus[8]?.submenuItems[0]?.customComponent[0]
        ?.content?.json;
  }
  const addTo = footerData?.allIsoMainMenus[9]?.submenuItems;
  // const isMobile = useMediaQuery({ maxWidth: 360 });

  return footerLogo?.length > 0 && signUp && addTo?.length > 0 ? (
    <Box
      background="linear-gradient(180deg, #352525 0%, #000000 100%)"
      className="footer"
      as="footer"
      width="100%"
    >
      <Box
        maxW="1330px"
        margin={{ base: 0, lg: '0 auto' }}
        background={`url(${config.imgPath}/d/46272/1683175350-footer_right_bg.png) no-repeat left top`}
        backgroundSize={{ base: '35%', sm: 'auto' }}
      >
        {/* Logo for mobile */}
        <Text
          textAlign="center"
          display={{ base: 'block', md: 'block', lg: 'none' }}
          pt={{ base: 7, lg: '0' }}
        >
          <Link
            href={`/linga-bhairavi/${region}/${language}`}
            aria-label="Link to home page"
          >
            <LazyLoadImageComponent
              src={footerLogo[0]?.icon?.url}
              alt={footerLogo[0]?.title}
            />
          </Link>
        </Text>
        <Box
          py={{ base: '2rem', md: '2.5rem', lg: '2.6rem' }}
          px={{ base: '1rem', lg: '0' }}
          display="flex"
          justifyContent={{ sm: 'center', md: 'end' }}
          gap="30px"
        >
          <Box
            maxW="1180px"
            // mx="auto"
            margin={{ base: '0', md: '0 59.59px 0 auto' }}
            width="100%"
            bg={{
              base: 'transparent',
              md: 'transparent',
              lg: 'linear-gradient(270deg, #362E2E -2.19%, rgba(53, 43, 43, 0) 50%)',
            }}
            padding={{
              base: '0px',
              md: '0px 30px 42px 0px',
              lg: '42px 30px 42px 0',
            }}
          >
            {/* main menu */}
            <List
              listStyleType="none"
              display="flex"
              flexWrap="wrap"
              flexDir={{ base: 'column', md: 'column', lg: 'row' }}
              fontSize="body2"
              w="100%"
              margin="auto"
              pl={{ base: '0', sm: '0px' }}
              maxW="827px"
              justifyContent="space-between"
              borderBottom={{
                base: 'none',
                md: 'noe',
                lg: '0.872212px solid #5A3B3B',
              }}
              pb={{ base: '19px', lg: '0' }}
            >
              {footerData?.allIsoMainMenus?.map((menu) => {
                return (
                  <>
                    {menu?.url && (
                      <ListItem key={nanoid()}>
                        <LinkItem
                          url={refineUrlDomain(menu?.url, region, language)}
                          title={menu?.title}
                        />
                      </ListItem>
                    )}
                  </>
                );
              })}
            </List>
            <Flex
              w={{ base: '100%', md: 'auto' }}
              flexWrap="wrap"
              alignItems={{ base: 'flex-start', lg: 'end' }}
              justifyContent={{ base: 'flex-start', lg: 'end' }}
              flexDir={{
                base: 'column-reverse',
                md: 'column-reverse',
                lg: 'row',
              }}
              padding={{
                base: '30px 0',
                lg: '40px 15px 0px 15px',
                xl: '40px 15px 0px 0px',
              }}
              bg={{
                base: 'linear-gradient(270deg, #362E2E -2.19%, rgba(53, 43, 43, 0) 50%)',
                md: 'transparent',
              }}
            >
              <Box flex="1" display={{ base: 'none', md: 'none', lg: 'block' }}>
                <Box display={{ base: 'none', md: 'none', lg: 'block' }}>
                  {/* Logo for Desktop */}
                  <Link
                    href={`/linga-bhairavi/${region}/${language}`}
                    aria-label={`link to home page`}
                  >
                    <LazyLoadImageComponent
                      src={footerLogo[0]?.icon?.url}
                      alt={footerLogo?.icon?.alt || footerLogo[0]?.title}
                      height="auto"
                      width="183px"
                      objectFit="cover"
                    />
                  </Link>
                </Box>
                {/* Social Links*/}
                <Box>
                  <Flex
                    color="#DB3030"
                    fontSize="22px"
                    width="170px"
                    marginTop="20px"
                    alignItems="flex-end"
                    justifyContent="space-between"
                    mb="10px"
                    style={{ gap: '10px' }}
                  >
                    <Box>
                      <Link
                        href={refineUrlDomain(
                          footerLogo?.[1].url,
                          region,
                          language
                        )}
                        target="_blank"
                        aria-label={`link to ${footerLogo[1]?.key} page`}
                      >
                        <LazyLoadImageComponent
                          src={footerLogo[1]?.icon?.url}
                          alt={footerLogo[1]?.icon?.title}
                        />
                      </Link>

                      {/* <FaInstagram /> */}
                    </Box>
                    <Box>
                      <Link
                        href={refineUrlDomain(
                          footerLogo?.[2].url,
                          region,
                          language
                        )}
                        target="_blank"
                        aria-label={`link to ${footerLogo[2]?.key} page`}
                      >
                        {/* {( */}
                        <LazyLoadImageComponent
                          src={footerLogo[2]?.icon?.url}
                          alt={footerLogo[2]?.title}
                        />
                      </Link>
                      {/* ) && <Text>{footerLogo[2]?.title}</Text>} */}
                      {/* <FaTwitter /> */}
                    </Box>
                    <Box>
                      {/* <FaFacebook /> */}
                      {/* {( */}
                      <Link
                        href={refineUrlDomain(
                          footerLogo[3]?.url,
                          region,
                          language
                        )}
                        target="_blank"
                        aria-label={`link to ${footerLogo[3]?.key} page`}
                      >
                        <LazyLoadImageComponent
                          src={footerLogo[3]?.icon?.url}
                          alt={footerLogo[3]?.title}
                        />
                      </Link>
                      {/* ) && <Text>{footerLogo[3]?.title}</Text>} */}
                    </Box>
                  </Flex>
                  <Box marginTop="10px">
                    <Text
                      fontSize="body1"
                      color="#E19254"
                      fontWeight={350}
                      fontFamily="'FedraSerifAStdBook', system-ui, sans-serif"
                      //textTransform="uppercase"
                      fontVariantCaps="small-caps"
                    >
                      {footerLogo[4]?.title}
                    </Text>
                    <Text
                      fontSize="15.17px"
                      color="#8E8E8E"
                      fontWeight={350}
                      className="subtext"
                      fontFamily="FedraSansStd-Book"
                    >
                      {footerLogo[4]?.subtext}
                    </Text>
                  </Box>
                </Box>
              </Box>
              {/* Footer Form Section for desktop */}
              {footerData?.allIsoMainMenus[8]?.title ===
                'Footer Newsletter section' && (
                  <Box
                    width={{ base: '100%', lg: 'auto' }}
                    textAlign={{ base: 'left', md: 'left', lg: 'center' }}
                    display="flex"
                    flexDir="column"
                    alignItems={{
                      base: 'flex-start',
                      md: 'flex-start',
                      lg: 'center',
                    }}
                  >
                    <Text
                      fontSize={'26px'}
                      lineHeight="34.6px"
                      mt={{ base: '0', md: '40px', lg: '0' }}
                      color="#8E8E8E"
                      textAlign={{ base: 'left', md: 'left', lg: 'center' }}
                      fontFamily="FedraSansStd-book"
                      mb="7px"
                      maxW={{ base: '300px', lg: '400px' }}
                    >
                      <Text as={'span'} color="#E19254" fontWeight={400}>
                        {signUpNew?.titleHighlight}
                      </Text>
                      &nbsp;
                      <Text as="span" fontWeight={300}>
                        {signUpNew?.title}
                      </Text>
                    </Text>
                    <Text
                      maxW={{ base: '300px', md: '300px', lg: 447 }}
                      color="#8E8E8E"
                      mb="10px"
                      fontSize={{ base: '12px', md: '12px', lg: 'body3' }}
                      lineHeight="20px"
                      fontWeight="400"
                      fontFamily="FedraSansStd-book"
                      fontStyle="normal"
                      className="signUpDescription"
                    >
                      {signUpNew?.description}
                    </Text>
                    {signUpNew && (
                      <SubscribeBox
                        region={region}
                        lang={language}
                        sectionContent={
                          footerData?.allIsoMainMenus[8]?.submenuItems[0]
                            ?.customComponent[0]
                        }
                      />
                    )}
                  </Box>
                )}

              {/* Mobile add to section with logo */}
              <Box
                flex="1"
                textAlign={{ base: 'left', md: 'left', lg: 'right' }}
                display="flex"
                alignItems={{ lg: 'flex-end', md: 'center', base: 'center' }}
                mb={{ base: '40px', md: '0' }}
                width="100%"
                // style={{ gap: '14px' }}
                flexDir={{ base: 'row', md: 'row', lg: 'column' }}
                minW="240px"
              >
                <Box
                  as="span"
                  display={{ base: 'block', md: 'block', lg: 'none' }}
                  mr="14px"
                >
                  {/* Devi Image */}
                  <LazyLoadImageComponent
                    src={`${config.imgPath}/d/46272/1683176997-footerlogo.png`}
                    alt={footerLogo?.icon?.alt || footerLogo[0]?.title}
                    // height="91px"
                    mr="5px"
                    // alt=""
                    width="auto"
                  />
                </Box>
                {/* ADD To Desktop section */}
                <Box>
                  <NextLink href={addTo[0]?.url} passHref legacyBehavior>
                    <Link target="_blank">
                      <Text
                        // fontsize={{ base: '20px', md: 'h6 !important' }}
                        fontSize={{ base: '20px', md: '20px', lg: 'h6' }}
                        color="#DB3030"
                        cursor="pointer"
                        textDecor="underline"
                        fontFamily="FedraSansStd-book"
                        // fontsize={{ base: '20px', md: 'h6' }}
                        w={{ base: '193px', md: '193px', lg: '205px' }}

                        fontWeight={300}
                        as="h6"
                        mb={{ base: '0', md: '0', lg: '15px' }}
                        lineHeight={{ base: '36px', md: '36px', lg: '43.44px' }}
                      >
                        {addTo[0]?.title}
                      </Text>
                    </Link>
                  </NextLink>

                  <Text
                    fontSize="12px"
                    color="#8E8E8E"
                    lineHeight="20.32px"
                    w={{ base: '193px', md: '193px', lg: '205px' }}
                    fontFamily="FedraSansStd-book"
                  >
                    {addTo[0]?.subtext}
                  </Text>
                </Box>
                {/* Desktop copyright */}
                <Box>
                  <Text
                    fontSize="body3"
                    color="#A67D7D"
                    lineHeight="20px"
                    display={{ base: 'none', md: 'none', lg: 'block' }}
                    fontFamily="FedraSansStd-book"
                  >
                    <NextLink
                      href={refineUrlDomain(addTo[1]?.url, region, language)}
                      passHref
                      legacyBehavior>
                      <Link>
                        <Text color="#A67D7D" my="10px">
                          {addTo[1]?.title}
                        </Text>
                      </Link>
                    </NextLink>
                    <NextLink
                      href={refineUrlDomain(addTo[2]?.url, region, language)}
                      passHref
                      legacyBehavior>
                      <Link>
                        <Text my="10px" color="#A67D7D">
                          {addTo[2]?.title}
                        </Text>
                      </Link>
                    </NextLink>
                    <Box border="0.872212px solid #5A3B3B" p={0} m="0" />
                    <Text
                      my="10px"
                      color="#785151"
                      pl={{ base: 0, md: 5 }}
                      w={{ base: '100%', md: '203px' }}
                      lineHeight="1.25rem"
                      letterSpacing=".5px"
                      fontSize="body3"
                    >
                      {addTo[3]?.title}
                    </Text>
                  </Text>
                </Box>
              </Box>
            </Flex>
            <Box
              display={{ base: 'grid', md: 'grid', lg: 'none' }}
              // placeItems="center"
              mt="20px"
            >
              {/* Social links for mobile */}
              <Flex
                color="#DB3030"
                fontSize="22px"
                width={{ base: '160px', md: '160px', lg: '270px' }}
                mx="auto"
                marginTop="20px"
                justifyContent="space-between"
                style={{ gap: '10px' }}
              >
                <Box>
                  <Link
                    href={refineUrlDomain(
                      footerLogo?.[1].url,
                      region,
                      language
                    )}
                    target="_blank"
                    aria-label={`link to LingaBhairavi ${footerLogo[1]?.key} page`}
                  >
                    <LazyLoadImageComponent
                      src={footerLogo[1]?.icon?.url}
                      alt={footerLogo[1]?.icon?.alt || footerLogo?.title}
                    />
                  </Link>
                </Box>
                <Box>
                  <Link
                    href={refineUrlDomain(
                      footerLogo?.[2].url,
                      region,
                      language
                    )}
                    target="_blank"
                    aria-label={`link to LingaBhairavi ${footerLogo[2]?.title} page`}
                  >
                    {/* {( */}
                    <LazyLoadImageComponent
                      src={footerLogo[2]?.icon?.url}
                      alt={footerLogo[2]?.icon?.alt || footerLogo[2]?.title}
                    />
                  </Link>
                </Box>
                <Box>
                  <Link
                    href={refineUrlDomain(footerLogo[3]?.url, region, language)}
                    target="_blank"
                    aria-label={`link to LingaBhairavi ${footerLogo[3]?.title} page`}
                  >
                    <LazyLoadImageComponent
                      src={footerLogo[3]?.icon?.url}
                      alt={footerLogo[3]?.icon?.alt || footerLogo[3]?.title}
                    />
                  </Link>
                </Box>
              </Flex>
              <Box marginTop="18px" textAlign="center" mb={{ base: '40px' }}>
                <Text
                  fontSize="16.57px"
                  color="#E19254"
                  fontWeight={350}
                  lineHeight="23.2px"
                >
                  {footerLogo[4]?.title}
                </Text>
                <Text fontSize="14px" color="#8E8E8E" lineHeight="20px">
                  {footerLogo[4]?.subtext}
                </Text>
              </Box>
              {/* Mobile copyright section */}
              <Box color="#A67D7D" fontSize="body3">
                <NextLink
                  href={refineUrlDomain(addTo[1]?.url, region, language)}
                  passHref
                  legacyBehavior>
                  <Link>
                    <Text
                      textAlign="left"
                      lineHeight={{ base: '32px', md: '32px', lg: '20px' }}
                      fontSize={{ base: 'body2', md: 'body2', lg: 'body3' }}
                      fontWeight="400"
                    >
                      {addTo[1]?.title}
                    </Text>
                  </Link>
                </NextLink>
                <NextLink
                  href={refineUrlDomain(addTo[2]?.url, region, language)}
                  passHref
                  legacyBehavior>
                  <Link>
                    <Text
                      textAlign="left"
                      lineHeight={{ base: '32px', md: '32px', lg: '20px' }}
                      fontSize={{ base: 'body2', md: 'body2', lg: 'body3' }}
                      mb={{ base: '20px', md: '13px' }}
                      fontWeight="400"
                    >
                      {addTo[2]?.title}
                    </Text>
                  </Link>
                </NextLink>
                <Box borderBottom="0.872212px solid #A67D7D" p="0" m={0}></Box>
                <Text
                  lineHeight="20px"
                  py={{ base: '20px' }}
                  fontFamily="FedraSansStd-book"
                >
                  {addTo[3]?.title}
                </Text>
              </Box>
            </Box>
          </Box>
        </Box>
      </Box>
    </Box>
  ) : null;
};

export default LBFooter;
