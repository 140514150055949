/* Built In Imports */

/* External Imports */
import filter from 'lodash/filter';
import forEach from 'lodash/forEach';

/* Internal Imports */
/* Components */
// import { ShortCodeProcess } from '@components/Utility/ArticleParser/ShortCode';
import config from '@config';

/* Services */

/**
 * Renders the should Refresh Component
 *
 * @param {string} url - Link
 * @returns {ReactElement} - should Refresh Component
 */
export const shouldRefresh = url => {
  // consoleLog('shouldRefresh', url);
  // ? check for landing pages
  return false;
  // return (
  //   !!(
  //     url.indexOf('/sadhguru') < 0 &&
  //     url.indexOf('/wisdom') < 0 &&
  //     url.indexOf('/health') < 0 &&
  //     url.indexOf('/center') < 0 &&
  //     url.indexOf('/yoga-meditation') < 0 &&
  //     url.indexOf('/events') < 0 &&
  //     url.indexOf('/volunteer') < 0 &&
  //     url.indexOf('/donation') < 0 &&
  //     url.indexOf('/search') < 0 &&
  //     url.indexOf('/blog') < 0 &&
  //     url.indexOf('/news') < 0 &&
  //     url.indexOf('/inner-engineering-online') < 0 &&
  //     url.indexOf('/sadhguru-radio') < 0 &&
  //     url.indexOf('/topic') < 0 &&
  //     url.indexOf('/quotes') < 0 &&
  //     url.indexOf('/project-samskriti') < 0
  //   )
  //   // || url.indexOf('/center/isha-institute-inner-sciences-usa') > -1
  // );
};

export const refreshPage = (url, e) => {
  shouldRefresh(url) &&
    setTimeout(() => {
      window.location.href = url;
    }, 500);
};

export const getCardBgColors = cardType => {
  let bgColors = ['#DC9E41', '#D4782E', '#B05913', '#B6791F'];
  switch (cardType) {
    case 'health':
      bgColors = ['#7A9235', '#436533', '#528945', '#8CA753'];
      break;
    case 'event':
      bgColors = ['#01365D', '#004D85', '#18679F', '#00487B'];
      break;
    case 'yoga':
      bgColors = ['#DC9E41', '#D4782E', '#B05913', '#B6791F'];
      break;
  }
  return bgColors;
};

export const getCookie = name => {
  if (typeof window !== 'undefined') {
    // console.log('document-------',name, document.cookie);
    const value = `; ${document.cookie}`;
    const parts = value?.split(`; ${name}=`);
    if (parts.length === 2) return parts.pop()?.split(';').shift();
  }
};

let domainList = [
  '//prs.innerengineering.com',
  '//prs.isha.in',
  '//sadhana.sadhguru.org',
  'mokshatwo.sushumna.isha.in',
  'goyip.netlify.app',
  'ishangam.isha.in',
  'msrsp.sadhguru.org',
  'ishaoutreach.org',
  'iycblr.sadhguru.org',
  'sp.isha.in',
  'uat-facade-sgexclusive.sadhguru.org/user/createPaymentReferenceNew',
  'sgexclusive.sadhguru.org/user/createPaymentReferenceNew',
  'ishaprs.sushumna.isha.in',
  'online.sadhguru.org',
  'online2.sadhguru.org',
  'registration.innerengineering.com',
  'qa-prs8.innerengineering.com',
  'lingabhairavi.org',
  'beta.lingabhairavi.org',
  'hys.sushumna.isha.in',
  'hys.isha.in',
  'isha.co',
  'poornanga.sushumna.isha.in',
  'poornanga.isha.in',
  'preprod-online.sadhguru.org',
  'registration.innerengineering.com',
  'innerengineering.sadhguru.org',
  'payment.sadhguru.org',
  'online.innerengineering.com',
  'qa-ishangam.isha.in',
];

export const isOpenInNewTab = url => {
  let isUrlAvailableForUtmParam = [];
  if (
    isUrlAvailableForUtmParam?.indexOf('http') > -1 &&
    url?.indexOf(config.cdnPath) < 0
  ) {
    isUrlAvailableForUtmParam = [url];
  } else {
    isUrlAvailableForUtmParam = filter(domainList, function (domain) {
      return url.indexOf(domain) > -1;
    });
  }
  return isUrlAvailableForUtmParam?.length > 0;
};

export const handleUtmParams = (url, extraDomains) => {
  if (extraDomains?.length) {
    domainList = domainList.concat(extraDomains);
  }

  const cookieParams = [
    'utm_source',
    'utm_medium',
    'utm_campaign',
    'utm_content',
    'utm_term',
  ];
  let newUrl = '';

  const isUrlAvailableForUtmParam = filter(domainList, function (domain) {
    return url.indexOf(domain) > -1;
  });

  if (isUrlAvailableForUtmParam.length) {
    // console.log('isUrlAvailableForUtmParam', isUrlAvailableForUtmParam, url);
    const nUrl = new URL(url);
    const search_params = new URLSearchParams(nUrl.search);
    // let search_params = nUrl.searchParams;

    forEach(cookieParams, function (cookie) {
      const cookieVal = getCookie(cookie);

      if (cookieVal) {
        search_params.set(cookie, cookieVal);
      }
    });
    // change the search property of the main url
    nUrl.search = search_params.toString();

    // the new url string
    newUrl = nUrl.toString();
  } else {
    newUrl = url;
  }
  return newUrl;
};

export const refineUrlDomain = (url, region, lang, extraDomains) => {
  let paramUrl = url;
  if (url?.indexOf('[envurl') > -1) {
    const updatedUrlArr = url
      .replace('][/envurl]', '')
      ?.replace('[envurl ', '')
      ?.split(' ');
    forEach(updatedUrlArr, function (upUrl) {
      if (config.ENV_NAME === 'production') {
        if (upUrl?.indexOf('prod=') > -1) {
          paramUrl = upUrl.replace("prod='", '').replace("'", '');
        }
      } else {
        if (upUrl?.indexOf('uat=') > -1) {
          paramUrl = upUrl.replace("uat='", '').replace("'", '');
        }
      }
    });
  }
  // url?.indexOf('[envurl') > -1 ? ShortCodeProcess(url.toString()) : url;

  const checkRegionDomain = () => {
    const regions = ['in', 'global', 'au', 'ca', 'my', 'sg', 'uk', 'us'];
    const languages = ['en', 'hi', 'ta', 'te', 'kn', 'ml', 'mr', 'gu', 'np'];

    let isMatched = false;
    forEach(regions, function (reg) {
      forEach(languages, function (language) {
        if (!isMatched) {
          isMatched =
            paramUrl?.toLowerCase().indexOf(`/${reg}/${language}/`) === 0;
        }
      });
    });
    // checking if it contains /en/ structure
    forEach(languages, function (language) {
      if (!isMatched && paramUrl?.toLowerCase().indexOf(`/${language}/`) > -1) {
        isMatched = true;
      }
    });
    return isMatched;
  };

  let finalUrl = '';

  if (!paramUrl) {
    finalUrl = '#!';
  } else if (
    (paramUrl && paramUrl[0] === '#') ||
    paramUrl?.toLowerCase().indexOf('https://') >= 0 ||
    paramUrl?.toLowerCase().indexOf('http://') >= 0 ||
    paramUrl?.toLowerCase().indexOf('mailto:') >= 0 ||
    paramUrl?.toLowerCase().indexOf('()') >= 0
  ) {
    finalUrl = paramUrl;
  } else if (checkRegionDomain()) {
    // consoleLog('inside checkRegionDomain()', `/${region}/${lang}${url[0] === '/' ? '' : '/'}${url}`);
    finalUrl = `${paramUrl[0] === '/' ? '' : '/'}${paramUrl}`;
  } else {
    const isNonRegionalized = checkIsNonRegionalized(paramUrl, lang);
    if (isNonRegionalized) {
      finalUrl = `/${lang}${paramUrl[0] === '/' ? '' : '/'}${paramUrl}`;
    } else if (region !== undefined || lang !== undefined) {
      finalUrl = `/${region}/${lang}${paramUrl[0] === '/' ? '' : '/'
        }${paramUrl}`;
    } else {
      finalUrl = `/${paramUrl[0] === '/' ? '' : '/'
        }${paramUrl}`;
    }
  }
  finalUrl = handleUtmParams(finalUrl, extraDomains);
  return finalUrl;
};

export const getEmbedUrl = url => {
  let retUrl = '';
  if (!url) {
    return retUrl;
  }
  if (url.indexOf('/watch') > -1) {
    retUrl = url?.split('=')[1];
  } else if (url.indexOf('/embed/') > -1 || url.indexOf('/youtu.be/') > -1) {
    const splitArr = url?.split('/');
    const splittedUrl = splitArr[splitArr.length - 1];
    retUrl =
      splittedUrl?.split('?').length > 1
        ? splittedUrl?.split('?')[0]
        : splittedUrl;
  } else {
    retUrl = url;
  }
  return retUrl;
};

const checkIsNonRegionalized = (url, lang, region) => {
  let isMatched = false;
  const newUrl = url?.split('?')[0]?.replace(`/${lang}/`, '');
  // const replaceUrl = url.replace(`/${region}/${lang}/`, '/');
  // console.log('checkIsNonRegionalized', url, replaceUrl, region, lang);
  if (url.replace(`/${region}/${lang}/`, '/').startsWith('/sadhguru')) {
    // console.log('startswith sadhguru', newUrl, lang);
  }
  if (
    newUrl?.split('/')[0]?.endsWith('sadhguru') ||
    (newUrl.startsWith('/') && newUrl?.split('/')[1]?.endsWith('sadhguru'))
  ) {
    isMatched = true;
  }
  if (
    newUrl?.split('/')[0]?.endsWith('wisdom') ||
    (newUrl.startsWith('/') && newUrl?.split('/')[1]?.endsWith('wisdom'))
  ) {
    isMatched = true;
  }
  if (
    newUrl?.split('/')[0]?.endsWith('blog') ||
    (newUrl.startsWith('/') && newUrl?.split('/')[1]?.endsWith('blog'))
  ) {
    isMatched = true;
  }
  if (
    newUrl?.split('/')[0]?.endsWith('topic') ||
    (newUrl.startsWith('/') && newUrl?.split('/')[1]?.endsWith('topic'))
  ) {
    isMatched = true;
  }
  if (
    newUrl?.split('/')[0]?.endsWith('quotes') ||
    (newUrl.startsWith('/') && newUrl?.split('/')[1]?.endsWith('quotes'))
  ) {
    isMatched = true;
  }
  // if (config.ENV_NAME === 'local' || config.ENV_NAME === 'uat') {
  if (
    newUrl?.split('/')[0]?.endsWith('health') ||
    (newUrl.startsWith('/') && newUrl?.split('/')[1]?.endsWith('health'))
  ) {
    isMatched = true;
  }
  if (
    newUrl?.split('/')[0]?.endsWith('center') ||
    (newUrl.startsWith('/') && newUrl?.split('/')[1]?.endsWith('center'))
  ) {
    isMatched = true;
  }
  if (
    newUrl?.split('/')[0]?.endsWith('outreach') ||
    (newUrl.startsWith('/') && newUrl?.split('/')[1]?.endsWith('outreach'))
  ) {
    isMatched = true;
  }
  // }
  return isMatched;
};

export const isNonRegionalized = (url, lang, region) => {
  return checkIsNonRegionalized(url, lang, region);
};

export const setAudioPlayer = (audioUrl, tag) => {
  let audioPlayer = document.querySelector('.audio-player'); // from poem
  if (tag) {
    // from audio or power press / from html parser
    audioPlayer = document.querySelector(`.powerpress`);
  }
  const audio = new Audio(audioUrl);

  //   It loads and play the audio file on click of play button for the first time. Because of Closure and IIFE function.
  const playAudio = (function () {
    let executed = false;
    return function () {
      if (!executed && !tag) {
        executed = true;
        const playBtn =
          audioPlayer && audioPlayer.querySelector('.controls .toggle-play');
        playBtn.classList.remove('play');
        playBtn.classList.add('pause');
        audio.play();
      }
    };
  })();

  playAudio();

  console.dir(audio);

  audio.addEventListener(
    'loadeddata',
    () => {
      audioPlayer.querySelector(
        '.time .length'
      ).textContent = `/ ${getTimeCodeFromNum(audio.duration)} `;
      audio.volume = 0.75;
    },
    false
  );

  // click on timeline to skip around
  const timeline = audioPlayer && audioPlayer.querySelector('.timeline');
  timeline &&
    timeline.addEventListener(
      'click',
      e => {
        const timelineWidth = window.getComputedStyle(timeline).width;
        const timeToSeek =
          (e.offsetX / parseInt(timelineWidth, 10)) * audio.duration;
        audio.currentTime = timeToSeek;
      },
      false
    );

  // click volume slider to change volume
  const volumeSlider =
    audioPlayer && audioPlayer.querySelector('.controls .volume-slider');
  volumeSlider &&
    volumeSlider.addEventListener(
      'click',
      e => {
        const sliderWidth = window.getComputedStyle(volumeSlider).width;
        const newVolume = e.offsetX / parseInt(sliderWidth, 10);
        audio.volume = newVolume;
        audioPlayer.querySelector(
          '.controls .volume-percentage'
        ).style.width = `${newVolume * 100}%`;
      },
      false
    );

  // check audio percentage and update time accordingly
  setInterval(() => {
    const progressBar = audioPlayer?.querySelector('.progress');
    if (progressBar) {
      progressBar.style.width = `${(audio.currentTime / audio.duration) * 100
        }%`;
    }
    if (audioPlayer) {
      audioPlayer.querySelector('.time .current').textContent =
        getTimeCodeFromNum(audio.currentTime);
    }
  }, 500);

  // toggle between playing and pausing on button click
  const playBtn =
    audioPlayer && audioPlayer.querySelector('.controls .toggle-play');

  playBtn &&
    playBtn.addEventListener(
      'click',
      () => {
        if (audio.paused) {
          playBtn.classList.remove('play');
          playBtn.classList.add('pause');
          audio.play();
        } else {
          playBtn.classList.remove('pause');
          playBtn.classList.add('play');
          audio.pause();
        }
      },
      false
    );

  audioPlayer &&
    audioPlayer
      .querySelector('.volume-button')
      .addEventListener('click', () => {
        const volumeEl = audioPlayer.querySelector('.volume-container .volume');
        audio.muted = !audio.muted;
        if (audio.muted) {
          volumeEl.classList.remove('icono-volumeMedium');
          volumeEl.classList.add('icono-volumeMute');
        } else {
          volumeEl.classList.add('icono-volumeMedium');
          volumeEl.classList.remove('icono-volumeMute');
        }
      });

  // turn 128 seconds into 2:08
  /**
   *
   * @param num
   */
  function getTimeCodeFromNum(num) {
    let seconds = parseInt(num, 10);
    let minutes = parseInt(seconds / 60, 10);
    seconds -= minutes * 60;
    const hours = parseInt(minutes / 60, 10);
    minutes -= hours * 60;

    if (hours === 0) return `${minutes}:${String(seconds % 60).padStart(2, 0)}`;
    return `${String(hours).padStart(2, 0)}:${minutes}:${String(
      seconds % 60
    ).padStart(2, 0)}`;
  }
};

/**
 *
 * @param {Array} arr
 * @param {keyName} keyName
 * @returns  //converts array of string into array of obj
 */
export const handleArray = (arr, keyName = 'item') => {
  const temp = [];
  if (Array.isArray(arr)) {
    arr.map(item => {
      if (typeof item == 'string') {
        const obj = { [keyName]: item };
        temp.push(obj);
      }
    });
    return temp;
  }
};
