/* Built In Imports */
/* External Imports */
/* Internal Imports */
/* Components */
import developmentConfig from './development';
import development_homeConfig from './development_home';
import localConfig from './local';
import productionConfig from './production';
import production_homeConfig from './production_home';
import qaConfig from './qa';
import stage_homeConfig from './stage_home';
import stage_v2Config from './stage_v2';
import stagingConfig from './staging';
import uatConfig from './uat';
import uat_homeConfig from './uat_home';

/* Services */

export {
  developmentConfig,
  development_homeConfig,
  localConfig,
  productionConfig,
  production_homeConfig,
  qaConfig,
  stage_homeConfig,
  stage_v2Config,
  stagingConfig,
  uatConfig,
  uat_homeConfig,
};
