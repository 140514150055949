/* Built In Imports */
import NextLink from 'next/link';
import { useRouter } from 'next/router';
import { useEffect, useState } from 'react';

/* External Imports */
import {
  Box,
  Image,
  Link,
  ListItem,
  Text,
  UnorderedList,
} from '@chakra-ui/react';

/* Internal Imports */
/* Components */
import StaticLooper from '@components/Layout/StaticLooper';
import LoggedIn from '@components/NewHeader/LoggedIn';
import NotLoggedIn from '@components/NewHeader/NotLoggedIn';
import MenuLI from '@components/UI/NewMobileMenuLI';
import { isNonRegionalized, refineUrlDomain } from '@components/Utility/utils';
import config from '@config';

/* Services */

/**
 * Renders the Mobile Menu component
 *
 * @param {object} headerMenuData - data
 * @param {string} region - country
 * @param {string} language language
 * @param {boolean} isOpenMobMenu
 * @param {boolean} isLoggedIn
 * @param {boolean} isStaticPage
 * @param {Object} ipWidgetData
 * @returns {ReactElement} Mobile Menu component
 */

const MobileMenu = ({
  headerMenuData,
  headTopMenuData,
  countryDropdownHandler,
  currentUserRegion,
  renderRegionsList,
  isOpenCountry,
  mobileMenuHandler,
  isOpenMobMenu,
  loginURL,
  countryDropdownRegionHandler,
  isLoggedIn,
  profileMobileDropdownHandler,
  isOpenProfile,
  region,
  language,
  isStaticPage,
  ipWidgetData,
}) => {
  const [isOpenMobSubMenu, setIsOpenMobSubMenu] = useState(false);
  const [isOpenMobThirdLevelMenu, setIsOpenMobThirdLevelMenu] = useState(false);
  const [isOpenThirdLevelMenuText, setIsOpenThirdLevelMenuText] = useState('');
  const [isOpenMenuText, setIsOpenMenuText] = useState('');
  const [isActive, setIsActive] = useState(false);
  const [isDropdownActive, setDropdownActive] = useState({
    profilemenu: false,
    countrymenu: false,
  });
  const router = useRouter();
  language = 'en';

  const mobileSubMenuHandler = menu => {
    setIsOpenMenuText(menu);
    setIsOpenMobSubMenu(!isOpenMobSubMenu);
  };
  const mobileThirdLevelMenuHandler = menu => {
    setIsOpenThirdLevelMenuText(menu);
    setIsOpenMobThirdLevelMenu(!isOpenMobThirdLevelMenu);
  };

  /**
   *
   */
  // function countryDropdownHandlerMobile() {
  //   if (isDropdownActive.profilemenu == false) {
  //     profileMobileDropdownHandler();
  //   }
  //   countryDropdownHandler();
  //   setIsActive(!isActive);
  // }
  //Handle country dropdown active styles mobile
  useEffect(() => {
    const clickOutsideCountry = document.getElementById(
      'countryDropdownMobile'
    );
    document.addEventListener('click', function (event) {
      const isClickInsideElement = clickOutsideCountry?.contains(event.target);
      if (!isClickInsideElement) {
        setIsActive(false);
      }
    });
  }, [isActive]);
  const renderMobileMenu = () => {
    return headerMenuData?.allIsoMainMenus?.map(item => {
      const url = refineUrlDomain(item?.url, region, language);
      const subMenuItems = [];
      if (item.submenuItems && item.submenuItems.length > 0) {
        item.submenuItems.map(item => {
          const subURL = refineUrlDomain(
            item?.url?.replace('/wisdom-intermediate', '/wisdom/type'),
            region,
            language
          );
          // let subURL;
          // const modifiedURL =
          //   item?.url?.replace('/wisdom-intermediate', '/wisdom/type');
          // if (modifiedURL.indexOf('https://') >= 0) {
          //   subURL = modifiedURL;
          // } else {
          //   subURL = `/${region}/${modifiedURL.indexOf('/sadhguru') > -1
          //     ? router.query.language
          //     : language
          //     }${modifiedURL}`;
          // }
          // if (
          //   subURL.indexOf('https://') < 0 &&
          //   subURL.indexOf(`/${region}/${language}/`) < 0
          // ) {
          //   subURL = `/${region}/${modifiedURL.indexOf('/sadhguru') > -1
          //     ? router.query.language
          //     : language
          //     }${modifiedURL}`;
          // } else if (subURL.indexOf('https://') >= 0) {
          //   subURL = modifiedURL;
          // }
          const subSubMenuItems = [];
          if (item.submenuItems && item.submenuItems.length > 0) {
            item.submenuItems.map(item => {
              const subSubURL = refineUrlDomain(item?.url, region, language);
              // const subSubURL =
              //   item?.url.indexOf('https://') >= 0
              //     ? item?.url
              //     : `/${region}/${language}${item?.url}`;
              subSubMenuItems.push(
                <MenuLI
                  text={item.title}
                  url={subSubURL}
                  key={item.title}
                  className={'third-level-mobile-menu'}
                  clicked={mobileMenuHandler}
                />
              );
            });
          }
          const listItem =
            subSubMenuItems.length > 0 ? (
              <MenuLI
                text={item.title}
                url={subURL}
                key={item.title}
                className={`${
                  isOpenMobThirdLevelMenu &&
                  item.title == isOpenThirdLevelMenuText
                    ? 'active' //'active'
                    : ''
                } ${isOpenMobSubMenu ? 'bottom-mobile-menu' : ''}`}
                clicked={mobileMenuHandler}
              >
                <UnorderedList
                  className={`submenu-opened ${
                    isOpenMobThirdLevelMenu &&
                    item.title === isOpenThirdLevelMenuText
                      ? null
                      : 'submenu-closed'
                  }`}
                >
                  {subSubMenuItems}
                </UnorderedList>
                <Text
                  as="span"
                  onClick={() => mobileThirdLevelMenuHandler(item.title)}
                  position="absolute"
                  width="60px"
                  height="60px"
                  backgroundColor={`${
                    isOpenMobThirdLevelMenu &&
                    item.title == isOpenThirdLevelMenuText
                      ? 'rgba(0,0,0,.4);'
                      : 'rgba(0, 0, 0, 0.05)'
                  }`}
                  backgroundImage={`${
                    isOpenMobThirdLevelMenu &&
                    item.title == isOpenThirdLevelMenuText
                      ? "url('/assets/images/white-chevron-up.svg')"
                      : "url('/assets/images/chevron-down.svg')"
                  }`}
                  backgroundSize="15px"
                  backgroundRepeat="no-repeat"
                  backgroundPosition="center"
                  cursor="pointer"
                  right="0"
                  top="0"
                  transition="all 0.6s ease"
                ></Text>
              </MenuLI>
            ) : (
              <MenuLI
                text={item.title}
                url={subURL}
                key={item.title}
                className={isOpenMobSubMenu ? 'sec-level-mobile-menu' : ''}
                clicked={mobileMenuHandler}
              />
            );
          subMenuItems.push(listItem);
        });
      }

      return (
        <MenuLI
          text={item.title}
          url={url}
          key={item.title}
          clicked={mobileMenuHandler}
        >
          <UnorderedList
            m="0"
            className={
              isOpenMobSubMenu && item.title == isOpenMenuText
                ? null
                : 'submenu-closed'
            }
          >
            {subMenuItems}
          </UnorderedList>
          {!isStaticPage && subMenuItems != '' && (
            <Text
              as="span"
              onClick={() => {
                mobileSubMenuHandler(item.title);
              }}
              position="absolute"
              width="60px"
              height="55px"
              backgroundColor={`${
                isOpenMobSubMenu && item.title == isOpenMenuText
                  ? 'rgba(0,0,0,.4);'
                  : 'rgba(0, 0, 0, 0.05)'
              }`}
              backgroundImage={`${
                isOpenMobSubMenu && item.title == isOpenMenuText
                  ? "url('/assets/images/white-chevron-up.svg')"
                  : "url('/assets/images/chevron-down-white.svg')"
              }`}
              backgroundSize="15px"
              backgroundRepeat="no-repeat"
              backgroundPosition="center"
              cursor="pointer"
              right="0"
              top="0"
              transition="all 0.6s ease"
            ></Text>
          )}
        </MenuLI>
      );
    });
  };

  return (
    <Box
      display={{ base: 'flex', xl: 'none' }}
      justifyContent="right"
      width="100%"
      height={{ base: '62px', sm: '80px', md: '62px', lg: '76px', xl: '62px' }}
      p={{
        base: '0 15px 0 15px',
        sm: '0 15px 0 15px',
        md: '0 30px 0 30px',
        lg: '0 15px 0 15px',
      }}
      backgroundColor={{ base: '#e4ded4', xl: '#f5f1e9' }}
      className={'mobile_nav'}
    >
      <Link
        href={
          // isStaticPage ? `/${config.cdnPath}` : `/${region}/${language}`
          isStaticPage
            ? config.cdnPath
            : `${refineUrlDomain('/', region, language)}`
        }
        width={{ base: '45px', sm: '46px', md: '47px', xl: '45px' }}
        mt={{ base: '13px', sm: '11px', md: '12px', xl: '13px' }}
        justifyContent="left"
        flexShrink={'0'}
        flexGrow="1"
        ml={{
          base: 'unset',
          md: '15px',
        }}
        mr="38%"
      >
        <Image
          loading="lazy"
          src="/assets/images/logo.png"
          width="45px"
          alt="logo"
        />
      </Link>
      {/* {!isStaticPage && (          */}
      <Box
        width="21px"
        cursor="pointer"
        mt={{ base: '22px', sm: '23px', md: '26px' }}
        mr={{ base: '10%', sm: '10%', lg: '20px' }}
      >
        {headTopMenuData[10]?.rootMenuOrder == '11' && (
          <Link href={refineUrlDomain('/search', region, language)}>
            <Image
              loading="lazy"
              // src={`${config.staticPath}/d/46272/1663582866-search.svg`}
              src={headTopMenuData[10]?.icon?.url}
              float="left"
              width="100%"
              alt="search"
            />
          </Link>
        )}
      </Box>
      <Box
        width="20px"
        cursor="pointer"
        m={{ base: '23px 8px 0 0', sm: '20px 8px 0 0' }}
        fontSize="18px"
        fontWeight="200"
        verticalAlign="middle"
        onClick={mobileMenuHandler}
      >
        <StaticLooper loopCount={3}>
          <Box
            as="span"
            display="block"
            width="23px"
            height="2px"
            borderRadius="6px"
            backgroundColor="#676055"
            m="4px 0"
          ></Box>
        </StaticLooper>
      </Box>
      <Box
        position="fixed"
        width="100%"
        height="100vh"
        top="0"
        backgroundColor="#e4ded4"
        zIndex="99"
        transition="all 0.6s ease"
        overflow="auto"
        left={` ${isOpenMobMenu ? '0' : 'inherit'}`}
        display={` ${isOpenMobMenu ? 'block' : 'none'}`}
      >
        <Box height="70px" backgroundColor="#E0D7C8" display="flex">
          {!isLoggedIn && (
            <NotLoggedIn type="mobile" headTopMenuData={headTopMenuData} />
          )}
          {isLoggedIn && (
            <LoggedIn
              profileDropdownHandler={profileMobileDropdownHandler}
              isOpenProfile={isOpenProfile}
              type="mobile"
              headTopMenuData={headTopMenuData}
              isDropdownActive={isDropdownActive}
              region={region}
              language={language}
            />
          )}
          <Box marginLeft="auto" display="flex">
            <Box display="inline-block" w="67px" height="100%" mr="10px">
              {!isNonRegionalized(router.asPath, language) && (
                <Box cursor="pointer">
                  <Box
                    onClick={countryDropdownHandler}
                    id="countryDropdownMobile"
                    mt="6px"
                  >
                    <Box
                      fontFamily="'FedraSansStd-book', 'sans-serif'"
                      fontSize="18px"
                      color={`${isActive ? '#cf4520' : '#000000'}`}
                      textDecoration="none"
                      padding="15px 25px"
                      bgImage={`url(${config.staticPath}/d/46272/${
                        isOpenCountry
                          ? '1667474630-icon_angle-up'
                          : '1667474632-icon_angle-down'
                      }.svg)`}
                      backgroundSize="13px"
                      backgroundRepeat="no-repeat"
                      backgroundPosition="90% 55%"
                      textTransform="capitalize"
                      cursor="pointer"
                    >
                      <Image
                        src={`${
                          isOpenCountry
                            ? `${headTopMenuData[5]?.iconHover?.url}`
                            : `${headTopMenuData[5]?.icon?.url}`
                        }`}
                        alt="Country"
                        width="110%"
                        maxW="none"
                      />
                    </Box>
                  </Box>
                  <Box
                    position="absolute"
                    width="180px"
                    right="12px"
                    top="54px"
                    pt="2px"
                    zIndex="9"
                    display={isOpenCountry ? 'block' : 'none'}
                  >
                    <Box
                      width="100%"
                      backgroundColor="#fff"
                      border="solid 1px #f0f0f0"
                      boxShadow="0 0 5px rgba(0, 0, 0, 0.2)"
                      borderRadius="5px"
                    >
                      <UnorderedList m="0px" p="0px">
                        {renderRegionsList()}
                      </UnorderedList>
                    </Box>
                  </Box>
                </Box>
              )}
            </Box>
            <Box
              // float="right"
              margin-left="auto"
              width="70px"
              height="70px"
              backgroundColor="#CDC3B3"
              backgroundImage={`${config.imgPath}/d/46272/1666772244-mobile-menu-cloe.svg`}
              backgroundSize="20px"
              backgroundRepeat="no-repeat"
              backgroundPosition="center"
              cursor="pointer"
              onClick={mobileMenuHandler}
            ></Box>
          </Box>
        </Box>
        <Box width="100%" backgroundColor="#e4ded4">
          <UnorderedList m="0">
            {renderMobileMenu()}
            <Box
              display={'flex'}
              m={'0 0 0 0'}
              background="#464038"
              padding="19px 0"
              fontFamily="FedraSansStd-book"
              className="programfinder"
            >
              <Image
                src={headTopMenuData[9]?.icon?.url}
                alignSelf={'baseline'}
                margin="5px 10px 0 24px"
              />
              <Link
                color="#DBD4C6"
                fontSize="14px"
                lineHeight={'26px'}
                _hover={{
                  textDecoration: 'none',
                }}
                // href={`/${region}/${language}${headTopMenuData[9]?.url}`}
                href={refineUrlDomain(
                  headTopMenuData[9]?.url,
                  region,
                  language
                )}
              >
                {headTopMenuData[9]?.title}
              </Link>
            </Box>
          </UnorderedList>
        </Box>
        <Box
          width="100%"
          backgroundColor="#e4ded4"
          p={'0 0'}
          bottom="0px"
          position="fixed"
        >
          <UnorderedList m="0px">
            <ListItem
              bgColor="#D2C8B5"
              color="#cca146"
              textTransform="capitalize"
              padding="0px"
            >
              <NextLink
                // href={`/${region}/${language}${headTopMenuData[4]?.url}`}
                href={refineUrlDomain(
                  headTopMenuData[4]?.url,
                  region,
                  language
                )}
                passHref
                legacyBehavior
              >
                <Link
                  color="#cca146"
                  fontFamily="FedraSansStd-book,sans-serif"
                  fontSize="18px"
                  border="0"
                  padding="0"
                  height="auto"
                  _hover={{ color: '#cf4520' }}
                  display="block"
                >
                  <Image
                    src={headTopMenuData[4]?.icon?.url}
                    alt="Sadhguru Exclusive"
                    padding="10px 0 10px 0px"
                  />
                </Link>
              </NextLink>
            </ListItem>
            <Box w="100%" fontFamily="FedraSansStd-medium" background="#E0D7C8">
              {headTopMenuData != '' && (
                <>
                  <UnorderedList
                    color="#797160"
                    padding="17px 0 17px 0"
                    display="flex"
                    justifyContent="space-around"
                    margin="0 0 0 5px"
                    className="topmenu"
                  >
                    <ListItem
                      display={'inline'}
                      margin="0 15px 0 0"
                      fontSize="14px"
                      color="#797160"
                    >
                      <NextLink
                        // href={`/${region}/${language}${headTopMenuData[1]?.url}`}
                        href={refineUrlDomain(
                          headTopMenuData[1]?.url,
                          region,
                          language
                        )}
                        passHref
                        legacyBehavior
                      >
                        <Link
                          _hover={{
                            color: '#cf4520',
                          }}
                        >
                          <Image
                            src={headTopMenuData[1]?.icon?.url}
                            alt={headTopMenuData[1]?.icon?.alt}
                            margin="0 7px 0 0"
                            height="19px"
                            width="auto"
                          />{' '}
                          {headTopMenuData[1]?.title}
                        </Link>
                      </NextLink>
                    </ListItem>
                    <ListItem display={'inline'} margin="0 25px 0 0">
                      <NextLink
                        // href={`/${region}/${language}${headTopMenuData[2]?.url}`}
                        href={refineUrlDomain(
                          headTopMenuData[2]?.url,
                          region,
                          language
                        )}
                        passHref
                        legacyBehavior
                      >
                        <Link
                          _hover={{
                            color: '#cf4520',
                          }}
                        >
                          <Image
                            src={headTopMenuData[2]?.icon?.url}
                            alt={headTopMenuData[2]?.icon?.alt}
                            margin="0 5px 0 0"
                            height="18px"
                            width="auto"
                          />
                          {headTopMenuData[2]?.title}
                        </Link>
                      </NextLink>
                    </ListItem>
                    <ListItem display={'inline'} margin="0 25px 0 0">
                      <NextLink
                        // href={`${headTopMenuData[3]?.url}`}
                        href={refineUrlDomain(
                          headTopMenuData[3]?.url,
                          region,
                          language
                        )}
                        passHref
                        legacyBehavior
                      >
                        <Link
                          _hover={{
                            color: '#cf4520',
                          }}
                        >
                          <Image
                            src={headTopMenuData[3]?.icon?.url}
                            alt={headTopMenuData[3]?.icon?.alt}
                            margin="0 5px 0 0"
                            height="18px"
                            width="auto"
                          />
                          {headTopMenuData[3]?.title}
                        </Link>
                      </NextLink>
                    </ListItem>
                  </UnorderedList>
                </>
              )}
            </Box>
          </UnorderedList>
        </Box>
      </Box>
      <style jsx global>{`
        .mobile_nav a,
        .mobile_nav .topmenu a,
        .mobile_nav .Header_block__Jcnzr a {
          color: #464038;
        }
        .mobile_nav li a {
          color: #dbd4c6;
          font-weight: 600;
        }
        .open {
          display: block;
        }
        .title {
          font-weight: bold;
          font-size: 14px;
        }
        .active {
          background-color: #e4ded4;
          color: #cf4520;
        }
        .submenu-closed {
          margin: 0;
          padding: 0;
          display: none;
        }
        .sec-level-mobile-menu,
        .bottom-mobile-menu {
          font-family: FedraSansStd-book, sans-serif !important;
          // font-weight: 500 !important;
          font-size: 16px !important;
          background-color: #f0eae0 !important;
          color: #464038 !important;
          border: none;
          text-decoration: none;
        }
        .mobile_nav li .bottom-mobile-menu a {
          font-weight: bold;
        }
        .third-level-mobile-menu {
          font-family: FedraSansStd-book, sans-serif !important;
          font-size: 16px !important;
          font-weight: 500 !important;
          color: #464038 !important;
          background-color: #faf5ed !important;
        }
        .bottom-mobile-menu {
          background-color: #f5f1e9;
          height: 60px;
        }
        .programfinder a {
          color: #dbd4c6;
        }
      `}</style>
    </Box>
  );
};

export default MobileMenu;
