/* Built In Imports */
import { createContext, useEffect, useState } from 'react';

/* External Imports */
import { useCookies } from 'react-cookie';

/* Internal Imports */
/* Components */
import config from '@config';

export const AuthContext = createContext({
  ssoSessionId: '',
  facadeSessionId: '',
  userName: '',
  userImage: '',
  isLoginComplete: false,
  isLoggedIn: false,
  lang: '',
  region: '',
  login: {},
  logout: {},
  setRegionAndLang: {},
  uk_cookie: '',
  setRegionUKCookies: {},
});

export const AuthContextProvider = props => {
  const envName =
    config.ENV_NAME === 'production'
      ? ''
      : config.ENV_NAME === 'development'
      ? 'dev_'
      : config.ENV_NAME + '_';
  const [cookie, setCookie, removeCookie] = useCookies([
    envName + 'login_sso_sessid',
  ]);
  const [ssoSessionId, setSsoSessionId] = useState(
    cookie[envName + 'login_sso_sessid']
  );
  const [facadeSessionId, setFacadeSessionId] = useState(
    cookie[envName + 'facad_session_id']
  );
  const [userImage, setUserImage] = useState(cookie[envName + 'profile_img']);
  const [userName, setUserName] = useState(cookie[envName + 'profile_name']);
  const [region, setRegion] = useState('');
  const [lang, setLang] = useState('');
  const [ukCookie, setUKCookie] = useState(cookie.uk_cookie);
  // console.log('Env Name ', config.ENV_NAME);
  const [isLoginComplete, setIsLoginComplete] = useState(false);

  useEffect(() => {
    if (ssoSessionId && !facadeSessionId) {
      (async () => {
        const result = await fetch(
          `${config.FACADE_BASE_PATH}/autologin/${ssoSessionId}`,
          {
            'Content-Type': 'application/json',
            method: 'GET',
          }
        );
        const data = await result.json();
        loginHandler(
          data.data.sso_session_id,
          data.data.facade_session_id,
          data.data.profile_name,
          data.data.photo_url
        );
        setIsLoginComplete(true);
      })();
    } else {
      setIsLoginComplete(true);
    }
  }, []);

  const userIsLoggedIn = !!facadeSessionId;

  const loginHandler = (
    sso_session_id,
    facad_session_id,
    profile_name,
    profile_img
  ) => {
    const cookieObj = {
      path: '/',
      maxAge: 3600, // Expires after 1hr
      sameSite: true,
      domain: config.COOKIE_DOMAIN,
      secure: true,
    };
    setCookie(
      envName + 'login_sso_sessid',
      JSON.stringify(sso_session_id),
      cookieObj
    );
    setCookie(
      envName + 'facad_session_id',
      JSON.stringify(facad_session_id),
      cookieObj
    );
    setCookie(
      envName + 'profile_name',
      JSON.stringify(profile_name),
      cookieObj
    );
    setCookie(envName + 'profile_img', JSON.stringify(profile_img), cookieObj);
    setSsoSessionId(sso_session_id);
    setFacadeSessionId(facad_session_id);
    setUserName(profile_name);
    setUserImage(profile_img);
    // console.log(envName + 'login_sso_sessid', ':', sso_session_id);
  };

  const setRegionAndLang = (region, lang) => {
    setRegion(region);
    setLang(lang);
  };

  const logoutHandler = () => {
    const cookieObj = {
      domain: config.COOKIE_DOMAIN,
      path: '/',
    };
    removeCookie(envName + 'login_sso_sessid', cookieObj);
    removeCookie(envName + 'facad_session_id', cookieObj);
    removeCookie(envName + 'profile_name', cookieObj);
    removeCookie(envName + 'profile_img', cookieObj);

    document.cookie =
      envName +
      'login_sso_sessid=; Path=/; domain=' +
      config.COOKIE_DOMAIN +
      ' Expires=Thu, 01 Jan 1970 00:00:01 GMT;';
    document.cookie =
      envName +
      'facad_session_id=; Path=/; domain=' +
      config.COOKIE_DOMAIN +
      ' Expires=Thu, 01 Jan 1970 00:00:01 GMT;';
    document.cookie =
      envName +
      'profile_name=; Path=/; domain==' +
      config.COOKIE_DOMAIN +
      ' Expires=Thu, 01 Jan 1970 00:00:01 GMT;';
    document.cookie =
      envName +
      'profile_img=; Path=/; domain=' +
      config.COOKIE_DOMAIN +
      ' Expires=Thu, 01 Jan 1970 00:00:01 GMT;';

    setSsoSessionId('');
    setFacadeSessionId('');
    setUserName('');
    setUserImage('');
  };

  const setRegionUKCookies = () => {
    setCookie('uk_cookie', 'yes', {
      path: '/',
      maxAge: 3600, // Expires after 1hr
      sameSite: true,
      secure: true,
    });
    setUKCookie('yes');
  };

  const setBottomWidgetCookie = isVisible => {
    setCookie('bottom_widget', isVisible, {
      path: '/',
      maxAge: 3600, // Expires after 1hr
      sameSite: true,
      secure: true,
    });
  };

  const getBottomWidgetCookie = () => {
    return cookie.bottom_widget;
  };

  const contextValue = {
    ssoSessionId: ssoSessionId,
    facadeSessionId: facadeSessionId,
    userName: userName,
    userImage: userImage,
    isLoginComplete,
    isLoggedIn: userIsLoggedIn,
    region: region,
    lang: lang,
    login: loginHandler,
    logout: logoutHandler,
    uk_cookie: ukCookie,
    RegionAndLang: setRegionAndLang,
    RegionUKCookies: setRegionUKCookies,
    getBottomWidgetCookie: getBottomWidgetCookie,
    setBottomWidgetCookie: setBottomWidgetCookie,
  };

  return (
    <AuthContext.Provider value={contextValue}>
      {props.children}
    </AuthContext.Provider>
  );
};

export default AuthContextProvider;
