/* Built In Imports */
import NextLink from 'next/link';

/* External Imports */
import {
    Box,
    Heading,
    Link,
    ListItem,
    Text,
    UnorderedList,
} from '@chakra-ui/react';

/* Internal Imports */
/* Components */
// import config from '@config';
import { refreshPage } from '@components/Utility/utils';
import { refineUrlDomain } from '@msr/components/Utility/utils';

/* Services */

/**
 * Renders the Footer External Link  component
 *
 * @param {string} hlink - url
 * @param {string} text text
 * @returns {ReactElement} Footer External Link Items component
 */
const FooterExternalLinkItem = ({ hlink, text }) => (
  <ListItem mb={{ xl: '20px', base: '17px' }} pl="0" lineHeight="1.21">
    <Text
      as="span"
      textDecor="none"
      paddingBottom="0.2vw"
      color="#C1C3DD"
      fontSize="16px"
      lineHeight="1.21"
      _hover={{
        color: '#cd6727',
        textDecoration: 'none',
        cursor: 'pointer',
        borderBottom: '1px solid #cd6727',
      }}
    >
      <NextLink href={hlink} passHref prefetch={false} legacyBehavior>
        <Link
          onClick={e => refreshPage(e?.target?.href, e)}
          _hover={{
            textDecoration: 'none',
          }}
        >
          {text}
        </Link>
      </NextLink>
    </Text>
  </ListItem>
);

export const FooterExternalLinks = ({ region, language, footerMenuData }) => (
  <Box
    width={{ base: '100%', lg: '35%', md: '100%' }}
    // marginTop={{
    //   lg: '3rem',
    // }}
    justifyContent="space-between"
    flexDir={{ base: 'row', lg: 'column', md: 'row' }}
    flexWrap="wrap"
  >
    <Box width={{ base: '100%', lg: '50%' }} float="left">
      <UnorderedList
        fontFamily="FedraSansStd-book,sans-serif"
        fontSize="0.85rem"
        color="#aea493"
        listStyleType="none"
        p="0 1.2rem 0 0"
        lineHeight="24px"
        marginInlineStart={{ base: '0' }}
      >
        {footerMenuData[0]?.submenuItems.length > 0 &&
          footerMenuData[0]?.submenuItems?.map((externallink, index) => {
            return (
              <FooterExternalLinkItem
                hlink={
                  externallink?.url?.startsWith('http')
                    ? `${externallink?.url}`
                    : refineUrlDomain(externallink?.url, '', language)
                }
                text={externallink?.title}
              />
            );
          })}
      </UnorderedList>
    </Box>
    <Box
      width={{ base: '100%', lg: '50%', md: '100%' }}
      float="left"
      marginBottom={{ base: '20px' }}
    >
      <Heading
        as="h4"
        fontSize={{ base: '20px', lg: '20px' }}
        fontFamily="FedraSansStd-A-medium"
        fontWeight="500"
        fontStyle="normal"
        color="#FFF7E9"
        mb={{ base: '0.7rem', lg: '1.7rem' }}
        mt={{ base: '1rem', lg: '0' }}
      >
        {footerMenuData[1]?.title}
      </Heading>
      <UnorderedList
        fontFamily="FedraSansStd-book,sans-serif"
        fontSize="16px"
        color="#C1C3DD"
        listStyleType="none"
        p="0 1.2rem 0 0"
        lineHeight="24px"
        marginInlineStart={{ base: '0' }}
      >
        {footerMenuData[1]?.submenuItems.length > 0 &&
          footerMenuData[1]?.submenuItems?.map((externallink, index) => {
            return (
              <>
                <FooterExternalLinkItem
                  hlink={
                    externallink?.url?.startsWith('http')
                      ? `${externallink?.url}`
                      : refineUrlDomain(externallink?.url, '', language)
                  }
                  text={externallink?.title}
                />
              </>
            );
          })}
      </UnorderedList>
    </Box>
  </Box>
);
