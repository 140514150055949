/* Built In Imports */
import dynamic from 'next/dynamic';
import { useRouter } from 'next/router';
import { useContext, useEffect, useRef, useState } from 'react';

/* External Imports */
import { Box } from '@chakra-ui/react';
import LoadingBar from 'react-top-loading-bar';

/* Internal Imports */
/* Components */
import { getCookie } from '@components/Utility/utils';
import Cart from '@lb/components/Cart';
import LBFooter from '@lb/components/Footer/LBFooter';
import LBHeader from '@lb/components/Header/LBHeader';
import { AuthContext } from '@store/auth-context';
// import WithPreview from '@lb/components/PreviewMode';
// import ScrollToTop from '@components/Utility/ScrollToTop';

const ScrollToTop = dynamic(() =>
  import('@components/Utility/ScrollToTop')
);

const WithPreview = dynamic(() =>
  import('@lb/components/PreviewMode')
);

/**
 * Renders the Layout component.
 *
 * @param {object} children - Child Elements.
 * @returns {ReactElement} Layout component
 */

const LbLayout = ({ children }) => {
  const {
    headerMenuData,
    topMenuData,
    pageData,
    footerData,
    footerDetailData,
    region,
    lang,
    isPreview,
    headerVisible = true,
    footerVisible = true,
    page
  } = children.props;
  let pageConfig = pageData?.pageConfig;

  const [showHeader] = useState(headerVisible);
  const [showFooter] = useState(footerVisible);
  const authContext = useContext(AuthContext);
  const router = useRouter();
  const { client } = router.query;
  const ref = useRef(null);
  // const url = router.asPath;
  // const path = url?.split('?')?.[0];
  // const isMobile = useMediaQuery({ maxWidth: 767 });
  const language = lang;
  const cartCookie = getCookie(
    region === 'np' ? 'numCartItemsS2' : 'numCartItemsS1'
  );

  useEffect(() => {
    authContext.RegionAndLang(region, language);
  }, [authContext, region, language]);

  // logic to have last and current url in session history starts
  useEffect(() => {
    let urls = sessionStorage.getItem('lb-url')?.split(',');
    if (!urls) {
      urls = [router.asPath];
      sessionStorage.setItem('lb-url', urls);
      return;
    }
    if (urls?.length) {
      urls[0] = urls[1];
      urls[1] = router.asPath;
    }
    sessionStorage.setItem('lb-url', urls);
  }, [client, router.asPath]);
  // logic to have last and current url in session history ends

  // show top loader starts
  useEffect(() => {
    router.events.on('routeChangeStart', () => ref?.current?.continuousStart());
    router.events.on('routeChangeError', () => ref?.current?.console?.error());
    router.events.on('routeChangeComplete', () => ref?.current?.complete());

    return () => {
      router.events.off('routeChangeComplete', () => ref?.current?.complete());
      router.events.off('routeChangeError', () => ref?.current?.console?.error());
    };
  }, [router.asPath]);
  // show top loader ends

  return (
    <>
      <LoadingBar color="#f11946" ref={ref} transitionTime={400} />
      <Box className={`lang-${language || 'en'}`} minH={{ base: '75px', md: '160px' }}>
        {/* header section */}
        {router.asPath !== `/linga-bhairavi/${region}/${language}` &&
          (showHeader || headerVisible) && headerMenuData != undefined &&
          pageConfig?.header != 'disable' ? (
          <Box pos="relative" w="100%">
            <LBHeader
              headerMenuData={headerMenuData}
              topMenuData={topMenuData}
              region={region}
              language={language != 'ne' ? language : 'en'}
              page={page}
              pageData={pageData}
            />
          </Box>
        ) : (
          ''
        )}
        {children}
        {router.asPath !== `/linga-bhairavi/${region}/${language}` &&
          (showFooter || footerVisible) &&
          (footerData || footerDetailData) && (
            <Box pos="relative" display="flex" justifyContent="center" w="100%">
              <LBFooter
                region={region}
                language={language != 'ne' ? language : 'en'}
                footerData={footerData}
                pageConfig={pageConfig}
                page={page}
              />
            </Box>
          )}
        <Cart region={region} lang={lang} pageConfig={pageConfig} />
        {isNaN(cartCookie) && <ScrollToTop />}
        {isPreview && <WithPreview isPreview={isPreview} />}
      </Box>
    </>
  );
};

export default LbLayout;
