/* Built In Imports */
import dynamic from 'next/dynamic';
import { useRouter } from 'next/router';
import { useContext, useEffect, useState } from 'react';

/* External Imports */
import { Box } from '@chakra-ui/react';
import Script from 'next/script';

/* Internal Imports */
/* Components */
import NewFooter from '@components/NewFooter';
import NewHeader from '@components/NewHeader';
import RegionSwitcher from '@components/UI/RegionSwitcher';
import BottomWidget from '@components/Utility/BottomWidget';
import { getCookie, isNonRegionalized } from '@components/Utility/utils';
import config from '@config';
import { AuthContext } from '@store/auth-context';

/* Services */
import {
  fetchIpDetectWidget,
  fetchPopUpBlacklistedUrls,
  fetchPopUpJson,
} from 'services/commonService';

const ScrollToTop = dynamic(() => import('@components/Utility/ScrollToTop'));
const WithPreview = dynamic(() => import('@components/PreviewMode'));

const NewsletterSubscriptionPopup = dynamic(() =>
  import('@components/RegisterModal')
);

const FreeYogaRegistration = dynamic(() =>
  import('@components/FYDRegisterPopup')
);

const UkCookie = dynamic(() => import('@components/Utility/UkCookie'));

/**
 * Renders the Layout component.
 *
 * @param {object} children - Child Elements.
 * @returns {ReactElement} Layout component
 */
export default function Layout({ children }) {
  // console.log("THE CHILDREN IS", children)
  const {
    headerTopMenuData,
    headerMenuData,
    pageData,
    footerData,
    footerDetailData,
    language,
    isPreview,
    headerVisible = true,
    page,
    secondMenuVisible = true,
    hideTopMenu = false,
  } = children.props;
  let pageConfig = '';
  if (pageData?.contentType === 'landing_page') {
    pageConfig = pageData?.pageConfig;
  } else if (pageData?.contentType === 'landing_page_child') {
    pageConfig = pageData?.pageConfig[0].value;
  } else if (pageData?.pageConfig != '') {
    pageConfig = pageData?.pageConfig;
  }
  const usScriptParam = { instance: 'iso' };

  const [currentUserRegion, setCurrentUserRegion] = useState(
    children.props?.region || 'in'
  );
  const [isClientApp, setIsClientApp] = useState(false);
  const [showHeader] = useState(headerVisible);

  const [region, setRegion] = useState(children.props?.region || 'in');
  const authContext = useContext(AuthContext);
  const [popUpData, setPopUpData] = useState('');
  const [popUpBlackListedJson, setPopUpBlackListedJson] = useState('');
  const [ipWidgetData, setIpWidgetData] = useState({});
  const [isScrollToTop, setIsScrollToTop] = useState(true);

  const router = useRouter();
  const { client } = router.query;
  const url = router.asPath;
  const path = url?.split('?')?.[0];

  useEffect(() => {
    authContext?.RegionAndLang(region, language);
    const currentRegion = config.locales.find(
      locale => locale?.region_sf === region
    );
    if (currentRegion) {
      setCurrentUserRegion(currentRegion?.region);
    }
  }, [authContext, region, language]);

  useEffect(() => {
    // setRegion(router.query.region?.toLowerCase());
    if (
      router.asPath.includes('client=sadhguru-app') ||
      client === 'sadhguru-app'
    ) {
      setIsClientApp(true);
    }
  }, [client, router.asPath]);

  // Scroll to top component hidden for US location WMSD-2168
  useEffect(() => {
    if (
      router.asPath.includes('/us/') ||
      router.asPath.includes('/inner-engineering')
    ) {
      setIsScrollToTop(false);
    }
  }, []);

  useEffect(() => {
    if (router.isReady) {
      const bottomWidgetCookie = getCookie('bottom_widget');

      if (isNonRegionalized(path, language)) {
        const data = fetchIpDetectWidget(url, router.query.country);
        data.then(res => {
          // console.log('aaaaaa(path)', region, res);
          setRegion(res?.region?.toLowerCase());
          // authContext?.RegionAndLang(res.region?.toLowerCase(), language);
          setPopUpData(res?.popup || res?.uspopup);
          setIpWidgetData(res);
        });
      } else if (bottomWidgetCookie !== 'false') {
        // If the bottom widget was not closed previously, then only fetch the data.
        const data = fetchPopUpJson(url);
        data.then(res => {
          const popupJson = fetchPopUpBlacklistedUrls(url);
          popupJson.then(resPopUp => {
            setPopUpBlackListedJson(resPopUp);
            setPopUpData(res);
          });
        });
      }
    }
  }, [router.isReady]);

  return (
    <Box className={`lang-${language}`}>
      {/* header section */}
      {pageData &&
        headerMenuData &&
        !isNonRegionalized(path, language) &&
        router.asPath.indexOf('/inner-engineering') < 0 && (
          <RegionSwitcher lang={language} region={region} />
        )}

      {!isClientApp &&
      showHeader &&
      (pageConfig?.header != 'disable' || page === 'Exclusive') ? (
        <Box pos="relative" float="left" w="100%">
          <NewHeader
            headerTopMenuData={headerTopMenuData}
            headerMenuData={headerMenuData}
            availableLocals={pageData?.availableLocales}
            currentUserRegion={currentUserRegion}
            setCurrentUserRegion={val => setCurrentUserRegion(val)}
            region={region}
            language={language}
            page={page}
            secondMenuVisible={secondMenuVisible}
            hideTopMenu={hideTopMenu}
            // ipWidgetData={ipWidgetData}
          />
        </Box>
      ) : (
        ''
      )}
      {/* main content to render */}
      {children}
      {/* Footer section */}
      {!isClientApp &&
        (footerData || footerDetailData) &&
        pageConfig?.pageName != 'iso-bs' && (
          <Box w="100%">
            <NewFooter
              region={region}
              language={language}
              footerData={footerData}
              pageConfig={pageConfig}
              page={page}
              ipWidgetData={ipWidgetData}
            />
          </Box>
        )}

      {/* Bottom widget */}
      {!isClientApp && (
        <BottomWidget
          region={region}
          lang={language}
          popUpData={popUpData}
          popUpBlackListedJson={popUpBlackListedJson}
          url={url}
        />
      )}
      {isPreview && <WithPreview isPreview={isPreview} />}
      {isScrollToTop && <ScrollToTop />}
      {/* IDY Popup */}
      {router.asPath.includes('#subscribepopup-idy2023') && (
        <NewsletterSubscriptionPopup
          sectionContent={pageData?.body}
          region={region}
          lang={language}
        />
      )}
      {router.asPath.includes('#freeyogaregistration') && (
        <FreeYogaRegistration
          // sectionContent={pageData?.body}
          region={region}
          lang={language}
        />
      )}
      <UkCookie />
      {region === 'us' && isNonRegionalized(path, language) && (
        <>
          <Box id="habitat-exit-popup-render">
            <Script type="text/props" id="USScript">
              {usScriptParam}
            </Script>
          </Box>
          <Script src={popUpData} />
        </>
      )}

      {url.indexOf('/wisdom/') > -1 && (
        <script
          dangerouslySetInnerHTML={{
            __html: `(function(d,u,ac){var s=d.createElement('script');s.type='text/javascript';s.src='https://a.omappapi.com/app/js/api.min.js';s.async=true;s.dataset.user=u;s.dataset.account=ac;d.getElementsByTagName('head')[0].appendChild(s);})(document,324804,'ygiw4hlfkhcipzclw05i');`,
          }}
        />
      )}
    </Box>
  );
}
