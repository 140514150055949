/* Built In Imports */
/* External Imports */
import { Box } from '@chakra-ui/react';

/* Internal Imports */
/* Components */
import LazyLoadImageComponent from '@components/UI/LazyLoadImageComponent';

/* Services */

/**
 * Renders the Small Listing Card Record Component
 * @param {Object} record - data
 * @returns {ReactElement} Small Listing Card Record component
 */
export default function SmallListingCardRecord(record) {
  return (
    <Box
      width={{ lg: '30%', md: '100%', sm: '100%' }}
      m={{
        lg: '2rem 7px 0 7px',
        base: '1.5rem auto 0 auto',
        md: '1.5rem auto 0 auto',
      }}
      display="inline-block"
      verticalAlign="top"
    >
      <LazyLoadImageComponent alt={record.image.alt} title={record.image.title} src={record.image.url} width="100%" />
      <Box
        h="20px"
        backgroundColor={'orange.50'}
        ml="10%"
        mr="10%"
        mt="-10px"
        style={{ backgroundColor: record.highlightColour.hex }}
      ></Box>
      <Box
        fontFamily="FedraSansStd-book"
        fontSize="1rem"
        lineHeight="1.5"
        textAlign="center"
        color={'black.300'}
        mt="20px"
        mb="15px"
        p="0"
      >
        {record.descriptionText}
      </Box>
    </Box>
  );
}
