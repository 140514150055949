/* Built In Imports */
import NextLink from 'next/link';
import { useEffect, useRef, useState } from 'react';

/* External Imports */
import { Box, Flex, Link, Text } from '@chakra-ui/react';

/* Internal Imports */
/* Components */
import LazyLoadImageComponent from '@components/UI/LazyLoadImageComponent';
import config from '@config';
import { refineUrlDomain } from '@msr/components/Utility/utils';

/* Services */

/**
 * Returns the Simple Button.
 *
 * @param style.style
 * @param {object} style - Style for button.
 * @param {string} bType - Button Type.
 * @param {string} region - Current User Country.
 * @param {string} language - Current User Language.
 * @param style.bType
 * @param style.region
 * @param style.lang
 * @param style.clickHandler
 * @param style.padding
 * @param style.textAlign
 * @param style.width
 * @param style.mb
 * @param style.mt
 * @param style.fontstyle
 * @param style.fontbold
 * @param style.disabled
 * @param style.textTransform
 * @returns {ReactElement} SimpleButton component.
 */
export default function SimpleButton({
  style,
  bType,
  region,
  lang,
  clickHandler,
  padding,
  textAlign,
  width,
  mb,
  mt,
  fontstyle,
  fontbold,
  disabled = false,
  textTransform,
  ...props
}) {
  const firstUpdate = useRef(true);
  // consoleLog('SimpleButton', style);
  const [currentUrl, setCurrentUrl] = useState(
    refineUrlDomain(style?.linkUrl, region, lang)
  );
  // consoleLog('called currentUrl', currentUrl);
  useEffect(() => {
    setTimeout(function () {
      if (firstUpdate.current) {
        firstUpdate.current = false;
      } else {
        setCurrentUrl(refineUrlDomain(style?.linkUrl, region, lang));
      }
      // console.log('currentUrl', currentUrl, style?.linkUrl);
    }, 100);
  }, [style?.linkUrl]);

  const RenderButton = ({ style }) => {
    // const [currentUrl, setCurrentUrl] = useState('');

    // useEffect(() => {
    //   setCurrentUrl(refineUrlDomain(linkUrl, region, lang));
    // }, [linkUrl]);

    const buttonStyle = {
      'MSR-Orange': (
        <NextLink href={disabled ? '#' : currentUrl} passHref legacyBehavior>
          <Link
            className="orange-button"
            m="auto"
            display="block"
            fontWeight={fontbold || '500'}
            fontFamily={fontstyle || 'FedraSansStd-medium'}
            fontSize={'16px'}
            bgColor="yellow.100"
            h={'auto'}
            minH="56px"
            // minW="200px"
            textAlign="center"
            padding={padding || '18px 18px'}
            borderRadius="5px"
            color="black !important"
            textDecoration="none"
            width={width || 'auto'}
            _hover={{
              bg: 'fulvous',
            }}
            // minW="192px"
            onClick={clickHandler}
            target={
              currentUrl?.indexOf('http') > -1 &&
              currentUrl?.indexOf(config.cdnPath) < 0
                ? '_blank'
                : ''
            }
            {...props}
          >
            {style?.buttonText}
          </Link>
        </NextLink>
      ),
      'MSR-Orange-LeftIcon': (
        <NextLink href={disabled ? '#' : currentUrl} passHref legacyBehavior>
          <Link
            className="orange-button"
            m="auto"
            display="block"
            fontWeight={fontbold || '500'}
            fontFamily={fontstyle || 'FedraSansStd-medium'}
            fontSize={'16px'}
            bgColor="yellow.100"
            h={'auto'}
            minH="50px"
            // minW="200px"
            textAlign="center"
            padding={padding || '18px 20px'}
            borderRadius="5px"
            color="black"
            textDecoration="none"
            width={width || 'auto'}
            _hover={{
              bg: 'fulvous',
            }}
            onClick={clickHandler}
            target={
              currentUrl?.indexOf('http') > -1 &&
              currentUrl?.indexOf(config.cdnPath) < 0
                ? '_blank'
                : ''
            }
            {...props}
          >
            <Flex justifyContent="center" alignItems="center">
              <LazyLoadImageComponent src={style?.imgPath} width={'30px'} />
              <Text pl={'10px'}>{style?.buttonText}</Text>
            </Flex>
          </Link>
        </NextLink>
      ),
      'MSR-Orange-RightIcon': (
        <NextLink href={disabled ? '#' : currentUrl} passHref legacyBehavior>
          <Link
            className="orange-button"
            m="auto"
            display="block"
            fontWeight={fontbold || '500'}
            fontFamily={fontstyle || 'FedraSansStd-medium'}
            fontSize={'16px'}
            bgColor="yellow.100"
            h={'auto'}
            minH="50px"
            // minW="200px"
            textAlign="center"
            padding={padding || '18px 20px'}
            borderRadius="5px"
            color="black"
            textDecoration="none"
            width={width || 'auto'}
            _hover={{
              bg: 'fulvous',
            }}
            onClick={clickHandler}
            target={
              currentUrl?.indexOf('http') > -1 &&
              currentUrl?.indexOf(config.cdnPath) < 0
                ? '_blank'
                : ''
            }
            {...props}
          >
            <Flex justifyContent="center" alignItems="center">
              <Text pr={'10px'}>{style?.buttonText}</Text>
              <LazyLoadImageComponent
                src={style?.imgPath}
                width={style?.imgWidth || '30px'}
                pt={style?.imgPT || '0'}
              />
            </Flex>
          </Link>
        </NextLink>
      ),
      'MSR-Orange-with-Arrow': (
        <NextLink href={disabled ? '#' : currentUrl} passHref legacyBehavior>
          <Link
            className="orange-button"
            m="auto"
            display="block"
            fontWeight={fontbold || '500'}
            fontFamily={fontstyle || 'FedraSansStd-medium'}
            fontSize={'16px'}
            bgColor="yellow.100"
            h={'auto'}
            minH="50px"
            // minW="200px"
            textAlign="center"
            padding={padding || '18px 20px'}
            borderRadius="5px"
            color="black"
            textDecoration="none"
            width={width || 'auto'}
            _hover={{
              bg: 'fulvous',
            }}
            onClick={clickHandler}
            target={
              currentUrl?.indexOf('http') > -1 &&
              currentUrl?.indexOf(config.cdnPath) < 0
                ? '_blank'
                : ''
            }
            {...props}
          >
            <Flex justifyContent="center" alignItems="center">
              <Text pr={'10px'}>{style?.buttonText}</Text>
              <LazyLoadImageComponent
                src="/img/right-arrow.png"
                width={style?.imgWidth || '14px'}
                pt={style?.imgPT || '0'}
              />
            </Flex>
          </Link>
        </NextLink>
      ),
      'MSR-Orange-Transparent': (
        <NextLink href={disabled ? '#' : currentUrl} passHref legacyBehavior>
          <Link
            className="orange-button"
            m="auto"
            display="block"
            fontWeight={fontbold || '500'}
            fontFamily={fontstyle || 'FedraSansStd-medium'}
            fontSize={'16px'}
            textAlign="center"
            padding={padding || '18px 20px'}
            borderRadius="5px"
            borderColor="yellow.100"
            borderWidth="1px"
            color="yellow.100"
            textDecoration="none"
            width={width || 'auto'}
            _hover={{
              borderColor: 'fulvous',
              color: 'fulvous',
            }}
            onClick={clickHandler}
            target={
              currentUrl?.indexOf('http') > -1 &&
              currentUrl?.indexOf(config.cdnPath) < 0
                ? '_blank'
                : ''
            }
            {...props}
          >
            {style?.buttonText}
          </Link>
        </NextLink>
      ),
      'MSR-Red': (
        <NextLink href={disabled ? '#' : currentUrl} passHref legacyBehavior>
          <Link
            // className="orange-button"
            m="auto"
            display="block"
            fontWeight={fontbold || '500'}
            fontFamily={fontstyle || 'FedraSansStd-medium'}
            fontSize={'16px'}
            bgColor="#CF4520"
            h={'auto'}
            minH="50px"
            textAlign="center"
            padding={padding || '16px 44px'}
            borderRadius="3px"
            color="white"
            textDecoration="none"
            width={width || 'auto'}
            _hover={{
              bg: 'fulvous',
            }}
            onClick={clickHandler}
            target={
              currentUrl?.indexOf('http') > -1 &&
              currentUrl?.indexOf(config.cdnPath) < 0
                ? '_blank'
                : ''
            }
            {...props}
          >
            {style?.buttonText}
          </Link>
        </NextLink>
      ),
      'MSR-LightRed': (
        <NextLink href={disabled ? '#' : currentUrl} passHref legacyBehavior>
          <Link
            m="auto"
            display="block"
            fontWeight={fontbold || '500'}
            fontFamily={fontstyle || 'FedraSansStd-medium'}
            fontSize={{base:'16px', lg:'20px'}}
            bgColor="#FFDCCA"
            h={'auto'}
            minH="50px"
            textAlign="center"
            padding={padding || '16px 44px'}
            borderRadius="3px"
            color="#3C0108"
            textDecoration="none"
            width={width || 'auto'}
            _hover={{
              bg: 'fulvous',
            }}
            onClick={clickHandler}
            target={
              currentUrl?.indexOf('http') > -1 &&
              currentUrl?.indexOf(config.cdnPath) < 0
                ? '_blank'
                : ''
            }
            {...props}
          >
            {style?.buttonText}
          </Link>
        </NextLink>
      ),
      'MSR-Orange-Outline': (
        <NextLink href={disabled ? '#' : currentUrl} passHref legacyBehavior>
          <Link
            // className="orange-button"
            m="auto"
            display="block"
            fontWeight={fontbold || '500'}
            fontFamily={fontstyle || 'FedraSansStd-medium'}
            fontSize={'16px'}
            bgColor="rgba(255, 255, 255, 0.25)"
            h={'auto'}
            border="2px solid #FFA735"
            minH="42px"
            textAlign="center"
            lineHeight="20px"
            padding={padding || '16px 22px'}
            borderRadius="5px"
            color="#FFA735"
            textDecoration="none"
            width={width || '96px'}
            _hover={{
              bg: 'fulvous',
              color: 'white',
            }}
            textTransform={textTransform}
            onClick={clickHandler}
            target={
              currentUrl?.indexOf('http') > -1 &&
              currentUrl?.indexOf(config.cdnPath) < 0
                ? '_blank'
                : ''
            }
            {...props}
          >
            {style?.buttonText}
          </Link>
        </NextLink>
      ),
      Orange: (
        <NextLink href={disabled ? '#' : currentUrl} passHref legacyBehavior>
          <Link
            className="orange-button"
            m="auto"
            mb={mb || '30px'}
            display="block"
            fontWeight={fontbold || '500'}
            fontFamily={fontstyle || 'FedraSansStd-medium'}
            fontSize={'18px'}
            bgColor={disabled ? 'rgb(207 69 32 / 41%)' : '#cf4520'}
            h={'auto'}
            minH="50px"
            cursor={disabled ? 'not-allowed' : 'pointer'}
            // minW="200px"
            textAlign="left"
            padding={padding || '14px 29px'}
            borderRadius="5px"
            color="#faf7f0"
            textDecoration="none"
            width={width || 'auto'}
            _hover={{
              bg: disabled ? 'rgb(207 69 32 / 41%)' : '#000054',
            }}
            onClick={clickHandler}
            target={
              currentUrl?.indexOf('http') > -1 &&
              currentUrl?.indexOf(config.cdnPath) < 0
                ? '_blank'
                : ''
            }
            {...props}
          >
            {style?.buttonText}
          </Link>
        </NextLink>
      ),
    };
    return buttonStyle[style?.buttonStyle] || null;
  };

  return currentUrl && currentUrl !== '#' ? (
    <Box
      boxSizing="borderBox"
      // display={bType ? 'flex' : 'grid'}
      placeItems={'left'}
      as={'button'}
      color="#faf7f0"
      width={'auto'}
      h="auto"
      minH="50px"
      borderRadius="5px"
      fontSize={'18px'}
      textAlign={textAlign || 'center'}
      justifyContent={bType ? 'center' : ''}
      cursor="default"
      m="0 auto"
      mb={mb || '30px'}
      mt={mt ? mt : '12px'}
    >
      <RenderButton style={style} />
    </Box>
  ) : null;
}
