/* Built In Imports */
import NextLink from 'next/link';
import { useRouter } from 'next/router';
import { useState } from 'react';

/* External Imports */
import {
    Accordion,
    AccordionButton,
    AccordionIcon,
    AccordionItem,
    AccordionPanel,
    Box,
    chakra,
    Image,
    Input,
    Link,
    Stack
} from '@chakra-ui/react';
import filter from 'lodash/filter';
import { nanoid } from 'nanoid';
import { LazyLoadImage } from 'react-lazy-load-image-component';
import { useMediaQuery } from 'react-responsive';

/* Internal Imports */
/* Components */
import StaticLooper from '@components/Layout/StaticLooper';
import config from '@config';
import { refineUrlDomain } from '@msr/components/Utility/utils';

/**
 *
 * @param {*} param0
 * @returns
 */
const MobileMenu = ({
  headerMenuData,
  languagelisting,
  languageDropdownHandler,
  mobileMenuHandler,
  isOpenMobMenu,
  mobileSearchHandler,
  isOpenMobSearch,
  isOpenCountry,
  region,
  language,
  isStaticPage,
}) => {
  // const [isOpenMobSubMenu, setIsOpenMobSubMenu] = useState(false);
  // const [isOpenMobThirdLevelMenu, setIsOpenMobThirdLevelMenu] = useState(false);
  // const [isOpenThirdLevelMenuText, setIsOpenThirdLevelMenuText] = useState('');
  const [isOpenMenuText, setIsOpenMenuText] = useState('');
  const router = useRouter();
  language = 'en';
  const isMobile = useMediaQuery({ maxWidth: 360 });

  // const generateUrl = itemurl => {
  //   let linkURL;
  //   if (itemurl.indexOf('https://') >= 0) {
  //     linkURL = itemurl;
  //   } else {
  //     linkURL = `/mahashivratri/${itemurl}`;
  //   }
  //   return linkURL;
  // };

  const menulistMobile = headerMenuData => {
    let menucontent = headerMenuData?.allIsoMainMenus;
    return <>
      <Box className="mobilemenu">
        <Accordion allowToggle cursor="default">
          {menucontent?.map((menuObj, indx) => {
            return <>
              {menuObj.key != 'language-switcher' &&
                menuObj.key != 'search' &&
                menuObj.key != 'msr-logo' &&
                menuObj.key != 'donate-to-annadanam' &&
                menuObj.key != 'register-for-rudraksha' && (
                  <AccordionItem
                    key={nanoid(4)}
                    borderBottomWidth="1px"
                    borderBottomColor={
                      indx === menucontent?.submenuItems?.length - 1
                        ? 'white'
                        : '#CEC3B0'
                    }
                    borderTop="none"
                    m="0 20px"
                    pl="16px"
                    lineHeight="1.61"
                    _expanded={{
                      background: '#fff3de',
                    }}
                  >
                    <AccordionButton
                      px="0"
                      pt="0"
                      pb="0"
                      fontFamily="FedraSansStd-book"
                      _expanded={{
                        fontWeight: 'bold',
                      }}
                      fontSize="16px"
                      borderTop="none"
                      _hover={{ background: 'none' }}
                      _active={{ background: 'none' }}
                      _focus={{ background: 'none' }}
                      cursor="default"
                    >
                      <NextLink
                        href={refineUrlDomain(menuObj?.url, '', language)}
                        scroll={false}
                        legacyBehavior>
                        <chakra.div
                          flex="1"
                          textAlign="left"
                          key={nanoid(4)}
                          color="#000000"
                          pt="16px"
                          pb="12px"
                          minHeight="58px"
                        >
                          {menuObj.title}
                        </chakra.div>
                      </NextLink>
                      {menuObj.submenuItems.length > 0 && (
                        <AccordionIcon
                          color="#000000"
                          width="60px"
                          height="58px"
                          px="15px"
                        />
                      )}
                    </AccordionButton>
                    {menuObj.submenuItems.length > 0 && (
                      <AccordionPanel paddingInlineStart="0" p="0 0 0 4px" 
                        key={nanoid(4)}
                      >
                        {/* {secondLevel(menuObj)} */}
                        {menuObj?.submenuItems?.map((menuObj, indx) => {
                          return <>
                            <AccordionItem
                              key={nanoid(4)}
                              borderBottom="none"
                              borderTop="none"
                              lineHeight="1.61"
                              paddingInlineStart="0px"
                            >
                              <AccordionButton
                                px="0"
                                fontFamily="FedraSansStd-book"
                                fontWeight={
                                  menuObj.submenuItems.length > 0
                                    ? 'bold'
                                    : 'normal'
                                }
                                fontSize="16px"
                                borderTop="none"
                                color={
                                  menuObj.submenuItems.length > 0
                                    ? '#988970'
                                    : '#000000'
                                }
                                pt="0"
                                pl={
                                  menuObj.submenuItems.length > 0
                                    ? '4px'
                                    : '18px'
                                }
                              >
                                <NextLink
                                  href={refineUrlDomain(
                                    menuObj?.url,
                                    '',
                                    language
                                  )}
                                  passHref
                                  scroll={false}
                                  legacyBehavior>
                                  <chakra.div
                                    flex="1"
                                    textAlign="left"
                                    key={nanoid(4)}
                                    py="2px"
                                  >
                                    <>
                                      {menuObj.title}
                                      {menuObj.subtext != '' && (
                                        <>
                                          <Box
                                            as="span"
                                            color="#424786"
                                            pl="4px"
                                            fontWeight="bold"
                                          >
                                            {menuObj?.subtext}
                                          </Box>
                                        </>
                                      )}
                                    </>
                                  </chakra.div>
                                </NextLink>
                              </AccordionButton>
                              {menuObj.submenuItems.length > 0 && (
                                <Box
                                  pb={2}
                                  fontSize="16px"
                                  fontFamily="FedraSansStd-book"
                                  lineHeight="1.61"
                                  px="0"
                                  pl="14px"
                                >
                                  {menuObj?.submenuItems?.map(
                                    (menuObj, indx) => {
                                      return <>
                                        <Box as="p" py="8px">
                                          <NextLink
                                            href={refineUrlDomain(
                                              menuObj?.url,
                                              '',
                                              language
                                            )}
                                            passHref
                                            scroll={false}
                                            legacyBehavior>
                                            {menuObj.title}
                                          </NextLink>
                                        </Box>
                                      </>;
                                    }
                                  )}
                                </Box>
                              )}
                            </AccordionItem>
                          </>;
                        })}
                      </AccordionPanel>
                    )}
                  </AccordionItem>
                )}
            </>;
          })}
        </Accordion>
      </Box>
    </>;
  };

  // const secondLevel = menudata => {
  //   return (
  //     <>
  //       <Accordion allowToggle cursor="default">
  //         {menudata?.submenuItems?.map((menuObj, indx) => {
  //           return (
  //             <>
  //               <AccordionItem
  //                 key={nanoid(4)}
  //                 borderBottom="none"
  //                 borderTop="none"
  //                 lineHeight="1.61"
  //                 pl="4px"
  //                 paddingInlineStart="0px"
  //               >
  //                 <AccordionButton
  //                   px="0"
  //                   fontFamily="FedraSansStd-book"
  //                   fontSize="16px"
  //                   borderTop="none"
  //                   color="#000000"
  //                   pt="0"
  //                   _expanded={{
  //                     fontWeight: 'bold',
  //                     color: '#988970',
  //                     pb: '0',
  //                   }}
  //                   _hover={{ background: 'none' }}
  //                   _active={{ background: 'none' }}
  //                   _focus={{ background: 'none' }}
  //                 >
  //                   <NextLink
  //                     href={
  //                       menuObj?.url.indexOf('https://') >= 0
  //                         ? `${menuObj?.url}`
  //                         : refineUrlDomain(
  //                             `/mahashivratri/${menuObj?.url}`,
  //                             region,
  //                             language
  //                           )
  //                     }
  //                     passHref
  //                     scroll={false}
  //                   >
  //                     <chakra.div
  //                       flex="1"
  //                       textAlign="left"
  //                       key={nanoid(4)}
  //                       py="2px"
  //                     >
  //                       <>
  //                         {menuObj.title}
  //                         {menuObj.subtext != '' && (
  //                           <>
  //                             <Box
  //                               as="span"
  //                               color="#424786"
  //                               pl="4px"
  //                               fontWeight="bold"
  //                             >
  //                               {menuObj?.subtext}
  //                             </Box>
  //                           </>
  //                         )}
  //                       </>
  //                     </chakra.div>
  //                   </NextLink>
  //                   {menuObj.submenuItems.length > 0 && (
  //                     <AccordionIcon
  //                       color="#000000"
  //                       width="60px"
  //                       height="36px"
  //                       px="15px"
  //                     />
  //                   )}
  //                 </AccordionButton>
  //                 {menuObj.submenuItems.length > 0 && (
  //                   <AccordionPanel
  //                     pb={2}
  //                     fontSize="16px"
  //                     fontFamily="FedraSansStd-book"
  //                     lineHeight="1.61"
  //                     px="0"
  //                     pl="14px"
  //                   >
  //                     {menuObj?.submenuItems?.map((menuObj, indx) => {
  //                       return (
  //                         <>
  //                           <Box as="p" py="8px">
  //                             <NextLink
  //                               href={
  //                                 menuObj?.url.indexOf('https://') >= 0
  //                                   ? `${menuObj?.url}`
  //                                   : refineUrlDomain(
  //                                       `/mahashivratri/${menuObj?.url}`,
  //                                       region,
  //                                       language
  //                                     )
  //                               }
  //                               passHref
  //                               scroll={false}
  //                             >
  //                               {menuObj.title}
  //                             </NextLink>
  //                           </Box>
  //                         </>
  //                       );
  //                     })}
  //                   </AccordionPanel>
  //                 )}
  //               </AccordionItem>
  //             </>
  //           );
  //         })}
  //       </Accordion>
  //     </>
  //   );
  // };

  const topNavigation = () => {
    return (
      <>
        <Box
          display="inline-flex"
          className="langListing"
          fontSize="14px"
          onClick={languageDropdownHandler}
        >
          {languagelisting()}
        </Box>
        <Box
          marginLeft="auto"
          display="inline-flex"
          width="18px"
          cursor="pointer"
          m={{ base: '4px 5px 0 17px' }}
          className="searchIcon"
        >
          <LazyLoadImage
            src={`${config.imgPath}/d/46272/1665455172-msr_search.svg`}
            float="left"
            width="100%"
            alt="search"
            onClick={mobileSearchHandler}
          />
        </Box>
        <Box
          position="absolute"
          width="100%"
          top="0"
          backgroundColor="#fff7e9"
          zIndex="99"
          transition="all 0.6s ease"
          overflow="auto"
          left={` ${isOpenMobSearch ? '0' : 'inherit'}`}
          display={` ${isOpenMobSearch ? 'block' : 'none'}`}
        >
          <form method="get" title="Search Form" action="/mahashivratri/search">
            <Stack display="block">
              <Input
                type="search"
                w="80%"
                height="70px"
                pl="22px"
                py="22px"
                placeholder="SEARCH"
                color="#333333"
                fontFamily="FedraSansStd-book"
                fontSize="18px"
                id="q"
                name="q"
                border="none"
                _focus={{ border: 'none' }}
                _placeholder={{
                  color: '#D4CBB9',
                }}
                display="inline-block"
              />
              <Box
                width="64px"
                height="20px"
                backgroundImage="url('/assets/images/mobile-menu-close.svg')"
                backgroundSize="20px"
                backgroundRepeat="no-repeat"
                backgroundPosition="50% 50%"
                cursor="pointer"
                onClick={mobileSearchHandler}
                className="closeIcon"
                display="inline-block"
              ></Box>
            </Stack>
          </form>
        </Box>
      </>
    );
  };

  const logoObj = filter(
    headerMenuData?.allIsoMainMenus,
    function (menu, index) {
      return menu?.key === 'msr-logo';
    }
  )[0];

  // const mobileSubMenuHandler = menu => {
  //   setIsOpenMenuText(menu);
  //   setIsOpenMobSubMenu(!isOpenMobSubMenu);
  // };
  // const mobileThirdLevelMenuHandler = menu => {
  //   setIsOpenThirdLevelMenuText(menu);
  //   setIsOpenMobThirdLevelMenu(!isOpenMobThirdLevelMenu);
  // };
  // const renderMobileMenu = () => {
  //   return headerMenuData?.allIsoMainMenus?.map(item => {
  //     const url =
  //       item?.url.indexOf('https://') >= 0
  //         ? item?.url
  //         : `/${region}/${item?.url}`;
  //     const subMenuItems = [];
  //     if (item.submenuItems && item.submenuItems.length > 0) {
  //       item.submenuItems.map(item => {
  //         let subURL;
  //         const modifiedURL =
  //           item?.url &&
  //           item?.url.replace('/wisdom-intermediate', '/wisdom/type');
  //         if (url.indexOf('https://') >= 0) {
  //           subURL = modifiedURL;
  //         } else {
  //           subURL = `/${region}/${
  //             modifiedURL.indexOf('/sadhguru') > -1
  //               ? router.query.language
  //               : language
  //           }${modifiedURL}`;
  //         }
  //         if (subURL.indexOf(`/${region}/${language}/`) < 0) {
  //           subURL = `/${region}/${
  //             modifiedURL.indexOf('/sadhguru') > -1
  //               ? router.query.language
  //               : language
  //           }${modifiedURL}`;
  //         }
  //         const subSubMenuItems = [];
  //         if (item.submenuItems && item.submenuItems.length > 0) {
  //           item.submenuItems.map(item => {
  //             const subSubURL =
  //               item?.url.indexOf('https://') >= 0
  //                 ? item?.url
  //                 : `/mahashivratri/${item?.url}`;
  //             subSubMenuItems.push(
  //               <MenuLI
  //                 text={item?.title}
  //                 subtext={item?.subtext}
  //                 url={subSubURL}
  //                 key={item?.title}
  //                 className={'third-level-mobile-menu'}
  //                 clicked={mobileMenuHandler}
  //               ></MenuLI>
  //             );
  //           });
  //         }
  //         const listItem =
  //           subSubMenuItems.length > 0 ? (
  //             <MenuLI
  //               text={item.title}
  //               subtext={item.subtext}
  //               url={generateUrl(item?.url)}
  //               key={item.title}
  //               className={`${
  //                 isOpenMobThirdLevelMenu &&
  //                 item.title == isOpenThirdLevelMenuText
  //                   ? 'active' //'active'
  //                   : ''
  //               } ${isOpenMobSubMenu ? 'bottom-mobile-menu' : ''} subsection`}
  //               clicked={mobileMenuHandler}
  //             >
  //               <UnorderedList
  //                 m="0"
  //                 className={
  //                   isOpenMobThirdLevelMenu &&
  //                   item.title === isOpenThirdLevelMenuText
  //                     ? null
  //                     : 'submenu-closed'
  //                 }
  //                 sx={{
  //                   a: {
  //                     padding: '0 16px',
  //                   },
  //                 }}
  //               >
  //                 {subSubMenuItems}
  //               </UnorderedList>
  //               <Text
  //                 as="span"
  //                 onClick={() => mobileThirdLevelMenuHandler(item.title)}
  //                 position="absolute"
  //                 width="60px"
  //                 height="42px"
  //                 backgroundImage={`${
  //                   isOpenMobThirdLevelMenu &&
  //                   item.title == isOpenThirdLevelMenuText
  //                     ? `url('${config.imgPath}/d/46272/1667076368-menu_arrow_up.svg')`
  //                     : `url('${config.imgPath}/d/46272/1667076365-menu_arrow_down.svg')`
  //                 }`}
  //                 backgroundSize="15px"
  //                 backgroundRepeat="no-repeat"
  //                 backgroundPosition="center"
  //                 cursor="pointer"
  //                 right="20px"
  //                 top="0"
  //                 transition="all 0.6s ease"
  //               ></Text>
  //             </MenuLI>
  //           ) : (
  //             <MenuLI
  //               text={item?.title}
  //               subtext={item?.subtext}
  //               url={generateUrl(item?.url)}
  //               key={item?.title}
  //               className={isOpenMobSubMenu ? 'sec-level-mobile-menu' : ''}
  //               clicked={mobileMenuHandler}
  //             />
  //           );
  //         subMenuItems.push(listItem);
  //       });
  //     }
  //     return (
  //       <>
  //         {item.key != 'language-switcher' &&
  //           item.key != 'search' &&
  //           item.key != 'donate-to-annadanam' &&
  //           item.key != 'register-for-rudraksha' && (
  //             <MenuLI
  //               text={item?.title}
  //               subtext={item?.subtext}
  //               url={generateUrl(item?.url)}
  //               key={item?.title}
  //               clicked={mobileMenuHandler}
  //               className={
  //                 isOpenMobSubMenu && item.title == isOpenMenuText
  //                   ? 'menuopen'
  //                   : 'closed'
  //               }
  //             >
  //               <UnorderedList
  //                 m="0"
  //                 className={
  //                   isOpenMobSubMenu && item.title == isOpenMenuText
  //                     ? null
  //                     : 'submenu-closed'
  //                 }
  //               >
  //                 {subMenuItems}
  //               </UnorderedList>
  //               {!isStaticPage && subMenuItems.length > 0 && (
  //                 <Text
  //                   as="span"
  //                   onClick={() => {
  //                     mobileSubMenuHandler(item.title);
  //                   }}
  //                   position="absolute"
  //                   width="60px"
  //                   height="58px"
  //                   backgroundImage={`${
  //                     isOpenMobSubMenu && item.title == isOpenMenuText
  //                       ? `url('${config.imgPath}/d/46272/1667076368-menu_arrow_up.svg')`
  //                       : `url('${config.imgPath}/d/46272/1667076365-menu_arrow_down.svg')`
  //                   }`}
  //                   backgroundSize="15px"
  //                   backgroundRepeat="no-repeat"
  //                   backgroundPosition="center"
  //                   cursor="pointer"
  //                   right="20px"
  //                   top="0"
  //                   transition="all 0.6s ease"
  //                 ></Text>
  //               )}
  //             </MenuLI>
  //           )}
  //       </>
  //     );
  //   });
  // };

  return (
    <Box
      display={{ base: 'block', xl: 'none' }}
      float="left"
      width="100%"
      height={{ base: '69px', sm: '80px', md: '69px', lg: '76px', xl: '69px' }}
      p={{
        base: '0 15px 0 15px',
        sm: '0 15px 0 15px',
        md: '0 30px 0 30px',
        lg: '0 15px 0 15px',
      }}
      backgroundColor="#111111"
    >
      <NextLink
        href={refineUrlDomain(logoObj?.url, '', language)}
        passHref
        scroll={false}
        legacyBehavior>
        <Link
          float="left"
          width={{ base: '102px', sm: '160px' }}
          mt={{ base: '26px', sm: '26px', md: '22px', xl: '13px' }}
          ml={{ base: 'unset', md: '15px', lg: '0' }}
        >
          <Image
            src={logoObj?.icon?.url}
            float="left"
            width={{ md: '152px', base: '102px' }}
            height={{ md: '24px', base: '16px' }}
            alt="logo"
          />
        </Link>
      </NextLink>
      <Box
        float="right"
        width="20px"
        cursor="pointer"
        m="23px 2px 0 0"
        fontSize="18px"
        fontWeight="200"
        verticalAlign="middle"
        onClick={mobileMenuHandler}
        ml="20px"
      >
        <StaticLooper loopCount={3}>
          <Box
            as="span"
            display="block"
            width="19px"
            height="2px"
            borderRadius="6px"
            backgroundColor="#ffffff"
            m="4px 0"
          ></Box>
        </StaticLooper>
      </Box>

      <Box
        float="right"
        borderLeft="0"
        mt="16px"
        display="flex"
        alignItems="center"
      >
        {topNavigation()}
      </Box>

      <Box
        position="fixed"
        width="100%"
        height="100vh"
        top="0"
        backgroundColor="#FFF7E9"
        zIndex="99"
        transition="all 0.6s ease"
        overflow="auto"
        left={` ${isOpenMobMenu ? '0' : 'inherit'}`}
        display={` ${isOpenMobMenu ? 'block' : 'none'}`}
      >
        <Box float="left" width="100%" height="70px" backgroundColor="#111111">
          <Link
            src={logoObj?.icon?.url}
            float="left"
            width={{ base: '102px', sm: '160px', md: '47px', xl: '45px' }}
            mt={{ base: '20px', sm: '20px', md: '12px', xl: '13px' }}
            ml={{ base: '15px', md: '15px', lg: '0' }}
          >
            <LazyLoadImage
              src={logoObj?.icon?.url}
              float="left"
              width="100%"
              alt="logo"
            />
          </Link>
          <Box
            float="right"
            width="55px"
            height="70px"
            backgroundColor="rgba(0, 0, 0, 0.8)"
            backgroundImage="url('/assets/images/mobile-menu-close.svg')"
            backgroundSize="20px"
            backgroundRepeat="no-repeat"
            backgroundPosition="center"
            cursor="pointer"
            onClick={mobileMenuHandler}
          ></Box>
          <Box
            float="right"
            display="inline-flex"
            mt="16px"
            mr="2px"
            alignItems="center"
            className="expandedMenu"
          >
            {topNavigation()}
          </Box>
        </Box>
        <Box width="100%" backgroundColor="#FFF7e9">
          {menulistMobile(headerMenuData)}
        </Box>
      </Box>
      {/* prettier-ignore */}
      <style jsx global>
        {`
        .bottom-mobile-menu {
          font-family: FedraSansStd-book, sans-serif !important;
          border-bottom: 1px solid transparent !important;
          margin-left: 24px !important;
          font-weight: bold;
          color: #988970 !important;
          height: 45px !important;
          padding-top: 5px !important;
        }
        .langListing div {
          border-left: none;
          left: -8px;
        }
        .langListing {
          margin-right: 0;
        }
        .languageList li {
          padding: 0;
        }
        .languageList li:last-child {
          margin-bottom: 10px;
        }
        .mobilemenu {
          -webkit-tap-highlight-color: transparent;
          cursor: default;
        }        
      `}</style>
    </Box>
  );
};

export default MobileMenu;
