/* Built In Imports */
/* External Imports */
import { Box, Image, Link } from '@chakra-ui/react';

/* Internal Imports */
/* Components */
import { getCookie } from '@components/Utility/utils';
import config from '@config';

/* Services */

/**
 * Renders Cart Component
 *
 * @param region
 * @param lang
 * @returns {ReactElement} - Scroll To Top Component
 */

const Cart = ({  region, pageConfig }) => {
  const cartCookie = getCookie(
    region === 'np' ? 'numCartItemsS2' : 'numCartItemsS1'
  );

  if (
    pageConfig?.hasOwnProperty('breadcrumb_hide') &&
    !pageConfig?.breadcrumb_hide
  ) {
    return <></>;
  }

  if (!isNaN(cartCookie) && cartCookie > 0) {
    return (
      <Box
        position="fixed"
        w="54px"
        h="54px"
        right="50px"
        bottom="40px"
        background="none"
        rounded="full"
        zIndex="9999"
        // bgColor="red"
      >
        <Box position="fixed"
          w="54px"
          h="54px"
          right="50px"
          bottom="40px"
          background="#EBE9DB"
          rounded="full"
          display="flex"
          alignItems="center"
          justifyContent="center"
        >
          <Link
            href={'https://arpanam.sadhguru.org/cart'}
            target={'_blank'}
            p="0"
            border="none"
            margin="0"
          >
            <Image
              w="22px"
              height="22px"
              src={`${config.imgPath}/d/46272/1682709741-vector-1.svg`}
              alt="cart"
              objectFit="cover"
            />
            {/* {cartCookie && ( */}
            <Box
              as="span"
              bgColor="#DB3030"
              color="white"
              fontSize="10px"
              fontFamily="FedraSansStd-book"
              position="absolute"
              left="28px"
              top="-6px"
              borderRadius="50%"
              padding="0 5px"
              fontWeight="500"
            >
              {cartCookie}
            </Box>
          </Link>
        </Box>
      </Box>
    )
  } 
  return <></>;
};

export default Cart;
