import { Box, Link, ListItem } from '@chakra-ui/react';
import { refreshPage } from '@components/Utility/utils';
import NextLink from 'next/link';

const LILayout = props => {
  let { url, text, subtext, height } = props;
  // shouldRefresh(url) &&
  //   (url = url.replace(`${config.BASE_PATH}`, 'https://isha.sadhguru.org'));

  return (
    <ListItem pos="relative" height={height}>
      <NextLink href={url} passHref legacyBehavior>
        <Link
          className={props.className}
          fontFamily="'FedraSansStd-book', sans-serif"
          fontSize="12px"
          color="#FFF7E9"
          onClick={e => refreshPage(e?.target?.href)}
          _focus={{ boxShadow: 'none' }}
        >
          {text}
          {subtext != '' && (
            <>
              <Box as="span" color="#424786" pl="4px" fontWeight="bold">
                {subtext}
              </Box>
            </>
          )}
        </Link>
      </NextLink>
      {props.children}
    </ListItem>
  );
};

export default LILayout;
