/* Built In Imports */
import dynamic from 'next/dynamic';

/* External Imports */
import { Heading } from '@chakra-ui/react';
import { render as toPlainText } from 'datocms-structured-text-to-plain-text';
import {
  isBlockquote,
  isHeading,
  isListItem,
  isParagraph,
  renderRule,
} from 'datocms-structured-text-utils';
import { StructuredText } from 'react-datocms';

/* Internal Imports */
/* Components */
import SimpleButton from '@components/Buttons/SimpleButton';
import upcomingDates from '@components/UI/UpcomingDates';
import SingleImageBlocker from './SingleImageBlocker';
import SmallListingCardRecord from './SmallListingCardRecord';

/* Services */

/**
 * Renders Structured Text Content Component
 *
 * @param {object} mainContent - data
 * @param region
 * @param language
 * @returns {ReactElement} Structured Text Content component
 */
export default function StructuredTextContent(mainContent, region, language) {
  // const [show, setShow] = useState(false);
  // const handleToggle = () => setShow(!show);
  const contentValue = mainContent;

  // consoleLog('data passed to StructuredText=> ', contentValue);
  // return <></>;
  return (
    <StructuredText
      data={contentValue}
      customRules={[
        renderRule(isHeading, ({ node, children, key }) => {
          const HeadingTag = `h${node.level}`;
          const anchor = toPlainText(node)
            ?.toLowerCase()
            .replace(/ /g, '-')
            .replace(/[^\w-]+/g, '');
          return (
            <HeadingTag key={key}>
              {children} <a id={anchor} />
              <a href={`#${anchor}`} />
            </HeadingTag>
          );
        }),
        renderRule(
          isParagraph,
          ({ adapter: { renderNode }, key, children }) => {
            return renderNode('p', { key }, children);
          }
        ),
        // renderRule(
        //   isList,
        //   ({ adapter: { renderNode }, node, key, children }) => {
        //     return (
        //       <Collapse startingHeight={150} in={show} key={key} pb="30x">
        //         <UnorderedList key={key} pl="10px" pb="30x">
        //           {children}
        //         </UnorderedList>
        //       </Collapse>
        //     );
        //   }
        // ),
        renderRule(isListItem, ({ adapter: { renderNode }, key, children }) => {
          return renderNode('li', { key }, children);
        }),
        renderRule(
          isBlockquote,
          ({ adapter: { renderNode }, key, node, children }) => {
            const childrenWithAttribution = node.attribution
              ? [
                  ...(children || []),
                  renderNode('span', { key: 'span' }, node.attribution),
                ]
              : children;
            return renderNode('blockquote', { key }, childrenWithAttribution);
          }
        ),
      ]}
      renderBlock={({ record }) => {
        // consoleLog('renderBlock rendering=>', record);
        switch (record.__typename) {
          case 'SingleImageBlockRecord':
            return SingleImageBlocker(record);
          case 'SmallListingCardRecord':
            return SmallListingCardRecord(record);
          case 'SimpleButtonRecord':
            return (
              <SimpleButton style={record} region={region} lang={language} />
            );
          // case 'SimpleButtonRecord':
          //   switch (record.buttonStyle) {
          //     case 'Orange':
          //       return (
          //         <Box textAlign="center" mt="2rem">
          //           <Button variant="solid" minW="216px" h="2.85rem">
          //             <a href={record.linkUrl}>{record.buttonText}</a>
          //           </Button>
          //         </Box>
          //       );
          //     case 'White with Orange outline':
          //       return (
          //         <Box textAlign="center">
          //           <Button variant="ghost" onClick={handleToggle} mt="1rem">
          //             {' '}
          //             Read {show ? 'Less' : 'More'}
          //           </Button>
          //         </Box>
          //       );
          //     default:
          //       return null;
          //   }
          case 'SectionTitleRecord':
            return (
              <Heading
                as="h2"
                fontFamily="FedraSansStd-A-medium"
                fontSize="1.7rem"
                fontWeight="normal"
                color="#02026c"
                textAlign="center"
                bgRepeat="no-repeat"
                backgroundPosition="bottom"
                mt="3rem"
                mb="1.5rem"
                pb="1.5rem"
              >
                {record.titleText}
              </Heading>
            );
          case 'GoogleMapRecord':
            const GoogleMap = dynamic(() =>
              import('@components/Embeds/GoogleMap')
            );
            return <GoogleMap sectionContent={record} />;
          case 'TableRowContentRecord':
            return upcomingDates(record);
          default:
            return null;
        }
      }}
    />
  );
}
