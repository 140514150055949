/* Built In Imports */
import { useEffect, useState } from 'react';

/* External Imports */
import { Box } from '@chakra-ui/react';
import LiteYouTubeEmbed from '@components/Embeds/YoutubeEmbed';
import YouTube from 'react-youtube';

/* Internal Imports */
import MidContainer from '@Layout/MidContainer';
import { getEmbedUrl } from '@components/Utility/utils';

/* Services */
/* Styles */
import 'react-lite-youtube-embed/dist/LiteYouTubeEmbed.css';

/**
 * @param {object} sectionContent - Data for Section
 * @param sectionContent.margin
 * @param sectionContent.width
 * @param sectionContent.pxBase
 * @returns {ReactElement} MediaEmbed component
 */
const MediaEmbed = ({ sectionContent, margin, width, pxBase, ...props }) => {
  const [vidVis, setVidVis] = useState(false);
  const [opts, setOpts] = useState({});

  useEffect(() => {
    setOpts({
      height: document.getElementById('msr-vid')?.clientHeight || '315',
      width: '100%',
      playerVars: {
        autoplay: 1,
      },
    });
  }, [global?.window]);

  const onReady = event => {
    event.target.playVideo();
  };

  if (sectionContent?.image?.url) {
    return (
      <MidContainer w={width || '755px'}>
        <Box
          backgroundImage={{
            base: sectionContent?.mobileImage?.url || sectionContent?.image?.url,
            md: sectionContent?.image?.url
          }}
          backgroundSize="cover"
          backgroundColor="#000"
          backgroundRepeat="no-repeat"
          backgroundPosition="center"
          width="100%"
          position="relative"
          display="flex"
          justifyContent="center"
          alignItems="center"
          flexDir="column"
          cursor="pointer"
          onClick={e => setVidVis(!vidVis)}
          overflow="hidden"
          height={{ base: '193px', sm: '300px', md: '430px' }}
          m={margin || '0 auto 30px auto'}
          id="msr-vid"
        >
          {' '}
          {vidVis && (
            <Box maxW="100%" w="full">
              <YouTube
                videoId={getEmbedUrl(sectionContent?.embedUrl)}
                onReady={onReady}
                opts={opts}
                loading="lazy"
              />{' '}
            </Box>
          )}
        </Box>
      </MidContainer>
    );
  }

  return (
    <MidContainer w={width || '755px'}>
      <Box
        position="relative"
        display="block"
        width={width || ''}
        pb={sectionContent?.source === 'soundcloud' ? '17%' : '56.25%'}
        overflow="hidden"
        height="0"
        m={margin || '30px auto'}
        px={{ base: pxBase || '15px', md: 0 }}
        {...props}
      >
        {sectionContent?.source === 'youtube' ||
          sectionContent?.embedUrl?.indexOf('youtube.com') > -1 ? (
          <LiteYouTubeEmbed
            id={getEmbedUrl(sectionContent?.embedUrl)}
            embedUrl={getEmbedUrl(sectionContent?.embedUrl)}
            cookie
          />
        ) : (
          <Box
            as="iframe"
            width="100%"
            allowFullScreen
            style={{
              position: 'absolute',
              top: 0,
              left: 0,
              width: '100%',
              height: '100%',
            }}
            src={getEmbedUrl(sectionContent?.embedUrl)}
          />
        )}
      </Box>
    </MidContainer>
  );
};

export default MediaEmbed;
