export const ButtonStyles = {
  baseStyle: {
    fontWeight: 'bold',
    textTransform: 'normal',
    borderRadius: 'base',
  },
  sizes: {
    sm: {
      fontSize: 'sm',
      px: 6,
      py: 5,
    },
    md: {
      fontSize: 'md',
      px: 6,
      py: 5,
    },
  },
  variants: {
    solid: props => ({
      bg: '#cf4520',
      color: 'white',
      border: '1px solid',
      _hover: {
        bg: '#000054',
        borderColor: '#fff',
        border: '1px solid',
        color: '#fff',
      },
      _active: {
        bg: '#000422',
        borderColor: '#fff',
        border: '1px solid',
        color: '#fff',
      },
    }),
    ghost: props => ({
      bg: 'transparent',
      color: '#cf4520',
      borderColor: '#cf4520',
      border: '1px solid',
      _hover: {
        bg: '#cf4520',
        // border: '#cf4520',
        color: '#ffffff',
        border: '1px solid',
      },
      _active: {
        bg: '#892a16',
        borderColor: '#cf4520',
        border: '1px solid',
        color: '#fff',
      },
    }),
  },
  defaultProps: {},
};
