/* Built In Imports */
/* External Imports */
import { Box } from '@chakra-ui/react';
import { render } from 'datocms-structured-text-to-html-string';

/* Internal Imports */
/* Components */
import StructuredTextParser from '@msr/Utility/StructuredTextParser';

/* Services */

/**
 * Renders the Poem Component
 *
 * @param {object} sectionContent - data
 * @param sectionContent.content
 * @param sectionContent.lang
 * @returns {ReactElement} Poem component
 */
const Poem = ({ content, lang }) => {
  return (
    <Box className="pb45Space">
      <StructuredTextParser
        str={render(content.body)}
        region={''}
        lang={lang}
        textAlign="center"
      ></StructuredTextParser>
      <style>
        {`
          .pb45Space p {
            padding-bottom: 30px;
            font-size: 19px;
          }
          @media (max-width: 767px) {
            .pb45Space p {
              font-size: 16px;
              padding-bottom: 20px;
            }
          }
        `}
      </style>
    </Box>
  );
};

export default Poem;
