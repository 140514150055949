/* Built In Imports */

/* External Imports */
import { Box, Heading, Link, Text } from '@chakra-ui/react';

/* Internal Imports */

/* Services */

/**
 * Renders the Footer Social Link Items component
 *
 * @param {string} link - URL of the Social Media Item.
 * @param {string} img1 - Image for Desktop View.
 * @param {string} img2 - Image for Mobile View.
 * @returns {ReactElement} Footer Social Link Items component
 */
const FooterSocialLinkItem = ({ link, img1, img2, title }) => {
  let imgwidth = '';
  if (title == 'Incredible India') {
    imgwidth = '165px';
  }
  return (
    <Link
      href={link}
      width={imgwidth != '' ? imgwidth : '40px'}
      height="40px"
      mb={{ base: '45px', lg: '40px' }}
      float="left"
      marginRight="20px"
      textDecoration="none"
      outline="2px solid rgb(0 0 0 / 0%)"
      outlineOffset="2px"
      color="inherit"
      _last={{ mb: { base: '10px', lg: '40px' } }}
    >
      <Text
        as="span"
        backgroundImage={img1}
        backgroundRepeat="no-repeat"
        backgroundSize="auto"
        width={imgwidth != '' ? imgwidth : '40px'}
        height="40px"
        cursor="pointer"
        p={{ base: '0', lg: '0 10px' }}
        display="inline-flex"
        justifyContent="space-between"
        _hover={{
          backgroundImage: img2,
          backgroundRepeat: 'no-repeat',
          backgroundSize: 'contain',
        }}
      ></Text>
    </Link>
  );
};

export const FooterSocialMediaLinks = ({
  region,
  language,
  footerMenuData,
}) => (
  <Box
    width="100%"
    mb={{
      base: '1rem',
      md: '1rem',
    }}
  >
    <Heading
      as="h4"
      fontSize="20px"
      fontFamily="FedraSansStd-A-medium"
      fontWeight="500"
      fontStyle="normal"
      color="#FFF7E9"
      mb={{
        base: '1rem',
        lg: '1.7rem',
      }}
    >
      {footerMenuData[4]?.title}
    </Heading>
    <Box width={{ xl: '100%', sm: '100%' }} marginBottom="4rem">
      {footerMenuData[4]?.submenuItems.length > 0 &&
        footerMenuData[4]?.submenuItems?.map((socialmedia, index) => {
          return (
            <FooterSocialLinkItem
              link={socialmedia?.url}
              img1={socialmedia?.icon?.url}
              img2={socialmedia?.iconHover?.url}
              title={socialmedia?.title}
            />
          );
        })}
    </Box>
  </Box>
);
