/* Built In Imports */
import { useState } from 'react';

/* External Imports */
import { Box, Heading } from '@chakra-ui/react';
import { render } from 'datocms-structured-text-to-html-string';
import { render as toPlainText } from 'datocms-structured-text-to-plain-text';
import {
    isBlockquote, isHeading, isListItem,
    isParagraph, renderRule
} from 'datocms-structured-text-utils';
import { StructuredText } from 'react-datocms';

/* Internal Imports */
/* Components */
import SingleImage from '@components/Card/SingleImage';
import StructuredTextParser from '@msr/Utility/StructuredTextParser';
import SimpleButton from '@msr/components/Buttons/SimpleButton';
import MediaEmbed from '@msr/components/Embeds/MediaEmbed';
import ImageWithTextSliderGroup from '@msr/components/Sliders/ImageWithTextSliderGroup';
import Quotes from '@msr/components/Card/Quotes';
import BlogTableOfContentIndex from '@msr/components/Tables/BlogTableOfContentIndex';
import BlogTableOfContentsSection from '@msr/components/Tables/BlogTableOfContentsSection';
import MsrBlogImageAtStart from '@msr/components/Tables/MsrBlogImageAtStart';
import SadhguruSignatureLoveGrace from '@msr/components/Tables/SadhguruSignatureLoveGrace';
import JsonTableUI from '@msr/components/Tables/JsonTableUI';
import Poem from '@msr/components/UI/Poem';
import BlogPoem from '@msr/components/Card/MsrBlogPoem';
import SingleImageBlocker from './SingleImageBlocker';
import SmallListingCardRecord from './SmallListingCardRecord';

/* Services */

/**
 * Renders Structured Text Content Component
 * @param {Object} mainContent - data 
 * @returns {ReactElement} Structured Text Content component
 */
export default function StructuredTextContent(mainContent, region, language, pType) {
  const [show, setShow] = useState(false);
  const handleToggle = () => setShow(!show);
  const contentValue = mainContent;
  
  return (
    <StructuredText
      data={contentValue}
      customRules={[
        renderRule(isHeading, ({ node, children, key }) => {
          const HeadingTag = `h${node.level}`;
          const anchor = toPlainText(node)?
            .toLowerCase()
            .replace(/ /g, '-')
            .replace(/[^\w-]+/g, '');
          return (
            <HeadingTag key={key}>
              {children} <a id={anchor} />
              <a href={`#${anchor}`} />
            </HeadingTag>
          );
        }),
        renderRule(
          isParagraph,
          ({ adapter: { renderNode }, key, children }) => {
            return renderNode ? renderNode('p', { key }, children) : null;
          }
        ),
        // renderRule(
        //   isList,
        //   ({ adapter: { renderNode }, node, key, children }) => {
        //     return (
        //       <Collapse startingHeight={150} in={show} key={key} pb="30x">
        //         <UnorderedList key={key} pl="10px" pb="30x">
        //           {children}
        //         </UnorderedList>
        //       </Collapse>
        //     );
        //   }
        // ),
        renderRule(isListItem, ({ adapter: { renderNode }, key, children }) => {
          return renderNode ? renderNode('li', { key }, children) : null;
        }),
        renderRule(
          isBlockquote,
          ({ adapter: { renderNode }, key, node, children }) => {
            if (node.attribution != '') {
              return (
                <Box
                  w="100%"
                  maxW="810px"
                  m={{ base: '30px auto', md: '60px auto' }}
                >
                  <Quotes
                    sectionContent={{ quote: render(node) }}
                    region={region}
                    lang={language}
                  />
                </Box>
              );
            } else if (pType === 'article') {
              return (
                <Quote
                  sectionContent={{ quote: render(node) }}
                  region={region}
                  lang={language}
                />
              );
            } else {
              const childrenWithAttribution = node.attribution
                ? [
                    ...(children || []),
                    renderNode ? renderNode('span', { key: 'span' }, node.attribution) : null,
                  ]
                : children;
              return renderNode ? renderNode('blockquote', { key }, childrenWithAttribution) : null;
            }
          }
        ),
      ]}
      renderBlock={({ record }) => {
        // consoleLog('record', record);
        switch (record._modelApiKey) {
          case 'image_with_text_slider_group':
            // consoleLog('record._modelApiKey', record._modelApiKey);
            return <ImageWithTextSliderGroup sectionContent={record} lang={language} structure={true} />;
        }
        switch (record.__typename) {          
          case 'SingleImageBlockRecord':
            return SingleImageBlocker(record);
          case 'SmallListingCardRecord':
            return SmallListingCardRecord(record);
          case 'SingleImageRecord':
            return (
              <SingleImage
                sectionContent={record}
                region={region}
                lang={language}
              />
            );      
          case 'SimpleButtonRecord':
            return (
              <SimpleButton
                style={record}
                region={region}
                lang={language}
              />
            );
          case 'JsonTableUiRecord':
            return (
              <Box my={10}>
                <JsonTableUI
                  sectionContent={record}
                  region={region}
                  lang={language}
                />
              </Box>
            );
          case 'MediaEmbedRecord':
            return (
              <Box my={5} w={{base:'100%', md:'595px'}}>
                <MediaEmbed
                  sectionContent={record}
                  // width={'595px'}
                />
              </Box>
            );          
          case 'StructuredTextRecord':
            if (record.style === 'lb-blog-image-at-start') {
              return (
                <Box>
                  <MsrBlogImageAtStart
                    sectionContent={record}
                    record={record}
                    region={region}
                    lang={language}
                  />
                </Box>
              );
            } else if (record?.style === 'poem') {
              return (
                <Poem
                  content={record}
                  lang={language}
                />
              );
            } else {
              return (
                <Box
                  sx={{
                    h2: {
                      fontSize: '26px',
                    },
                    h3: {
                      fontSize: '18px',
                    },
                  }}
                  px={{ base: '15px', md: '0' }}
                  pt={0}
                  pb={{ base: '20px', md: '20px' }}
                  w="100%"
                  maxW="755px"
                  m="0 auto"
                >
                  <StructuredTextParser
                    str={render(record.body)}
                    region={region}
                    lang={language}
                    textAlign={record?.style}
                    sectionContent={record}
                    fieldId={record.fieldId}
                  />
                </Box>
              );
            }
          case 'BlogTableOfContentIndexRecord':
            return (
              <BlogTableOfContentIndex
                sectionContent={record}
                region={region}
                lang={language}
              />
            );
          case 'BlogTableOfContentsSectionRecord':
            return (
              <BlogTableOfContentsSection
                sectionContent={record}
                region={region}
                lang={language}
              />
            );
          case 'SadhguruSignatureLoveGraceRecord':
            return (
              <Box w="100%" maxW="667px" m="0 auto">
                <SadhguruSignatureLoveGrace
                  card={record}
                  region={region}
                  lang={language}
                />
              </Box>
            );
          // case 'SimpleButtonRecord':
          //   switch (record.buttonStyle) {
          //     case 'Orange':
          //       return (
          //         <Box textAlign="center" mt="2rem">
          //           <Button variant="solid" minW="216px" h="2.85rem">
          //             <a href={record.linkUrl}>{record.buttonText}</a>
          //           </Button>
          //         </Box>
          //       );
          //     case 'White with Orange outline':
          //       return (
          //         <Box textAlign="center">
          //           <Button variant="ghost" onClick={handleToggle} mt="1rem">
          //             {' '}
          //             Read {show ? 'Less' : 'More'}
          //           </Button>
          //         </Box>
          //       );
          //     default:
          //       return null;
          //   }
          case 'SectionTitleRecord':
            return (
              <Heading
                as="h2"
                fontFamily="FedraSansStd-A-medium"
                fontSize="1.7rem"
                fontWeight="normal"
                color="#02026c"
                textAlign="center"
                // backgroundImage={`${config.imgPath}/46272/1630127754-divider-yoga.svg`}
                // backgroundImage="https://www.datocms-assets.com/46272/1630127754-divider-yoga.svg"
                bgRepeat="no-repeat"
                backgroundPosition="bottom"
                mt="3rem"
                mb="1.5rem"
                pb="1.5rem"
              >
                {record.titleText}
              </Heading>
            );
          default:
            if (record._modelApiKey === 'poem') {
              return (
                <BlogPoem
                  sectionContent={record}
                  region={region}
                  lang={language}
                  style={record.style}
                />
              );
            } else if (record._modelApiKey === 'simple_button') {
              return (
                <Box display={'flex'}>
                  <SimpleButton
                    style={record}
                    region={region}
                    lang={language}
                  />
                </Box>
              );
            }
            return null;
        }  
      }}
    />
  );
}
