/* Built In Imports */
/* External Imports */
import { Table, Tbody, Td, Tr } from '@chakra-ui/react';
import forEach from 'lodash/forEach';

/* Internal Imports */
/* Components */
// import config from '@config';
/* Services */

/**
 * Renders Upcoming Dates Component
 * @param {Object} record - Data
 * @returns {ReactElement} Upcoming Dates component
 */
export default function UpcomingDates(record) {
  let content = [];
  forEach(record.data, function (data) {
    const el = (
      <Tr key={data}>
        <Td>{data}</Td>
      </Tr>
    );
    content.push(el);
  });

  return (
    <Table
      variant="striped"
      colorScheme="blackAlpha"
      border="1px solid #ececec"
    >
      <Tbody>{content}</Tbody>
    </Table>
  );
}
