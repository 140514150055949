/* Built In Imports */
/* External Imports */
import { consoleLog } from '@components/Utility/Shared/SharedService';
import axios from 'axios';

/* Internal Imports */
/* Components */
/* Services */

const SESSION_KEY = 'somevalue';

/**
 * 
 */
export class HttpService {
  headers = {};

  constructor(url_prefix = '', rootUrl, defaultConfig) {
    this.url_prefix = url_prefix;
    this.rootUrl = rootUrl;
    this.defaultConfig = defaultConfig;
    this.getHeaders();
  }

  async get(url, queryParams) {
    let reqConfig = this.defaultConfig;
    if (reqConfig && reqConfig.params && queryParams) {
      reqConfig.params = { ...reqConfig.params, ...queryParams };
    }
    else {
      reqConfig = queryParams;
    }

    try {
      // consoleLog(`Starting Url----->>>>>> on`, new Date().toString(), ' >> ', this.rootUrl + this.getUrl(url), reqConfig);
    
      const response = await axios.get(this.rootUrl + this.getUrl(url), {
        data: {},
        ...reqConfig
      });
      
      // consoleLog(`Called Url----->>>>>> `, this.rootUrl + this.getUrl(url));
      // consoleLog('RESPONSE HEADERS FROM AXIOS', Object.keys(response.headers));
      // consoleLog('RESPONSE status log ' + response.status);
      // consoleLog('RESPONSE statusText', response.statusText);
      // consoleLog('RESPONSE data ', response.data ? 'coming' : 'not coming');
      // consoleLog(`Finished Url<<<<<<----- `, this.rootUrl + this.getUrl(url));      
      // consoleLog("RESPONSE FROM AXIOS", response);

      if (response.status === 200) return response.data;
      throw new Error('RESPONSE throw err', response.statusText);
    } catch (err) {
      // consoleLog(`Catch Root url logs `, this.rootUrl + this.getUrl(url));
      // consoleLog('Catch params', axiosConfig);
      consoleLog('response err log ', err.message);
      // const data = fs.writeFileSync('api-log.txt', content);
      return Promise.reject(err.message ? err.message : err);
    }
  }

  async post(url, body, queryParams = null) {
    try {
      const response = await axios.post(
        this.rootUrl + this.getUrl(url) + this.mapQueryParams(queryParams),
        body
      );
      if (response.status === 200) return response.data;
      throw new Error(response.statusText);
    } catch (err) {
      return Promise.reject(err.response ? err?.response.data?.message : err);
    }
  }

  async put(url, body, queryParams = null) {
    try {
      const response = await axios.put(
        this.rootUrl + this.getUrl(url) + this.mapQueryParams(queryParams),
        body
      );
      if (response.status === 200) return response.data;
      throw new Error(response.statusText);
    } catch (err) {
      return Promise.reject(err.message ? err.message : err);
    }
  }

  async remove(url, queryParams = null) {
    try {
      const response = await axios.delete(
        this.rootUrl + this.getUrl(url) + this.mapQueryParams(queryParams)
      );
      if (response.status === 200) return response.data;
      throw new Error(response.statusText);
    } catch (err) {
      return Promise.reject(err.message ? err.message : err);
    }
  }

  getUrl(url) {
    return this.url_prefix + url;
  }

  getHeaders() {
    this.headers = {
      'Content-Type': 'application/json',
      Accept: 'application/json',
    };
    if (this.checkSession()) {
      let apiToken = this.getSession().api_token;
      this.headers = {
        ...this.headers,
        Authorisation: `Bearer ${apiToken}`,
      };
    }
  }

  getSession() {
    // let session = localStorage.getItem(SESSION_KEY);
    // if (session) {
    //   return JSON.parse(session);
    // }
    // return session;
    return;
  }

  checkSession() {
    return;
    //return localStorage.getItem(SESSION_KEY) !== null;
  }

  mapQueryParams(queryParams) {
    return queryParams
      ? Object.keys(queryParams)
          .map(function (key) {
            return key + '=' + queryParams[key];
          })
          .join('&')
      : '';
  }
}
