/* Built In Imports */
/* External Imports */
import { Box, Flex } from '@chakra-ui/react';
import { render } from 'datocms-structured-text-to-html-string';

/* Internal Imports */
/* Components */
import config from 'config';
import StructuredTextParser from '@msr/Utility/StructuredTextParser';

/**
 *
 * @param {object} sectionContent
 * @returns
 */

const MsrBlogImageAtStart = ({ region, language, record }) => {
  return (
    <>
      <Box>
        <Flex flexDir="row" maxW={696} mx="auto">
          <Box display="inline" float="left">
            <StructuredTextParser
              mt="0"
              pt="0"
              px="0"
              str={render(record.body)}
              region={region}
              lang={language}
              textAlign={record?.style}
              sectionContent={record}
              id="MsrBlogImageAtStart"
              before
            />
          </Box>
          <style>
            {`
            #MsrBlogImageAtStart, #MsrBlogImageAtStart p{
                float:left;
                display:inline !important;
            }
            #MsrBlogImageAtStart p:first-child:before{
                content:'';
                float:left;
                width:70px;
                height:70px;
                float:left;
                margin-top:11px;
                margin-right:21px;
                display:inline !important;
                border-radius:20px;
                background-image:url(${config.imgPath}/d/46272/1682420348-images-4-1.jpg);
                background-position:center;
                background-size:cover;
                margin-bottom:5px;
            }
            #MsrBlogImageAtStart p strong, #MsrBlogImageAtStart strong{
              color: #000000;
            }
            @media only screen and (max-width: 600px){
              #MsrBlogImageAtStart p::before{
                width:53px !important;
                height:53px !important;
                margin-top:9px;
                margin-right:10px;
                border-radius:13px;
                margin-bottom:20px;
              }
            }
            `}
          </style>
        </Flex>
      </Box>
    </>
  );
};

export default MsrBlogImageAtStart;
