/* Built In Imports */
/* External Imports */
import { Box, Table, Tbody, Td, Tr } from '@chakra-ui/react';
import parser from 'html-react-parser';
import { useMediaQuery } from 'react-responsive';

/* Internal Imports */
/* Components */
import MidContainer from '@Layout/MidContainer';

/* Services */
/* Styles */

/**
 * Renders Json Table UI Component
 *
 * @param sectionContent.sectionContent
 * @param {object} sectionContent - Data from api
 * @param {string} region - Region of Page
 * @param {string} lang - Language of Page
 * @param sectionContent.region
 * @param sectionContent.lang
 * @returns {ReactElement} Json Table UI component.
 */
const JsonTableUI = ({ sectionContent, region, lang }) => {
  const isMobile = useMediaQuery({ maxWidth: 767 });

  return (
    <MidContainer maxW="768px">
      <Table variant="whiteAlpha" className="JsonTable" style={{borderCollapse:"separate"}}>
        <Tbody fontFamily="FedraSansStd-book">
          {sectionContent &&
            sectionContent.data &&
            Object.keys(sectionContent.data).map((item, index) => (
              <Tr
                border="0"
                p="0.35em"
                fontSize="18px"
                fontWeight="550"
                key={index}
              >
                <Td
                  fontWeight={index > 0 ? "100" : "600"}
                  fontSize={index > 0 ? "16px" : "26px"}
                  lineHeight={index > 0 ? "25.6px" : "36px"}
                  data-label={Object.keys(sectionContent.data)[0]}
                  verticalAlign={isMobile ? "top" : "middle"}
                  borderBottom={{base:'none', md:index > 0 ? '' : '2px solid #090D49'}}
                  mr={index > 0 ? '' : '20px'}
                  width='30%'
                >
                  {parser(item)}
                </Td>
                <Td
                  fontWeight={index > 0 ? "100" : "600"}
                  fontSize={index > 0 ? "16px" : "26px"}
                  lineHeight={index > 0 ? "25.6px" : "36px"}
                  borderLeft={{ base: "none", md: "0" }}
                  data-label={parser(Object.values(sectionContent.data)[0])}
                  verticalAlign={isMobile ? "top" : "middle"}
                  borderBottom={{base:'none', md:index > 0 ? '' : '2px solid #090D49'}}
                >
                  {parser(sectionContent.data[item])}
                </Td>
              </Tr>
            ))}
        </Tbody>
      </Table>
      <style global jsx>
        {`
        .JsonTable {
            border-spacing: 2.5em 0 !important;
        }
        .JsonTable td {
          color: #000000 !important;
        }
        @media only screen and (max-width: 600px){
        .JsonTable {
            border-spacing: 0 0 !important;
          }
        }
        `}
      </style>
    </MidContainer>
  );
};

export default JsonTableUI;
