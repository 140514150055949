const Styles = {
  bgColors: ['#DED5C3', '#B4AA96', '#DFD4BF', '#877D69', '#B6AA94'],
  clipPaths: [
    'polygon(0 0, 100% 0, 100% 100%, 0 90%)',
    'polygon(0 0, 100% 0, 100% 90%, 0% 98%)',
    'polygon(0 0, 100% 0, 100% 100%, 0 90%)',
  ]
};

export default Styles;
