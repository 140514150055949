/* Built In Imports */

/* External Imports */
import { Box, Heading } from '@chakra-ui/react';
import { render } from 'datocms-structured-text-to-html-string';

/* Internal Imports */
/* Components */
import LazyLoadImageComponent from '@components/UI/LazyLoadImageComponent';
import config from '@config';
import StructuredTextContent from '@msr/components/UI/StructuredTextContent';
/* Styles */
/* Services */

/**
 * Renders MsrBlogPoem Component
 *
 * @param {object} Card
 * @returns {ReactElement} MsrBlogPoem Component
 */

const MsrBlogPoem = ({ sectionContent, style }) => {
  const secStyle = style;
  return (
    <>
      <Box
        d="flex"
        textAlign="center"
        m={{ base: '40px auto', md: '40px auto 60px auto' }}
        w={{ base: '344px', md: '696px' }}
        fontWeight="350"
        flexDirection={'column'}
        alignItems="center"
        maxW="755px"
      >

        {sectionContent.title && (
          <Heading as="h3" mt="40px" color="#000000" fontSize={{base:'18px', md:'26px'}}>
            {sectionContent.title}
          </Heading>
        )}

        {render(sectionContent?.poem) &&
          render(sectionContent?.poem) !== '<p></p>' && (
            <Box
              fontSize={{ base: '16px', md: '18px' }}
              fontFamily="FedraSansStd-book"
              color="#000000"
              width={{  base: '70%',sm: '70%', md: '428px' }}
              height="auto"
              className={`poemtext${secStyle}`}
              lineHeight={{ base: '160%', md: '33px' }}
            >
              {StructuredTextContent(sectionContent.poem)}
            </Box>
          )}
        {sectionContent.showSadhguruSignature && (
          <LazyLoadImageComponent
            src={`${config.staticPath}/d/46272/1720185842-sg_sign.png`}
            h={{base:'55px', md:'84px'}}
            w={{base:'117px', md:'178px'}}
            mt={{base:'20px', md:'30px'}}
            mb="30px"
            alt="Sadhguru signature"
          />
        )}
        <LazyLoadImageComponent
            src={`${config.staticPath}/d/46272/1719491051-artboardty.png`}
            height={{ base: '28px', md: '42px' }}
            width={{ base: '136px', md: '211px' }}
            alt="Poem Separator"
          />
      <style global jsx>
        {`
        .poemtext p, .poemtextpoem p, .poemtextlyrics p {
            font-size: 18px !important;
            line-height: 32px !important;
          }
        .poemtextlyrics p {
            color : #49423B !important;
        }
        @media only screen and (max-width: 600px){
          .poemtext p, .poemtextpoem p, .poemtextlyrics p {
            font-size: 16px !important;
            line-height: 36px !important;
          }
        }
        `}
      </style>
      </Box>
    </>
  );
};

export default MsrBlogPoem;
