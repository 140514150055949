/* Built In Imports */
import NextLink from 'next/link';

/* External Imports */
import { Box, Heading, Link, Text } from '@chakra-ui/react';
import { useMediaQuery } from 'react-responsive';

/* Internal Imports */
/* Components */
import LazyLoadImageComponent from '@components/UI/LazyLoadImageComponent';
import StructuredTextContent from '@msr/components/UI/StructuredTextContent';
import { FooterExternalLinks } from './FooterExternalLinks';
import { FooterSocialMediaLinks } from './FooterSocialMediaLinks';

/* Services */

/**
 * Renders the Footer component
 *
 * @param {string} region - Country for constructing footer links.
 * @param {string} language Language for constructing footer links.
 * @param {object} footerData - Footer Data.
 * @returns {ReactElement} Footer component
 */

/**
 *
 * @param root0
 * @param root0.region
 * @param root0.language
 * @param root0.footerData
 */
export default function Footer({ region, language, footerData }) {
  const isMobile = useMediaQuery({ maxWidth: 360 });
  let footerMenuData = '';
  if (footerData && footerData?.allIsoMainMenus?.length > 0) {
    footerMenuData = footerData?.allIsoMainMenus;
  }
  // debugger;
  // consoleLog('FooterMenuData', footerMenuData);
  return <>
    <Box
      as="footer"
      className="footer"
      fontSize={{ base: '16px', lg: '0.85rem' }}
      fontWeight="normal"
      line-height="1.36"
      color="#aea493"
      backgroundColor="#141414"
      overflow="auto"
    >
      <Box
        display={{
          lg: 'flex',
        }}
        w="100%"
        margin="0 auto"
        backgroundColor="#141414"
        maxWidth="1300px"
        p={{
          base: '2rem 1.5rem 0 1.5rem',
          md: '2rem 1.5rem 0 1.5rem',
          lg: '60px 75px 30px 75px',
        }}
      >
        <FooterExternalLinks
          region={region}
          language={language}
          footerMenuData={footerMenuData}
        />

        <Box
          width={{ lg: '23%', md: '100%', sm: '100%', base: '100%' }}
          pl={{ base: '0', lg: '3%' }}
          mr={{ base: '0', lg: '3%' }}
          marginInlineStart={{ base: '0' }}
        >
          <Heading
            as="h4"
            fontSize={{ base: '20px', lg: '20px' }}
            fontFamily="FedraSansStd-A-medium"
            fontWeight="500"
            fontStyle="normal"
            color="#FFF7E9"
            mb={{ base: '0.3rem', lg: '1.7rem' }}
            ml={{ base: '0' }}
            marginInlineStart={{ base: '0' }}
          >
            {footerMenuData[2]?.submenuItems[0]?.title}
          </Heading>
          <Box
            fontSize="16px"
            fontWeight="normal"
            lineHeight="1.36"
            color="#C1C3DD"
            fontFamily="FedraSansStd-book,sans-serif"
            mb={{ base: '0.5rem' }}
            display="inline-block"
            width={'100%'}
            className="contactUsBlock"
          >
            <Box>
              <Box as="p" className="icon_text_1">
                {StructuredTextContent(
                  footerMenuData[2]?.submenuItems[0]?.body
                )}
              </Box>
            </Box>
          </Box>
        </Box>
        <Box
          width={{ lg: '19%', md: '100%', sm: '100%' }}
          mt={{
            base: '1rem',
            lg: '0',
            md: '1rem',
          }}
          // pl={{ base: '3%' }}
        >
          <Heading
            as="h4"
            fontSize={{ base: '20px', lg: '20px' }}
            fontFamily="FedraSansStd-A-medium"
            fontWeight="500"
            fontStyle="normal"
            color="#FFF7E9"
            mb={{ base: '0.5rem', lg: '1.7rem' }}
          >
            {footerMenuData[3]?.submenuItems[0]?.title}
          </Heading>
          <Box>
            <LazyLoadImageComponent
              src={footerMenuData[3]?.submenuItems[1]?.icon?.url}
              alt={footerMenuData[3]?.submenuItems[1]?.icon?.alt}
              title={footerMenuData[3]?.submenuItems[1]?.icon?.title}
              display="block"
            />
            <Box
              display="flex"
              flexDir="column"
              paddingTop="1rem"
              mb={{ base: '20px' }}
            >
              <Link
                href={footerMenuData[3]?.submenuItems[2]?.url}
                cursor="pointer"
                title={footerMenuData[3]?.submenuItems[2]?.title}
                mb={{ lg: '10px' }}
              >
                <LazyLoadImageComponent
                  src={footerMenuData[3]?.submenuItems[2]?.icon?.url}
                  alt={footerMenuData[3]?.submenuItems[2]?.icon?.alt}
                  title={footerMenuData[3]?.submenuItems[2]?.icon?.title}
                  p="0 0 10px 0"
                />
              </Link>
              <Link
                href={footerMenuData[3]?.submenuItems[3]?.url}
                cursor="pointer"
                title={footerMenuData[3]?.submenuItems[3]?.title}
              >
                <LazyLoadImageComponent
                  src={footerMenuData[3]?.submenuItems[3]?.icon?.url}
                  alt={footerMenuData[3]?.submenuItems[3]?.icon?.alt}
                  title={footerMenuData[3]?.submenuItems[3]?.icon?.title}
                  p="0 0 15px 0"
                />
              </Link>
            </Box>
          </Box>
        </Box>
        <Box
          pl={{ base: '3%', lg: '5%' }}
          display="block"
          width={{ base: '100%', lg: '29%', md: '100%' }}
        >
          <FooterSocialMediaLinks
            region={region}
            language={language}
            footerMenuData={footerMenuData}
          />
        </Box>
      </Box>
      <Box
        backgroundColor="#141414"
        p={{
          base: '0 1.5rem 2rem 2rem',
          lg: '1rem calc((100% - 1140px) / 2)',
        }}
        display={{ base: 'block', lg: 'flex' }}
        width="100%"
      >
        <Box
          color="#676055"
          mt={{ base: '16px', lg: '0' }}
          mb={{ base: '22px', lg: '0' }}
        >
          <LazyLoadImageComponent
            src={footerMenuData[5]?.submenuItems[0]?.icon?.url}
            alt={footerMenuData[5]?.submenuItems[0]?.icon?.alt}
            title={footerMenuData[5]?.submenuItems[0]?.icon?.title}
            width="27px"
          />
        </Box>
        <Box
          fontFamily="FedraSansStd-book,sans-serif"
          fontSize="12px"
          color="#C1C3DD"
          p="0"
          ml={{ base: '0', lg: 'auto' }}
          mb={{ base: '16px', lg: '0' }}
          w={isMobile ? '100%' : { base: '360px', sm: '80%', lg: 'auto' }}
        >
          <Text pt="3px">
            {footerMenuData[5]?.submenuItems[1]?.title}
            <Box as="span" paddingLeft="2px" paddingRight="2px">
              {' '}
              |{' '}
            </Box>
            <NextLink href={footerMenuData[5]?.submenuItems[2]?.url} passHref legacyBehavior>
              <Link>
                <Text as="span" cursor="pointer">
                  {footerMenuData[5]?.submenuItems[2]?.title}
                </Text>
              </Link>
            </NextLink>
            <Box as="span" paddingLeft="2px" paddingRight="2px">
              {' '}
              |{' '}
            </Box>
            <NextLink href={footerMenuData[5]?.submenuItems[3]?.url} passHref legacyBehavior>
              <Link>
                <Text as="span" cursor="pointer">
                  {footerMenuData[5]?.submenuItems[3]?.title}
                </Text>
              </Link>
            </NextLink>
          </Text>
        </Box>
      </Box>
      <style jsx global>{`
        .footer {
          clear: both;
        }
        .contactUsBlock img {
          width: 12px;
          height: 12px;
        }
      `}</style>
    </Box>
  </>;
}
