const IsoApiConstants = {
  components: {
    topBannerSimple: 'top_banner_simple',
    topBannerV2: 'top_banner_event',
    topBannerEvent: 'top_banner_event',
    topKolam: 'top_kolam',
    structuredText: 'structured_text',
    sectionTitle: 'section_title',
    cardInfo2ColumnGroup: 'card_info2_column_group',
    cardInfo4ColumnGroup: 'card_info4_column_group',
    health2ColumnCard: 'health2_column_card',
    simpleButton: 'simple_button',
    gallery: 'gallery',
    testimonialsGroupV2: 'testimonials_group_v2',
    relatedContent: 'related_content',
    relatedHealthProgram: 'related_health_program',
    upcomingProgramsV2: 'upcoming_programs_v2',
    faqSectionLinkedContent: 'faq_section_linked_content',
    newsletterSubscriptionForm: 'newsletter_subscription_form'
  },
  sectionId: {
    healthButtonLoginPopup: 'health-button-login-popup',
    faqSectionLinkedContent: 'faq_section_linked_content',
    faqSectionStd: 'FAQ-SECTION-STD',
    faqSection: 'faq-section',
    faq: 'Faq',
    frequentlyAskedQuestions: 'Frequently Asked Questions',
    architecture: 'architecture',
    processesAndOfferings: 'processes-and-offerings',
    ritualsAndOfferings:'rituals-and-offerings',
    
    //
    globalCenters: 'global-centers'
  },
  fieldId: {
    largeBrownBulletsHealth: 'large-brown-bullets-health'
  }
};

export default IsoApiConstants;
