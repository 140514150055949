/* Built In Imports */
/* External Imports */
import { Accordion } from '@chakra-ui/accordion';
import { theme as baseTheme, extendTheme } from '@chakra-ui/react';
import { createBreakpoints } from '@chakra-ui/theme-tools';

/* Internal Imports */
/* Components */
import { ButtonStyles as Button } from './components/button';

/* Styles */
// import { Belanosima, Zilla_Slab } from 'next/font/google';
import { styles } from './styles';
// export const belanosima = Belanosima({
//   weight: ['400', '600'],
//   style: 'normal',
//   subsets: ['latin'],
//   display: 'swap',
// });
// export const zilla_Slab = Zilla_Slab({
//   weight: ['400', '500', '600', '700'],
//   style: ['italic'],
//   subsets: ['latin'],
//   display: 'swap',
// });
const colors = {
  defaultBg: {
    50: '#f5f0e5',
  },
  orange: {
    50: '#cf4520',
    100: '#cf4824',
  },
  blue: {
    50: '#000054',
    100: '#02026c',
    200: '#C1C3DD', // lightBlue from MSR Figma VDs
    300: '#c1c3dd', // babyBlue from MSR Figma VDs
    400: '#03062C', // darkBlue from MSR Figma VDs
    500: '#00052f', // darkBlue background from MSR Figma VDs
    600: '#252964', // MSR Event card background
    700: '#8F92C1', // lightBlue 2 from MSR Figma VDs
  },
  gray: {
    50: '#aea493',
    100: '#707070',
    200: '#6e665b',
    300: '#e4ded4',
    400: '#dfd6c4',
    500: '#676055',
  },
  black: {
    50: '#28231e',
    100: '#000008',
    200: '#121212',
    300: '#464038',
    400: '#282728',
    500: '#030202',
    600: '#010717',
  },
  white: {
    50: '#fff7e9', // lightWhite from MSR Figma VDs
    100: '#fffdfd',
  },
  yellow: {
    50: '#cca146',
    100: '#ffa735', // actionYellow from MSR Figma VDs
  },
  floralWhite: {
    100: '#f8f6f3',
  },
  albescentWhite: '#dfd6c4',
  fulvous: '#dc8513',
};

/**
 * Breakpoints for responsive design
 */
const breakpoints = createBreakpoints({
  sm: '480px',
  md: '768px',
  lg: '960px',
  xl: '1200px',
  '2xl': '1536px',
  '3xl': '2560px',
});

const container = {
  sm: '640px',
  md: '768px',
  lg: '1024px',
  xl: '1280px',
};

// const fonts = process.env.NEXT_PUBLIC_BUILDTYPE === 'DATOCMS_ILA' ? {}:{
//   heading: "'FedraSansStd-A-medium', Georgia Bold, Serif Bold",
//   body: "'FedraSerifAStdBook', Georgia, Serif",
// };
let fonts = {
  heading: "'FedraSansStd-A-medium', Georgia Bold, Serif Bold",
  body: "'FedraSerifAStdBook', Georgia, Serif",
};
if (process.env.NEXT_PUBLIC_BUILDTYPE.toUpperCase() === 'DATOCMS_ILA') {
  fonts = {
    heading: 'sans-serif',
    body: 'sans-serif',
  };
}

let ishaThemeProps = {};

const lbThemeProps = extendTheme({
  ...extendTheme,
  colors: {
    ...extendTheme.colors,
    common: {
      white: '#fff',
      black: '#000',
      red: '#A9392D',
      brown: '#B7987E',
      maroon: '#A9392D',
    },
    success: {
      color: '#24821C',
    },
    error: {
      color: '##982929',
    },
    primary: {
      main: '#BC4034',
      light: '#E85B4C',
    },
    secondary: {
      main: '#F6A15E',
    },
    title: {
      light: '#F3F0E6',
      dark: '#823333',
    },
    headingText: {
      main: '#65000F',
      light: '#D4CAAE',
      dark: '#121212',
    },
    bodyText: {
      color1: '#49423B',
      color2: '#8D7452',
      color3: '#F8F8F8',
    },
    highlightText: {
      color1: '#F6A15E',
      color2: '#FFCB66',
      color3: '#BC4034',
    },
    quoteText: {
      color1: '#A36D23',
    },
    quoteGradient: {
      yellow:
        'linear-gradient(90deg, rgba(239, 221, 155, 0.36) 0%, rgba(214, 164, 118, 0) 100%)',
    },
    bannerGradient: {
      bannerOrange:
        'linear-gradient(180deg, rgba(239, 221, 155, 0.7) 0%, rgba(214, 164, 118, 0) 100%)',
      bannerDark:
        'linear-gradient(180deg, #340209 20.42%, rgba(52, 2, 9, 0) 87.38%)',
    },
    hyperLink: {
      main: '#C65A28',
    },
    buttonText: {
      color1: '#BC4034',
      color2: '#DB3030',
    },
  },
  fonts: {
    body: "'FedraSerifAStdBook', system-ui, sans-serif",
  },
  fontSizes: {
    h1: '100px',
    h2: '80px',
    h3: '64px',
    h4: '52px',
    h5: '46px',
    h6: '24px',
    body1: '18px',
    body2: '16px',
    body3: '14px',
  },
  components: {
    Heading: {
      baseStyle: {
        fontFamily: 'FedraSansStd-A-medium',
        lineHeight: 'inherit',
        fontWeight: 'normal',
      },
    },

    Button: {
      baseStyle: {
        fontFamily: 'FedraSansStd-A-medium',
        borderRadius: 0,
        lineHeight: 'inherit',
        color: '#fff',
        fontWeight: 500,
        boxSizing: 'border-box',
      },
      sizes: {
        sm: {
          h: '56px',
          fontSize: '18px',
          px: '32px',
        },
        lg: {
          h: '56px',
          fontSize: '22px',
          px: '32px',
        },
      },

      variants: {
        primary: {
          borderLeft: '11.938px solid #F7A15E',
          fontVariantCaps: 'small-caps',
          fontSize: '21px',
          background: 'primary.main',
          boxSizing: 'border-box',
          '&:hover': {
            background: 'secondary.main',
          },
        },
        secondary: {
          bg: '#DB3030',
          '&:hover': {
            bg: '#BB1A1A',
          },
        },
        defaultProps: {
          size: 'lg',
        },
      },
    },
  },
  baseTheme,
});

const IhsThemeProps = extendTheme({
  ...extendTheme,
  colors: {
    ...extendTheme.colors,
    common: {
      white: '#fff',
      black: '#000',
      red: '#A9392D',
      brown: '#B7987E',
      maroon: '#A9392D',
    },
    success: {
      color: '#24821C',
    },
    error: {
      color: '##982929',
    },
    primary: {
      main: '#BC4034',
      light: '#E85B4C',
    },
    secondary: {
      main: '#F6A15E',
    },
    title: {
      light: '#F3F0E6',
      dark: '#823333',
    },
    headingText: {
      main: '#65000F',
      light: '#D4CAAE',
      dark: '#121212',
    },

    hyperLink: {
      main: '#C65A28',
    },
    buttonText: {
      color1: '#BC4034',
      color2: '#DB3030',
    },
  },
  fonts: {
    body: "'Zilla Slab', serif",
  },
  fontSizes: {
    h1: '100px',
    h2: '80px',
    h3: '64px',
    h4: '52px',
    h5: '46px',
    h6: '24px',
    body1: '18px',
    body2: '16px',
    body3: '14px',
  },
  components: {
    Heading: {
      baseStyle: {
        fontFamily: 'Belanosima',
        lineHeight: 'inherit',
        fontWeight: 'normal',
      },
    },

    Button: {
      baseStyle: {
        fontFamily: "'Zilla Slab', serif",
        borderRadius: 0,
        lineHeight: 'inherit',
        color: '#fff',
        fontWeight: 500,
        boxSizing: 'border-box',
      },
      sizes: {
        sm: {
          h: '56px',
          fontSize: '18px',
          px: '32px',
        },
        lg: {
          h: '56px',
          fontSize: '22px',
          px: '32px',
        },
      },

      variants: {
        primary: {
          borderLeft: '11.938px solid #F7A15E',
          fontVariantCaps: 'small-caps',
          fontSize: '21px',
          background: 'primary.main',
          boxSizing: 'border-box',
          '&:hover': {
            background: 'secondary.main',
          },
        },
        secondary: {
          bg: '#DB3030',
          '&:hover': {
            bg: '#BB1A1A',
          },
        },
        defaultProps: {
          size: 'lg',
        },
      },
    },
  },
  baseTheme,
});

const ShivangaThemeProps = extendTheme({
  ...extendTheme,
  components: {
    Heading: {
      baseStyle: {
        fontFamily: 'FedraSansStd-A-medium',
        fontWeight: 'normal',
      },
    },

    Button: {
      baseStyle: {
        fontFamily: 'FedraSansStd-medium',
        borderRadius: '3px',
        lineHeight: 'inherit',
        color: '#fff',
        padding: '14px 29px',
        fontWeight: 'normal',
        boxSizing: 'border-box',
        minW: '130px',
      },
      sizes: {
        sm: {
          h: '44px',
          fontSize: '14px',
          px: '29px',
        },
        lg: {
          h: '48px',
          fontSize: '16px',
          px: '29px',
        },
      },

      variants: {
        primary: {
          fontSize: '14px',
          background: '#CA4E2A',
          boxSizing: 'border-box',
          '&:hover': {
            background: '#983C21',
          },
          '&:focus': {
            boxShadow: 'none',
          },
        },
        ghost: {
          fontSize: '14px',
          background: 'none',
          color: '#fff',
          border: '1px solid #FFF',
          boxSizing: 'border-box',
          '&:hover': {
            background: '#E19502',
            color: '#fff',
            border: '1px solid transparent',
          },
          '&:focus': {
            boxShadow: 'none',
          },
        },
        outlined: {
          fontSize: '16px',
          background: 'none',
          border: '1px solid #28231E',
          color: '#28231E',
          boxSizing: 'border-box',
          '&:hover': {
            background: '#E19502',
            color: '#28231E',
            border: '1px solid transparent',
          },
          '&:focus': {
            boxShadow: 'none',
          },
        },
        defaultProps: {
          size: 'lg',
        },
      },
    },
  },
});

if (process.env.NEXT_PUBLIC_BUILDTYPE?.toUpperCase() === 'LINGABHAIRAVI') {
  ishaThemeProps = lbThemeProps;
} else if (process.env.NEXT_PUBLIC_BUILDTYPE?.toUpperCase() === 'DATOCMS_IHS') {
  ishaThemeProps = IhsThemeProps;
} else if (process.env.NEXT_PUBLIC_BUILDTYPE?.toUpperCase() === 'DATOCMS_SHI') {
  ishaThemeProps = ShivangaThemeProps;
} else {
  ishaThemeProps = extendTheme({
    breakpoints,
    styles,
    colors,
    container,
    fonts,
    components: {
      Button,
      Accordion,
    },
    outline: {
      shadows: 'none',
    },
    focus: {
      outline: 'none',
      boxShadow: 'none',
    },
  });
}

export const ishaTheme = ishaThemeProps;
export const lingaBhairaviTheme = lbThemeProps;
export const shivangaTheme = ShivangaThemeProps;
