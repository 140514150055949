/* Built In Imports */
import NextLink from 'next/link';
import { useRouter } from 'next/router';
import React, { useContext, useEffect, useRef, useState } from 'react';

/* External Imports */
import { CloseIcon } from '@chakra-ui/icons';
import {
  Box,
  Button,
  Flex,
  Image,
  Link,
  List,
  ListItem,
  Popover,
  PopoverArrow,
  PopoverBody,
  PopoverContent,
  PopoverTrigger,
  Text,
} from '@chakra-ui/react';
import { nanoid } from 'nanoid';
import { useMediaQuery } from 'react-responsive';

/* Internal Imports */
/* Components */
import { getCookie } from '@components/Utility/utils';
import config from '@config';
import { FlagButton } from '@lb/components/Icons/lbArrowIcons';
import { refineUrlDomain } from '@lb/components/Utility/utils';
import { AuthContext } from '@store/auth-context';

/* Styles */
/* Services */

/**
 *
 * @param user
 * @param onClick
 * @param handleClick
 * @param headerMenuData
 * @param topMenuData
 * @param region
 * @param language
 */

const MobileNav = ({
  // user,
  handleClick,
  headerMenuData,
  topMenuData,
  region,
  language,
}) => {
  const router = useRouter();
  const authCtx = useContext(AuthContext);
  const [isLoggedIn, setIsLoggedIn] = useState(false);
  // region = authCtx?.region || region;
  // language = authCtx?.lang || language;
  const { userName, userImage } = authCtx;
  const isMobile = useMediaQuery({ maxWidth: 1000 });
  const loginURL = `${config.FACADE_BASE_PATH}/login?request_url=${config.BASE_PATH}${router.asPath}&action=0`;

  const mobileMenu = useRef();
  // const [Profile, setProfile] = useState(false);
  // const [lSwitchVisible, setLSwitchVisible] = useState(false);
  // const [rSwitchVisible, setRSwitchVisible] = useState(false);
  const login = topMenuData?.data?.allIsoMainMenus[5];
  const topMenu = topMenuData?.data?.allIsoMainMenus[7];
  const cartCookie = getCookie(
    region === 'np' ? 'numCartItemsS2' : 'numCartItemsS1'
  );

  const logoutHandler = () => {
    authCtx.logout();
  };

  useEffect(() => {
    setIsLoggedIn(authCtx.isLoggedIn);
  }, [authCtx.isLoggedIn]);

  const MNNavItem = ({ bgImg, children, url, region, language }) => {
    return (
      <NextLink
        href={refineUrlDomain(url, region, language)}
        passHref
        legacyBehavior
      >
        <Link textDecor="none" _hover={{ textDecor: 'none' }}>
          <Box
            width="100%"
            height="75.68px"
            padding="0 20px"
            display="flex"
            color="#D09700"
            fontFamily="'FedraSansStd-A-medium'"
            fontStyle="normal"
            fontWeight="500"
            fontSize="body3"
            lineHeight="18px"
            alignItems="center"
            maxH="75.68px"
            margin="1.32px 0"
            _hover={{ color: 'common.white' }}
            backgroundImage={`linear-gradient(90deg, #622306 45.41%, rgba(98, 35, 6, 0) 84.47%), url(${bgImg})`}
            backgroundRepeat="no-repeat, no-repeat"
            backgroundPosition="top, right"
            backgroundSize="cover, cover"
            textTransform="uppercase"
            onClick={() => (mobileMenu.current.style.display = 'none')}
            cursor="pointer"
          >
            {children}
          </Box>
        </Link>
      </NextLink>
    );
  };

  const bgImages = {
    0: `${config.imgPath}/d/46272/1681485130-20100304_chi_0004-e-0.jpg`,
    1: `${config.imgPath}/d/46272/1681485134-20181015_chi_0220-e-1.jpg`,
    2: `${config.imgPath}/d/46272/1681485137-20181015_chi_0220-e-2.jpg`,
    3: `${config.imgPath}/d/46272/1681485142-20181015_chi_0220-e-3.jpg`,
    4: `${config.imgPath}/d/46272/1681485147-20181015_chi_0220-e-4.jpg`,
    5: `${config.imgPath}/d/46272/1681485151-20181015_chi_0220-e-5.jpg`,
    6: `${config.imgPath}/d/46272/1681485155-20181015_chi_0220-e-6.jpg`,
    7: `${config.imgPath}/d/46272/1681485126-20181015_chi_0220-e-7.jpg`,
  };

  return (
    <Box
      bg="#fff"
      ref={mobileMenu}
      width="100%"
      // maxH="auto"
      overflow="scroll"
      pos="fixed"
      maxH="auto"
      top={0}
      // right="0"
      display={isMobile ? 'block' : 'none'}
      bottom={0}
      left="0"
      zIndex={999999}
      className="LBMobileNav"
      boxSizing="border-box"
      backgroundImage={`url(${config.imgPath}/d/46272/1683175865-white-texture-1.jpg)`}
    >
      <Box py="10px" width="100%" style={{ scrollBehavior: 'smooth' }}>
        <Box
          pos="absolute"
          top="0"
          right="0"
          onClick={handleClick}
          transition="width 1s ease-in, height 3s ease-in"
        >
          <FlagButton
            content={<CloseIcon color="white" />}
            bgColor="#D09700"
            color="white"
            position="absolute"
            fontSize="22px"
            top="10%"
            right="20%"
            clipPath="polygon(100% 0, 0 0, 100% 100%)"
          />
        </Box>
        {/* <LazyLoadImageComponent
            mx="20px"
            alt="logo"
            src="/assets/images/LingaBhairavi_1.png"
          /> */}
        <Box px="16px" onClick={handleClick}>
          <NextLink
            href={refineUrlDomain('/', region, language)}
            passHref
            legacyBehavior
          >
            <Link p="0" m="0">
              <Image
                alignSelf="center"
                cursor="pointer"
                width="80%"
                userSelect="none"
                maxW={{ base: '143.31px', lg: '196px' }}
                height="100%"
                minH={{ base: '70px', lg: '73px' }}
                maxHeight="96px"
                src={`${config.imgPath}/d/46272/1681884350-lingabhairavi-1.svg`}
                alt="LingaBhairavi"
              />
            </Link>
          </NextLink>
        </Box>

        <Box>
          <Flex width="100%" justifyContent="space-between" px="20px" py="10px">
            {/* user profile  */}
            {isLoggedIn && (
              <Popover
                trigger="click"
                // isLazy
                placement="bottom-start"
                // onOpen={setIsEditing.on}
                // onClose={setIsEditing.off}
                // initialFocusRef={initRef}
              >
                {/* {({ isOpen, onClose }) => ( */}

                <PopoverTrigger>
                  <Button
                    display="flex"
                    className="userProfile"
                    alignItems="center"
                    bg="transparent"
                    _hover={{ bg: 'transparent' }}
                    _active={{ bg: 'transparent' }}
                    _focus={{ bg: 'transparent' }}
                  >
                    <Image
                      alt="user image"
                      className="userImage"
                      src={userImage || login?.icon?.url}
                      width="36px"
                      height="36px"
                      rounded="full"
                      mr="10px"
                    />

                    <Box
                    // onClick={event => setProfile(!Profile)}
                    // pos="relative"
                    >
                      <Text
                        fontSize="body3"
                        fontFamily="FedraSansStd-book"
                        color="#A9392D"
                        lineHeight="15px"
                        fontWeight="600"
                        mb="3px"
                      >
                        {userName}
                      </Text>
                      <Text
                        fontSize="12px"
                        fontFamily="FedraSansStd-book"
                        color="#D09700"
                        fontStyle="italic"
                        lineHeight="10px"
                        fontWeight="400"
                      >
                        Profile, Settings, Rituals
                      </Text>
                      {/* <Box
                      pos="absolute"
                      display={Profile ? 'block' : 'none'}
                      bottom="-125px"
                      left="0px"
                      width="159px"
                      height="auto"
                      padding="16px"
                      rounded="md"
                      boxShadow="0px 20px 13px rgba(90, 63, 9, 0.035), 0px 8.14815px 6.51852px rgba(90, 63, 9, 0.0274815), 0px 1.85185px 3.14815px rgba(90, 63, 9, 0.0168519)"
                      background="common.white"
                      border="1px solid #EDD1C2"
                      onClick={() => setProfile(false)}
                    >
                      <List>
                        <List>
                          <ListItem
                            fontSize={{ base: '14px', md: '14px' }}
                            lineHeight={{ base: '31px', md: '31px' }}
                            color="#B7987E"
                            fontWeight="400"
                            _hover={{
                              color: '#A33A2C',
                              fontWeight: '500',
                              fontFamily: "'FedraSansStd-medium'",
                            }}
                            fontFamily="'FedraSansStd-book'"
                            fontStyle="'normal'"
                            textAlign="left"
                          >
                            <LazyLoadImageComponent
                              alt={topMenu?.submenuItems[0]?.title}
                              _hover={{
                                content: `url(${topMenu?.submenuItems[0]?.iconHover?.url})`,
                              }}
                              src={topMenu?.submenuItems[0]?.icon?.url}
                            />
                            <Link
                              textDecor="none"
                              _hover={{ color: '#A33A2C' }}
                              href={
                                topMenu?.submenuItems[0]?.url ||
                                'https://arpanam.sadhguru.org/account'
                              }
                            >
                              {topMenu?.submenuItems[0]?.title}
                            </Link>
                          </ListItem>

                          <ListItem
                            fontSize={{ base: '14px', md: '14px' }}
                            lineHeight={{ base: '31px', md: '31px' }}
                            color="#B7987E"
                            fontWeight="400"
                            _hover={{
                              color: '#A33A2C',
                              fontWeight: '500',
                              fontFamily: "'FedraSansStd-medium'",
                            }}
                            fontFamily="'FedraSansStd-book'"
                            fontStyle="'normal'"
                            textAlign="left"
                          >
                            <LazyLoadImageComponent
                              alt={topMenu?.submenuItems[1]?.title}
                              _hover={{
                                content: `url(${topMenu?.submenuItems[1]?.iconHover?.url})`,
                              }}
                              src={topMenu?.submenuItems[1]?.icon?.url}
                            />

                            <Link
                              textDecor="none"
                              _hover={{ color: '#A33A2C' }}
                              onClick={logoutHandler}
                            >
                              {topMenu?.submenuItems[1]?.title}
                            </Link>
                          </ListItem>
                        </List>
                      </List>
                    </Box> */}
                    </Box>
                  </Button>
                </PopoverTrigger>
                <PopoverContent
                  width="159px"
                  height="auto"
                  // padding="16px"
                  rounded="md"
                  boxShadow="0px 20px 13px rgba(90, 63, 9, 0.035), 0px 8.14815px 6.51852px rgba(90, 63, 9, 0.0274815), 0px 1.85185px 3.14815px rgba(90, 63, 9, 0.0168519)"
                  background="common.white"
                  border="1px solid #EDD1C2"
                  _hover={{ outline: 'none' }}
                  _focus={{ outline: 'none' }}
                  transition="all 0.1s linear"
                  zIndex="auto"
                  // padding="12px"
                >
                  <PopoverArrow
                    marginLeft="-69px"
                    bg="#EDD1C2"
                    zIndex="-5"
                    transition="all 0.4s linear"
                  />
                  <PopoverBody padding="0px">
                    <Box>
                      <List>
                        <List>
                          <ListItem
                            pl="16px"
                            fontSize={{ base: '14px', md: '14px' }}
                            lineHeight={{ base: '31px', md: '31px' }}
                            color="#B7987E"
                            fontWeight="400"
                            _hover={{
                              color: '#A33A2C',
                              fontWeight: '500',
                              fontFamily: "'FedraSansStd-medium'",
                            }}
                            fontFamily="'FedraSansStd-book'"
                            fontStyle="'normal'"
                            textAlign="left"
                          >
                            <Image
                              alt={topMenu?.submenuItems[0]?.title}
                              _hover={{
                                content: `url(${topMenu?.submenuItems[0]?.iconHover?.url})`,
                              }}
                              src={topMenu?.submenuItems[0]?.icon?.url}
                            />
                            <Link
                              marginLeft="14px"
                              textDecor="none"
                              _hover={{ color: '#A33A2C' }}
                              target="_blank"
                              href={
                                topMenu?.submenuItems[0]?.url ||
                                'https://arpanam.sadhguru.org/account'
                              }
                            >
                              {topMenu?.submenuItems[0]?.title}
                            </Link>
                          </ListItem>

                          <ListItem
                            pl="16px"
                            fontSize={{ base: '14px', md: '14px' }}
                            lineHeight={{ base: '31px', md: '31px' }}
                            color="#B7987E"
                            fontWeight="400"
                            _hover={{
                              color: '#A33A2C',
                              fontWeight: '500',
                              fontFamily: "'FedraSansStd-medium'",
                            }}
                            fontFamily="'FedraSansStd-book'"
                            fontStyle="'normal'"
                            textAlign="left"
                          >
                            <Image
                              alt={topMenu?.submenuItems[1]?.title}
                              _hover={{
                                content: `url(${topMenu?.submenuItems[1]?.iconHover?.url})`,
                              }}
                              src={topMenu?.submenuItems[1]?.icon?.url}
                            />

                            <Link
                              marginLeft="14px"
                              textDecor="none"
                              _hover={{ color: '#A33A2C' }}
                              onClick={logoutHandler}
                            >
                              {topMenu?.submenuItems[1]?.title}
                            </Link>
                          </ListItem>
                        </List>
                      </List>
                    </Box>
                  </PopoverBody>
                </PopoverContent>

                {/* )} */}
              </Popover>
            )}

            {!isLoggedIn && (
              <Link href={loginURL} _hover={{ textDecoration: 'none' }}>
                <Flex className="userProfile" alignItems="center">
                  <Image
                    alt="user image"
                    className="userImage"
                    src={login?.icon?.url}
                    width="36px"
                    height="36px"
                    rounded="full"
                    mr="10px"
                  />
                  <Box
                    // onClick={event => setProfile(!Profile)}
                    pos="relative"
                  >
                    <Text
                      fontSize="body3"
                      fontFamily="FedraSansStd-book"
                      color="#A9392D"
                      lineHeight="15px"
                      fontWeight="600"
                      mb="3px"
                    >
                      {login?.title}
                    </Text>
                    <Text
                      fontSize="12px"
                      fontFamily="FedraSansStd-book"
                      color="#D09700"
                      fontStyle="italic"
                      lineHeight="10px"
                      fontWeight="400"
                    >
                      {login?.subtext}
                    </Text>
                    {/* <Box
                      pos="absolute"
                      display={Profile ? 'block' : 'none'}
                      bottom="-125px"
                      left="0px"
                      width="159px"
                      height="auto"
                      padding="16px"
                      rounded="md"
                      boxShadow="0px 20px 13px rgba(90, 63, 9, 0.035), 0px 8.14815px 6.51852px rgba(90, 63, 9, 0.0274815), 0px 1.85185px 3.14815px rgba(90, 63, 9, 0.0168519)"
                      background="common.white"
                      border="1px solid #EDD1C2"
                      // onClick={() => setProfile(false)}
                    >
                      <List>
                        <ToggleAbleListItem
                          title={topMenu?.submenuItems[0]?.title}
                          icon={topMenu?.submenuItems[0]?.icon?.url}
                          hoverIcon={topMenu?.submenuItems[0]?.iconHover?.url}
                          region={region}
                          language={language}
                        />
                        <ToggleAbleListItem
                          title={topMenu?.submenuItems[1]?.title}
                          icon={topMenu?.submenuItems[1]?.icon?.url}
                          hoverIcon={topMenu?.submenuItems[1]?.iconHover?.url}
                          region={region}
                          language={language}
                        />
                      </List>
                    </Box> */}
                  </Box>
                </Flex>
              </Link>
            )}

            <Flex position="relative">
              <Box
                width="36px"
                h="36px"
                textAlign="center"
                display="grid"
                fontSize="body2"
                color="#D47A33"
                placeItems="center"
                cursor="pointer"
                mx="5px"
              >
                <Popover placement="bottom-end" trigger="click">
                  <PopoverTrigger>
                    {/* <Link> */}
                    <Button
                      outline="none"
                      border="none"
                      bg="none"
                      _focus={{ border: 'none', outline: 'none', bg: 'none' }}
                      _hover={{ border: 'none', outline: 'none', bg: 'none' }}
                      _active={{ border: 'none', outline: 'none', bg: 'none' }}
                    >
                      <Box
                        as="span"
                        w="36px"
                        bg="#EBE9DB"
                        rounded="full"
                        h="36px"
                        display="inline-flex"
                        alignItems="center"
                        justifyContent="center"
                      >
                        {topMenuData?.data?.allIsoMainMenus[1]?.icon?.url ? (
                          <Image
                            src={
                              topMenuData?.data?.allIsoMainMenus[1]?.icon?.url
                            }
                            alt={topMenuData?.data?.allIsoMainMenus[1]?.title}
                            width="auto"
                            height="auto"
                            // onClick={() => {
                            //   setRSwitchVisible(!rSwitchVisible);
                            //   setLSwitchVisible(false);
                            // }}
                          />
                        ) : (
                          <Text>
                            {topMenuData?.data?.allIsoMainMenus[1]?.title}
                          </Text>
                        )}
                      </Box>
                    </Button>
                    {/* </Link> */}
                  </PopoverTrigger>
                  <PopoverContent
                    width="83px"
                    height="auto"
                    rounded="md"
                    boxShadow="0px 20px 13px rgba(90, 63, 9, 0.035), 0px 8.14815px 6.51852px rgba(90, 63, 9, 0.0274815), 0px 1.85185px 3.14815px rgba(90, 63, 9, 0.0168519)"
                    _hover={{ outline: 'none' }}
                    _focus={{ outline: 'none' }}
                    background="common.white"
                    border="1px solid #EDD1C2"
                  >
                    {/* <PopoverArrow/> */}
                    <PopoverBody padding={{ base: '8px 0px 7px 0px' }}>
                      <List listStyleType="none">
                        <ListItem
                          fontSize={{ base: '14px', md: '14px' }}
                          lineHeight={{ base: '31px', md: '31px' }}
                          color="#B7987E"
                          fontWeight="400"
                          _hover={{
                            color: '#A33A2C',
                            fontWeight: '500',
                            fontFamily: "'FedraSansStd-medium'",
                            textDecoration: 'none',
                          }}
                          fontFamily="'FedraSansStd-book'"
                          fontStyle="'normal'"
                          textAlign="left"
                        >
                          <NextLink
                            href={refineUrlDomain(
                              router.asPath.replace(
                                `/linga-bhairavi/${region}/${language}`,
                                ''
                              ),
                              'in',
                              language
                            )}
                            passHref
                            legacyBehavior
                          >
                            <Link
                              w="100%"
                              display="block"
                              pl="15px"
                              textDecor="none"
                              _hover={{ textDecoration: 'none' }}
                            >
                              India
                            </Link>
                          </NextLink>
                        </ListItem>
                        <ListItem
                          fontSize={{ base: '14px', md: '14px' }}
                          lineHeight={{ base: '31px', md: '31px' }}
                          color="#B7987E"
                          fontWeight="400"
                          _hover={{
                            color: '#A33A2C',
                            fontWeight: '500',
                            fontFamily: "'FedraSansStd-medium'",
                          }}
                          fontFamily="'FedraSansStd-book'"
                          fontStyle="'normal'"
                          textAlign="left"
                        >
                          <NextLink
                            href={refineUrlDomain(
                              router.asPath.replace(
                                `/linga-bhairavi/${region}/${language}`,
                                ''
                              ),
                              'np',
                              language
                            )}
                            passHref
                            legacyBehavior
                          >
                            <Link
                              textDecor="none"
                              w="100%"
                              display="block"
                              pl="15px"
                              _hover={{ textDecoration: 'none' }}
                            >
                              Nepal
                            </Link>
                          </NextLink>
                        </ListItem>
                        <ListItem
                          fontSize={{ base: '14px', md: '14px' }}
                          lineHeight={{ base: '31px', md: '31px' }}
                          color="#B7987E"
                          fontWeight="400"
                          _hover={{
                            color: '#A33A2C',
                            fontWeight: '500',
                            fontFamily: "'FedraSansStd-medium'",
                          }}
                          fontFamily="'FedraSansStd-book'"
                          fontStyle="'normal'"
                          textAlign="left"
                        >
                          <NextLink
                            href={refineUrlDomain(
                              router.asPath.replace(
                                `/linga-bhairavi/${region}/${language}`,
                                ''
                              ),
                              'global',
                              language
                            )}
                            passHref
                            legacyBehavior
                          >
                            <Link
                              textDecor="none"
                              w="100%"
                              display="block"
                              pl="15px"
                              _hover={{ textDecoration: 'none' }}
                            >
                              Global
                            </Link>
                          </NextLink>
                        </ListItem>
                      </List>
                      {/* </Box> */}
                    </PopoverBody>
                  </PopoverContent>
                </Popover>
              </Box>
              {/* 
              <Box
                width="36px"
                h="36px"
                rounded="full"
                textAlign="center"
                bg="#EBE9DB"
                display="grid"
                fontSize="body2"
                color="#D47A33"
                placeItems="center"
                cursor="pointer"
                mx="5px"
                pos="relative"
              >
                <Popover placement="bottom-end">
                  <PopoverTrigger>
                    <Link>
                      {topMenuData?.data?.allIsoMainMenus[1]?.icon?.url ? (
                        <LazyLoadImageComponent
                          src={topMenuData?.data?.allIsoMainMenus[2]?.icon?.url}
                          alt={
                            topMenuData?.data?.allIsoMainMenus[2]?.icon?.url ||
                            topMenuData?.data?.allIsoMainMenus[2]?.title
                          }
                          width="18px"
                          height="16px"
                        // onClick={() => {
                        //   setRSwitchVisible(!rSwitchVisible);
                        //   setLSwitchVisible(false);
                        // }}
                        />
                      ) : (
                        <Text>
                          {topMenuData?.data?.allIsoMainMenus[2]?.title}
                        </Text>
                      )}
                    </Link>
                  </PopoverTrigger>
                  <PopoverContent
                    width="83px"
                    height="auto"
                    rounded="md"
                    boxShadow="0px 20px 13px rgba(90, 63, 9, 0.035), 0px 8.14815px 6.51852px rgba(90, 63, 9, 0.0274815), 0px 1.85185px 3.14815px rgba(90, 63, 9, 0.0168519)"
                    background="common.white"
                    border="1px solid #EDD1C2"
                    _hover={{ outline: 'none' }}
                    _focus={{ outline: 'none' }}
                  >
                    
                    <PopoverBody padding='0px'> 
                      <List listStyleType="none">
                        <ListItem background='pink'
                          fontSize={{ base: '14px', md: '14px' }}
                          lineHeight={{ base: '31px', md: '31px' }}
                          color="#B7987E"
                          fontWeight="400"
                          _hover={{
                            color: '#A33A2C',
                            fontWeight: '500',
                            fontFamily: "'FedraSansStd-medium'",
                            textDecoration: 'none',
                          }}
                          fontFamily="'FedraSansStd-book'"
                          fontStyle="'normal'"
                          textAlign="left"
                        >
                          <NextLink
                            href={refineUrlDomain(router.asPath.replace(`linga-bhairavi/${region}/${language}`, ''),
                            region,
                            'en'
                          )}
                            passHref
                          >
                            <Link
                            w="100%"
                            pl='15px'
                              textDecor="none"
                              _hover={{
                                textDecoration: 'none',
                              }}
                            >
                              English
                            </Link>
                          </NextLink>
                        </ListItem>
                      </List>
                    </PopoverBody>
                  </PopoverContent>
                </Popover>
              </Box>
            */}
            </Flex>
          </Flex>
        </Box>

        <Box height="auto" overflow="auto" marginBottom="80px">
          <Flex w="100%" flexDirection="column">
            <Box w="100%" px="20px">
              {React.Children.toArray(
                headerMenuData?.allIsoMainMenus.map((navItem, index) => {
                  return (
                    <MNNavItem
                      key={nanoid()}
                      url={navItem?.url}
                      region={region}
                      language={language}
                      // bgImg={`/assets/images/lbmenuImages/pic${index + 1}.png`}
                      bgImg={bgImages[index]}
                    >
                      {navItem?.title}
                    </MNNavItem>
                  );
                })
              )}
            </Box>
          </Flex>

          <Flex
            justifyContent="space-between"
            w="100%"
            padding="30px 20px 25px"
            background="#ffffff"
            alignItems="center"
            position="fixed"
            bottom="0"
            left="0"
          >
            <Flex
              // flex="1"
              alignSelf="flex-start"
              alignItems="center"
              width="70%"
              maxW="200px"
              // width="auto"
              justifyContent="space-between"
            >
              <Box>
                <NextLink
                  href={refineUrlDomain(
                    topMenuData?.data?.allIsoMainMenus[3]?.url,
                    region,
                    language
                  )}
                  passHref
                  legacyBehavior
                >
                  <Link textDecor="none" _hover={{ textDecor: 'none' }}>
                    <Text
                      display="inline-flex"
                      fontFamily="FedraSansStd-book"
                      fontSize="15px"
                      color="#A9392D"
                      cursor="pointer"
                    >
                      <Box as="span" display="inline">
                        <Image
                          src={topMenuData?.data?.allIsoMainMenus[3].icon?.url}
                          alt={topMenuData?.data?.allIsoMainMenus[3].title}
                          w="20px"
                          height="20px"
                          mr="8px"
                          // _hover={{ content: `url(${topmenu?.iconHover?.url})` }}
                          _hover={{
                            content: `url(${topMenuData?.data?.allIsoMainMenus[3].iconHover?.url})`,
                          }}
                        />
                      </Box>
                      {topMenuData?.data?.allIsoMainMenus[3].title}
                    </Text>
                  </Link>
                </NextLink>
              </Box>
              &nbsp;
              <Box>
                <NextLink
                  href={refineUrlDomain(
                    topMenuData?.data?.allIsoMainMenus[4]?.url,
                    region,
                    language
                  )}
                  passHref
                  legacyBehavior
                >
                  <Link textDecor="none" _hover={{ textDecor: 'none' }}>
                    <Text
                      display="inline-flex"
                      fontFamily="FedraSansStd-book"
                      fontSize="15px"
                      color="#A9392D"
                      cursor="pointer"
                    >
                      <Box as="span" color="#A9392D">
                        <Image
                          src={topMenuData?.data?.allIsoMainMenus[4].icon?.url}
                          alt={topMenuData?.data?.allIsoMainMenus[4].title}
                          w="20px"
                          height="20px"
                          mr="8px"
                          // _hover={{ content: `url(${topmenu?.iconHover?.url})` }}
                          _hover={{
                            content: `url(${topMenuData?.data?.allIsoMainMenus[4].iconHover?.url})`,
                          }}
                        />
                      </Box>
                      {topMenuData?.data?.allIsoMainMenus[4].title}
                    </Text>
                  </Link>
                </NextLink>
              </Box>
            </Flex>

            <Box
              alignSelf="flex-end"
              // flex="1"
              width="40px"
              h="40px"
              rounded="full"
              textAlign="center"
              bg="#EBE9DB"
              display="grid"
              fontSize="body2"
              color="#D47A33"
              placeItems="center"
              cursor="pointer"
              mx="5px"
              mt="-10px"
            >
              <Link
                href={
                  topMenuData?.data?.allIsoMainMenus[0]?.url ||
                  'https://arpanam.sadhguru.org/cart'
                }
                target={'_blank'}
              >
                <>
                  <Image
                    src={topMenuData?.data?.allIsoMainMenus[0].icon?.url}
                    alt={topMenuData?.data?.allIsoMainMenus[0].title}
                    w="15px"
                    height="15px"
                    mt="-5px"
                    // _hover={{ content: `url(${topmenu?.iconHover?.url})` }}
                    _hover={{
                      content: `url(${topMenuData?.data?.allIsoMainMenus[0].iconHover?.url})`,
                    }}
                  />
                  {cartCookie && (
                    <Box
                      as="span"
                      bgColor="#DB3030"
                      color="white"
                      fontSize="10px"
                      fontFamily="FedraSansStd-book"
                      position="absolute"
                      right="25px"
                      top="18px"
                      borderRadius="50%"
                      padding="0 5px"
                      fontWeight="500"
                    >
                      {cartCookie}
                    </Box>
                  )}
                </>
              </Link>
            </Box>
          </Flex>
        </Box>
      </Box>
    </Box>
  );
};

export default MobileNav;
