/* Built In Imports */
import NextLink from 'next/link';
/* External Imports */
import { Link, ListItem } from '@chakra-ui/react';

/* Internal Imports */
/* Components */
import { refreshPage } from '@components/Utility/utils';
/* Services */

/**
 *Renders Mobile Menu Li Component
 * @param {Object} props
 * @returns {ReactElement} MobileMenuLI component
 */
const MobileMenuLI = props => {
  let { url, text } = props;

  const handleClick = event => {
    props.clicked();
    refreshPage(event?.target?.href);
  };

  return (
    <ListItem display="block" position="relative">
      <NextLink href={url} passHref prefetch={false} legacyBehavior>
        <Link
          className={props.className}
          onClick={handleClick}
          display="block"
          minHeight="56px"
          height="auto"
          fontFamily="'FedraSansStd-book', 'sans-serif'"
          fontWeight="500"
          fontSize="14px"
          color="#DBD4C6"
          background="#464038"
          textDecoration="none"
          padding="16px 25px"
          borderBottom="solid 1px #faf7f0"
        >
          {text}
        </Link>
      </NextLink>
      {props.children}
    </ListItem>
  );
};

export default MobileMenuLI;
