/* External Imports */
import { Box, Image } from '@chakra-ui/react';
import { useMediaQuery } from 'react-responsive';

/* Internal Imports */
/* Components */
import LazyLoadImageComponent from '@components/UI/LazyLoadImageComponent';
import MidContainer from '@Layout/MidContainer';

/**
 * Renders the SingleImage Card component
 *
 * @param {object} sectionContent - Data for section
 * @param {string} region - Region for page
 * @param {Sting} lang - Language for page
 * @param sectionContent.my
 * @param sectionContent.px
 * @param sectionContent.w
 * @param sectionContent.h
 * @param sectionContent.maxWidth
 * @param sectionContent.mx
 * @returns {ReactElement} SingleImage Card component
 */

const SingleImage = ({
  sectionContent,
  sync,
  region,
  lang,
  my,
  px,
  w,
  h,
  maxWidth,
  mx,
  tAlign,
  ...props
}) => {
  const isLessThen = useMediaQuery({ maxWidth: 560 });
  // console.log('sectionContent', tAlign, props)
  return sectionContent ? (
    <MidContainer w={props.width} mx="0">
      <Box
        textAlign="center"
        position="relative"
        mx={mx || 'auto'}
        my={my || '10px'}
        px={{ base: px || '16px', md: '0' }}
        className="image-container"
        // maxW={{ base: 302, sm: 330, lg: 470 }}
        {...props}
      >
        {sync && (
          <Image
            h={h || 'auto'}
            alt={sectionContent.image?.alt}
            title={sectionContent.image?.title}
            textAlign="center"
            loading="eager"
            // src={sectionContent.image?.url}
            src={isLessThen ? (sectionContent.mobileImage?.url != null ? sectionContent.mobileImage?.url : sectionContent.image?.url) : sectionContent.image?.url}
            w={w || 'auto'}
            maxWidth={maxWidth || '100%'}
            m={tAlign === 'center' ? '0 auto' : ''}
          />
        )}
        {!sync && (
          <LazyLoadImageComponent
            h={h || 'auto'}
            alt={sectionContent.image?.alt}
            title={sectionContent.image?.title}
            textAlign="center"
            // src={sectionContent.image?.url}
            src={isLessThen ? (sectionContent.mobileImage?.url != null ? sectionContent.mobileImage?.url : sectionContent.image?.url) : sectionContent.image?.url}
            w={w || 'auto'}
            maxWidth={maxWidth || '100%'}
            m={tAlign === 'center' ? '0 auto' : ''}
          />
        )}
      </Box>
    </MidContainer>
  ) : null;
};

export default SingleImage;
