import React from "react";
import { Box, Image } from "@chakra-ui/react";

export default function SmallListingCardRecord(record) {
  return (
    <Box
      width={{ lg: '30%', md: '100%', sm: '100%' }}
      m={{
        lg: '2rem 7px 0 7px',
        base: '1.5rem auto 0 auto',
        md: '1.5rem auto 0 auto',
      }}
      display="inline-block"
      verticalAlign="top"
    >
      <Image alt="" src={record?.image?.url} width="100%" />
      <Box
        h="20px"
        backgroundColor={'orange.50'}
        ml="10%"
        mr="10%"
        mt="-10px"
        style={{ backgroundColor: record.highlightColour.hex }}
      ></Box>
      <Box
        fontFamily="FedraSansStd-book"
        fontSize="1rem"
        lineHeight="1.5"
        textAlign="center"
        color={'black.300'}
        mt="20px"
        mb="15px"
        p="0"
      >
        {record.descriptionText}
      </Box>
    </Box>
  );
}
