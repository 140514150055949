/* Built In Imports */

/* External Imports */
import { Box, Heading, Image, Link, Text } from '@chakra-ui/react';

/* Internal Imports */

/* Services */

/**
 * Renders the Footer Social Link Items component
 *
 * @param {string} link - URL of the Social Media Item.
 * @param {string} img1 - Image for Desktop View.
 * @param {string} img2 - Image for Mobile View.
 * @param {string} meidalinks - URL for the Social Media Items through PageConfig.
 * @returns {ReactElement} Footer Social Link Items component
 */
const FooterSocialLinkItem = ({ linkurl, img1, img2 }) => (
  <Link
    href={linkurl}
    width="24px"
    height={'24px'}
    mb="30px"
    float="left"
    marginRight="21px"
    textDecoration="none"
    outline="2px solid rgb(0 0 0 / 0%)"
    outlineOffset="2px"
    color="inherit"
  >
    <Text
      as="span"
      backgroundImage={img1}
      backgroundRepeat="no-repeat"
      backgroundSize="cover"
      width="1.8rem"
      height="1.8rem"
      cursor="pointer"
      p={{ base: '0', lg: '0' }}
      display="inline-flex"
      justifyContent="space-between"
      _hover={{
        backgroundImage: img2,
        backgroundRepeat: 'no-repeat',
        backgroundSize: 'contain',
      }}
    >
      <Image src={img2} display="none !important" />
    </Text>
  </Link>
);

export const FooterSocialMediaLinks = ({
  region,
  language,
  footerMenuData,
  pc_socialmedialink,
}) => {
  const pc_facebook = pc_socialmedialink?.footer_facebook;
  const pc_twitter = pc_socialmedialink?.footer_twitter;
  const pc_linkedin = pc_socialmedialink?.footer_linkedin;
  const pc_youtube = pc_socialmedialink?.footer_youtube;
  const pc_instagram = pc_socialmedialink?.footer_instagram;

  return (
    <Box
      width="100%"
      mb={{
        base: '2rem',
        md: '1rem',
      }}
    >
      <Heading
        as="h4"
        fontSize="16px"
        fontFamily="FedraSansStd-A-medium"
        fontWeight="500"
        fontStyle="normal"
        color="#cd6727"
        mb={{
          base: '1rem',
          lg: '0.5rem',
        }}
      >
        {footerMenuData[5]?.title}
      </Heading>
      <Box width={{ xl: '100%', sm: '100%' }} marginBottom="4rem">
        {footerMenuData[5]?.submenuItems?.length > 0 &&
          footerMenuData[5]?.submenuItems?.map((socialmedia, index) => {
            let cp_url;
            switch (socialmedia.key) {
              case 'facebook':
                cp_url = pc_facebook;
                break;
              case 'twitter':
                cp_url = pc_twitter;
                break;
              case 'linkedin':
                cp_url = pc_linkedin;
                break;
              case 'youtube':
                cp_url = pc_youtube;
                break;
              case 'instagram':
                cp_url = pc_instagram;
                break;
              default:
                cp_url = undefined;
                break;
            }
            return (
              <FooterSocialLinkItem
                // linkurl={
                //   socialmedia?.key == 'instagram' && pc_instagram
                //     ? pc_instagram
                //     : socialmedia?.url
                // }
                linkurl={cp_url !== undefined ? cp_url : socialmedia?.url}
                img1={socialmedia?.icon?.url}
                img2={socialmedia?.iconHover?.url}
                key={index}
              />
            );
          })}
      </Box>
    </Box>
  );
};
