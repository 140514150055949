/* Built In Imports */
import NextLink from 'next/link';
import { useRouter } from 'next/router';
import { useEffect, useState } from 'react';

/* External Imports */
import { Box, Link } from '@chakra-ui/react';
import forEach from 'lodash/forEach';
import { useCookies } from 'react-cookie';
import { useMediaQuery } from 'react-responsive';
import { Link as HashLink } from 'react-scroll';

/* Internal Imports */
/* Components */
import PatientNoteModal from '@components/Modal/PatientNoteModal';
import LazyLoadImageComponent from '@components/UI/LazyLoadImageComponent';
import { isOpenInNewTab, refineUrlDomain } from '@components/Utility/utils';
import config from '@config';

/* Services */

/**
 * Returns the Simple Button.
 *
 * @param style.style
 * @param {object} style - Style for button.
 * @param {string} bType - Button Type.
 * @param {string} region - Current User Country.
 * @param {string} language - Current User Language.
 * @param style.bType
 * @param style.region
 * @param style.lang
 * @param style.clickHandler
 * @param style.padding
 * @param style.textAlign
 * @param style.width
 * @param style.mb
 * @param style.mt
 * @param style.fontstyle
 * @param style.fontbold
 * @param style.extraDomains
 * @param style.disabled
 * @param style.pageName
 * @param style.height
 * @returns {ReactElement} SimpleButton component.
 */
export default function SimpleButton({
  style,
  bType,
  region,
  lang,
  clickHandler,
  height,
  padding,
  textAlign,
  width,
  mb,
  mt,
  mx,
  fontstyle,
  fontbold,
  pageName,
  pageConfig,
  disabled = false,
  icon,
  disableButton,
  scrollId,
  hashScroll,
  targetwindow,
  ...props
}) {
  const pName = pageConfig?.pageName;
  const router = useRouter();
  const [currentUrl, setCurrentUrl] = useState('');
  const cookieParams = [
    'utm_source',
    'utm_medium',
    'utm_campaign',
    'utm_content',
    'utm_term',
  ];
  const [cookies, setCookie] = useCookies(cookieParams);
  const isMobile = useMediaQuery({ maxWidth: 767 });
  const [shouldHide, setShouldHide] = useState(false);

  useEffect(() => {
    if (pName === 'freeyogaday' && disableButton === true) {
      setShouldHide(true);
    } else if (pName === 'freeyogaday' && isMobile === true) {
      setShouldHide(isMobile);
    }

    if (!router.isReady) return;
    setCurrentUrl(refineUrlDomain(style?.linkUrl, region, lang));
    // setTimeout(function () {
    forEach(cookieParams, function (cookie) {
      if (router.query[cookie]) {
        setCookie(cookie, router.query[cookie], {
          path: '/',
          maxAge: 3600 * 24, // Expires after 1year
          sameSite: true,
          domain: config.COOKIE_DOMAIN,
          secure: true,
        });
      }
    });
    // }, 0);
  }, [router.isReady, style?.linkUrl, isMobile, pageName]);

  const RenderButton = ({ style }) => {
    const buttonStyle = {
      Orange:
        currentUrl.startsWith('ieo-enroll-section') &&
          !currentUrl.startsWith('#freeyogaregistration') ? (
          <HashLink
            to={currentUrl}
            spy={true}
            //smooth={true}
            offset={0}
          //duration={1200}
          // delay={100}
          >
            <Box
              className="orange-button"
              position="relative"
              zIndex="99"
              mb={mb || '30px'}
              display={icon ? 'flex' : 'block'}
              justifyContent="center"
              alignItems="center"
              fontWeight={fontbold || '500'}
              fontFamily={fontstyle || 'FedraSansStd-medium'}
              fontSize={'18px'}
              bgColor={disabled ? 'rgb(207 69 32 / 41%)' : '#cf4520'}
              h={'auto'}
              minH="50px"
              cursor={disabled ? 'not-allowed' : 'pointer'}
              textAlign={textAlign || 'center'}
              padding={padding || '14px 29px'}
              borderRadius="5px"
              color="#faf7f0"
              textDecoration="none"
              width={width || 'auto'}
              _hover={{
                bg: disabled ? 'rgb(207 69 32 / 41%)' : '#000054',
              }}
              onClick={clickHandler}
              target={style?.openInNewTab == true ? '_blank' : isOpenInNewTab(currentUrl) || targetwindow ? '_blank' : ''}
              {...props}
            >
              {icon && (
                <LazyLoadImageComponent
                  w="32px"
                  h="100%"
                  mr="10px"
                  mt="-5px"
                  src={icon}
                />
              )}
              {style?.buttonText}
            </Box>
          </HashLink>
        ) : (
          <NextLink href={disabled ? '#' : currentUrl} passHref legacyBehavior>
            <Link
              className="orange-button"
              mb={mb || '30px'}
              display={icon ? 'flex' : 'block'}
              justifyContent="center"
              alignItems="center"
              fontWeight={fontbold || '500'}
              fontFamily={fontstyle || 'FedraSansStd-medium'}
              fontSize={'18px'}
              bgColor={disabled ? 'rgb(207 69 32 / 41%)' : '#cf4520'}
              h={'auto'}
              minH="50px"
              cursor={disabled ? 'not-allowed' : 'pointer'}
              textAlign={textAlign || 'center'}
              padding={padding || '14px 29px'}
              borderRadius="5px"
              color="#faf7f0"
              textDecoration="none"
              width={width || 'auto'}
              _hover={{
                bg: disabled ? 'rgb(207 69 32 / 41%)' : '#000054',
              }}
              onClick={clickHandler}
              target={style?.openInNewTab == true ? '_blank' : isOpenInNewTab(currentUrl) || targetwindow ? '_blank' : ''}
              {...props}
            >
              {icon && (
                <LazyLoadImageComponent
                  w="32px"
                  h="100%"
                  mr="10px"
                  mt="-5px"
                  src={icon}
                />
              )}
              {style?.buttonText}
            </Link>
          </NextLink>
        ),

      'White with Orange outline': (
        <NextLink href={disabled ? '#' : currentUrl} passHref legacyBehavior>
          <Link
            display="inline-block"
            mb={mb || '20px'}
            fontFamily="FedraSansStd-medium"
            borderRadius="5px"
            bgColor="transparent"
            border="solid 1px #cf4520"
            color="#cf4520"
            fontSize="18px"
            padding="14px 29px"
            _hover={{
              textDecoration: 'none',
              bgColor: '#cf4520',
              color: 'white',
            }}
            textAlign={textAlign || 'left'}
            width={'auto'}
            h="auto"
            minH="50px"
            // minW="200px"
            m="0 auto"
            onClick={clickHandler}
            target={style?.openInNewTab == true ? '_blank' : isOpenInNewTab(currentUrl) ? '_blank' : ''}
            {...props}
          >
            {style?.buttonText}
          </Link>
        </NextLink>
      ),
      'Program Finder Button': (
        <NextLink href={disabled ? '#' : currentUrl} passHref legacyBehavior>
          <Link
            fontFamily="FedraSansStd-medium"
            borderRadius="21.4px"
            border="none"
            padding="10px 10px"
            fontWeight="350"
            lineHeight="17.5px"
            fontSize="14px"
            _hover={{
              textDecoration: 'none',
              bgColor: '#DCD5C3',
              color: '#676055',
            }}
            textAlign={textAlign || 'left'}
            width={'auto'}
            h="auto"
            minH="45px"
            // minW="200px"
            m="0 auto"
            onClick={clickHandler}
            target={style?.openInNewTab == true ? '_blank' : isOpenInNewTab(currentUrl) ? '_blank' : ''}
            {...props}
          >
            {style?.buttonText}
          </Link>
        </NextLink>
      ),
      Blue: (
        <NextLink href={disabled ? '#' : currentUrl} passHref legacyBehavior>
          <Link
            width="auto"
            minW="200px"
            height="48px"
            borderRadius="4px"
            fontFamily="'FedraSansStd-book', sans-serif"
            fontSize="16px"
            color="#ffffff"
            lineHeight="48px"
            textAlign="center"
            marginTop="30px"
            textDecoration="none"
            colorScheme={'#293B7D'}
            background="#293B7D"
            _hover={{ bg: '#293B7D', border: 'none' }}
            _active={{ bg: '#293B7D', border: 'none' }}
            _focus={{ bg: '#293B7D', border: 'none' }}
            onClick={clickHandler}
            target={style?.openInNewTab == true ? '_blank' : isOpenInNewTab(currentUrl) ? '_blank' : ''}
          >
            {style?.buttonText}
          </Link>
        </NextLink>
      ),
      Yellow: (
        <NextLink href={disabled ? '#' : currentUrl} passHref legacyBehavior>
          <Link
            width="100%"
            // minW="200px"
            // maxW="188px"
            height="46px"
            fontFamily="'FedraSansStd-medium', sans-serif"
            fontSize="16px"
            lineHeight="46px"
            background="#EAB646"
            borderRadius="5px"
            color="#28231E"
            fontWeight={'300'}
            textAlign="center"
            marginTop="20px"
            p={5}
            pt="0"
            onClick={clickHandler}
            target={style?.openInNewTab == true ? '_blank' : isOpenInNewTab(currentUrl) ? '_blank' : ''}
            _hover={{
              background: '#F5E3B5',
              border: '1px solid #F5E3B5',
              color: '#000000',
            }}
            _focus={{
              background: '#F5E3B5',
              border: '1px solid #F5E3B5',
              color: '#000000',
            }}
            _active={{
              background: '#F5E3B5',
              border: '1px solid #F5E3B5',
              color: '#000000',
            }}
            _visited={{
              background: '#F5E3B5',
              border: '1px solid #F5E3B5',
              color: '#000000',
            }}
            {...props}
          >
            {style?.buttonText}
          </Link>
        </NextLink>
      ),
      'yoga-sandhya-yellow': (
        <NextLink href={disabled ? '#' : currentUrl} passHref legacyBehavior>
          <Link
            // minW="200px"
            // maxW="188px"
            width="auto"
            minW={width ? width : pName === "hytt" ? "200px" : { base: '188px', md: '188px' }}
            // height={height || '46px'}
            height={height ? height : pName === "hytt" ? "58px" : "46px"}
            fontStyle="normal"
            fontWeight="500"
            fontFamily="'FedraSansStd-medium', sans-serif"
            fontSize={pName === "hytt" ? "20px" : "16px"}
            lineHeight="46px"
            background={style?.linkUrl != '' ? '#FFAE27' : '#909090'}
            borderRadius="5px"
            color={pName === "hytt" ? "black" : style?.linkUrl != '' ? '#000' : '#000'}
            // fontWeight={'300'}
            textAlign="center"
            marginTop="20px"
            p={padding || 5}
            // pt={padding ? 'auto' : '0'}
            pt={pName === "hytt" ? '1' : padding ? 'auto' : '0'}
            cursor={style?.linkUrl == '' ? 'not-allowed' : 'pointer'}
            _hover={{
              textDecoration: 'none',
              bgColor: style?.linkUrl != '' ? '#cf4520' : '#909090',
              color: style?.linkUrl != '' ? '#fff' : '#000',
            }}
            _focus={{
              background: '#cf4520',
              border: '1px solid #F5E3B5',
              color: '#000000',
            }}
            _active={{
              background: '#cf4520',
              border: '1px solid #F5E3B5',
              color: '#000000',
            }}
            onClick={clickHandler}
            target={style?.openInNewTab == true ? '_blank' : isOpenInNewTab(currentUrl) ? '_blank' : ''}
            {...props}
          >
            {style?.buttonText}
          </Link>
        </NextLink>
      ),
    };
    return buttonStyle[style?.buttonStyle] || null;
  };

  return (
    <>
      {shouldHide || pName === 'freeyogaday' && disableButton === true ? null : (
        <Box
          boxSizing="borderBox"
          display={bType ? 'flex' : 'flex'}
          alignItems="center"
          as={'button'}
          color="#faf7f0"
          width={'auto'}
          h={height || 'auto'}
          minH={height || '50px'}
          borderRadius="5px"
          fontSize={'18px'}
          textAlign="center"
          justifyContent={bType ? 'center' : 'center'}
          cursor="default"
          mb={mb || '30px'}
          mt={mt ? mt : '12px'}
          mx={mx}
          className={disableButton}
        >
          {!bType && currentUrl ? <RenderButton style={style} /> : null}
          {bType && PatientNoteModal(style, currentUrl)}
        </Box>
      )}
    </>
  );
}
