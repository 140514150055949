/* Built In Imports */
import NextLink from 'next/link';
/* External Imports */
import { Box, Link, ListItem, Text } from '@chakra-ui/react';

/* Internal Imports */
/* Components */
import { refreshPage } from '@components/Utility/utils';
/* Services */

/**
 * Renders LI Layout component
 * @param {Object} props
 * @returns {ReactElement} LILayout component
 */
const LILayout = props => {
  let { url, text, textFormat } = props;
  // console.log(text, url);

  return (
    <ListItem
      display="inline-block"
      position="relative"
      paddingTop="17px"
      paddingBottom="18px"
      borderBottom="7px solid transparent"
    >
      <Box
        className={props.className}
        _focus={{ boxShadow: 'none' }}
        onClick={e => refreshPage(e?.target?.href)}
      >
        {url?.endsWith('/') ? (
          <Link
            href={url}
            className={props.className}
            _focus={{ boxShadow: 'none' }}
            fontWeight={textFormat}
            fontStyle={textFormat}
            _hover={{
              color: '#cf4520',
            }}
            display="block"
            fontFamily="'FedraSansStd-medium', sans-serif"
            fontSize="16px"
            lineHeight="30px"
            color="#dbd4c6"
            textDecoration="none"
            padding="0px 11px"
            borderRight="solid 1px #a39d93"
            margin="6px 0"
          >
            {text}
          </Link>
        ) : url == '#' ? (
          <>
            <Text
              fontFamily="'FedraSansStd-book', sans-serif"
              fontSize="14px"
              fontStyle={textFormat === 'italic' ? textFormat : ''}
              lineHeight="30px"
              color="#3c3c3c"
              padding="0px 11px"
              margin="6px 0"
            >
              {text}
            </Text>
          </>
        ) : (
          <NextLink
            href={url}
            prefetch={false}
            passHref
            fontWeight={textFormat === 'bold' ? 'bold' : '200'}
            fontStyle={textFormat === 'italic' ? textFormat : ''}
            legacyBehavior
          >
            <Link
              display="block"
              fontFamily="'FedraSansStd-medium', sans-serif"
              fontSize="16px"
              fontStyle={textFormat === 'italic' ? textFormat : ''}
              lineHeight="30px"
              color="#dbd4c6"
              textDecoration="none"
              padding="0px 11px"
              borderRight="solid 1px #a39d93"
              margin="6px 0"
              _hover={{ textDecoration: 'none', color: '#cf4520 !important' }}
            >
              {text}
            </Link>
          </NextLink>
        )}
      </Box>
      {props.children}
    </ListItem>
  );
};

export default LILayout;
