/* Built In Imports */
import NextLink from 'next/link';
import { useRouter } from 'next/router';
import { useContext, useEffect, useRef, useState } from 'react';

/* External Imports */
import {
  Box,
  Flex,
  Image,
  Link,
  List,
  ListItem,
  Popover,
  PopoverArrow,
  PopoverBody,
  PopoverContent,
  PopoverTrigger,
  Text,
} from '@chakra-ui/react';
import { isNaN } from 'lodash';
import { nanoid } from 'nanoid';
import { TbChevronDown, TbChevronUp } from 'react-icons/tb';

/* Internal Imports */
/* Components */
import { getCookie } from '@components/Utility/utils';
import config from '@config';
import { refineUrlDomain } from '@lb/components/Utility/utils';
import { AuthContext } from '@store/auth-context';

/* Styles */
/* Services */

/**
 *
 * @param {object} topMenuData
 * @param {string} region
 * @param {string} language
 * @returns
 */

const TopMenu = ({ topMenuData, region, language, pageData }) => {
  /* states we have not used
  const [IsShown, setIsShown] = useState(false);
  const [isEditing, setIsEditing] = useBoolean();
  const [lSwitchVisible, setLSwitchVisible] = useState(false);
  const [rSwitchVisible, setRSwitchVisible] = useState(false);
  const { isOpen, onToggle, onClose } = useDisclosure();
*/
  const router = useRouter();
  const authCtx = useContext(AuthContext);
  const { userName, userImage } = authCtx;
  const [isLoggedIn, setIsLoggedIn] = useState(false);
  // const [hasHovered, setHasHovered] = useState(false);
  // const [hasHoveredDS, setHasHoveredDS] = useState(false);
  // const [hasHoveredSignOut, setHasHoveredSignOut] = useState(false);
  // const [hasHoveredLogOut, setHasHoveredLogOut] = useState(false);
  region = authCtx?.region || region;
  language = authCtx?.lang || language;
  // const [profile, setProfile] = useState(false);
  const topMenu = topMenuData?.data?.allIsoMainMenus[7];
  const loginURL = `${config.FACADE_BASE_PATH}/login?request_url=${config.BASE_PATH}${router.asPath}&action=0`;
  const cartCookie = getCookie(
    region === 'np' ? 'numCartItemsS2' : 'numCartItemsS1'
  );

  const logoutHandler = () => {
    authCtx.logout();
  };

  useEffect(() => {
    setIsLoggedIn(authCtx.isLoggedIn);
  }, [authCtx.isLoggedIn]);
  const initRef = useRef();

  // const IconsListItem = ({ children, borderRight, topmenu, topMenuData }) => {
  //   return (
  //     <>
  //       <Popover placement="bottom-end" trigger="hover">
  //         <PopoverTrigger>
  //           <ListItem
  //             padding="0 20px"
  //             borderRight={borderRight ? '0px' : '1px solid #A9392D'}
  //             display="inline-flex"
  //             cursor="pointer"
  //             userSelect="none"
  //             pos="relative"
  //           >
  //             <Image
  //               src={topmenu?.icon?.url}
  //               alt={topmenu?.icon?.title || topmenu?.title || ''}
  //               w="18px"
  //               height="18px"
  //               _hover={{
  //                 content: `url(${topmenu.iconHover?.url})`,
  //                 w: '18px',
  //                 height: '18px',
  //               }}
  //             />
  //             {!topmenu?.icon?.url && (
  //               <Text
  //                 color="#A9392D"
  //                 fontWeight="400"
  //                 fontStyle="normal"
  //                 lineHeight="15px"
  //                 fontFamily="FedraSansStd-book"
  //                 fontSize="12px"
  //               >
  //                 {topmenu?.title}
  //               </Text>
  //             )}
  //             {children}
  //           </ListItem>
  //         </PopoverTrigger>
  //         <PopoverContent
  //           _hover={{ outline: 'none' }}
  //           _focus={{ outline: 'none' }}
  //           width="83px"
  //           height="auto"
  //           rounded="md"
  //           boxShadow="0px 20px 13px rgba(90, 63, 9, 0.035), 0px 8.14815px 6.51852px rgba(90, 63, 9, 0.0274815), 0px 1.85185px 3.14815px rgba(90, 63, 9, 0.0168519)"
  //           background="common.white"
  //           border="1px solid #EDD1C2"
  //         >
  //           <PopoverBody padding="0px">
  //             <List listStyleType="none">
  //               <ListItem
  //                 fontSize={{ base: '14px', md: '14px' }}
  //                 lineHeight={{ base: '31px', md: '31px' }}
  //                 color="#B7987E"
  //                 fontWeight="400"
  //                 _hover={{
  //                   color: '#A33A2C',
  //                   fontWeight: '500',
  //                   fontFamily: "'FedraSansStd-medium'",
  //                   textDecoration: 'none',
  //                 }}
  //                 fontFamily="'FedraSansStd-book'"
  //                 fontStyle="'normal'"
  //                 textAlign="left"
  //               >
  //                 <NextLink
  //                   href={refineUrlDomain(
  //                     router.asPath.replace(
  //                       `linga-bhairavi/${region}/${language}`,
  //                       ''
  //                     ),
  //                     region,
  //                     'en'
  //                   )}
  //                   passHref
  //                 >
  //                   <Link
  //                     w="100%"
  //                     pl="15"
  //                     display="block"
  //                     _hover={{ textDecoration: 'none' }}
  //                   >
  //                     English
  //                   </Link>
  //                 </NextLink>
  //               </ListItem>
  //             </List>
  //           </PopoverBody>
  //         </PopoverContent>
  //       </Popover>
  //     </>
  //   );
  // };

  const RegionListItem = ({ children, borderRight, topmenu }) => {
    return (
      <>
        <Popover placement="bottom-end" trigger="hover">
          <PopoverTrigger>
            <ListItem
              padding="0 20px"
              borderRight={borderRight ? '0px' : '1px solid #A9392D'}
              display="inline-flex"
              cursor="pointer"
              userSelect="none"
              pos="relative"
            >
              <Image
                src={topmenu?.icon?.url}
                alt={''}
                _hover={{ content: `url(${topmenu.iconHover?.url})` }}
                w="18px"
                height="18px"
                loading='eager'
              />
              {!topmenu?.icon?.url && (
                <Text
                  color="#A9392D"
                  fontWeight="400"
                  fontStyle="normal"
                  lineHeight="15px"
                  fontFamily="FedraSansStd-book"
                  fontSize="12px"
                >
                  {topmenu?.title}
                </Text>
              )}
              {children}
            </ListItem>
          </PopoverTrigger>

          <PopoverContent
            width="83px"
            marginLeft={{ xl: '800px', md: '550px' }}
            height="auto"
            // padding="8px 0 7px 15px"
            outline="none"
            _hover={{ outline: 'none' }}
            _focus={{ outline: 'none' }}
            rounded="md"
            boxShadow="0px 20px 13px rgba(90, 63, 9, 0.035), 0px 8.14815px 6.51852px rgba(90, 63, 9, 0.0274815), 0px 1.85185px 3.14815px rgba(90, 63, 9, 0.0168519)"
            background="common.white"
            border="1px solid #EDD1C2"
          >
            <PopoverBody padding={{ md: '8px 0px 7px 0px' }}>
              <List listStyleType="none">
                <ListItem
                  fontSize={{ base: '14px', md: '14px' }}
                  lineHeight={{ base: '31px', md: '31px' }}
                  color="#B7987E"
                  fontWeight="400"
                  _hover={{
                    color: '#A33A2C',
                    fontWeight: '500',
                    fontFamily: "'FedraSansStd-medium'",
                    textDecoration: 'none',
                  }}
                  fontFamily="'FedraSansStd-book'"
                  fontStyle="'normal'"
                  textAlign="left"
                >
                  <NextLink
                    href={refineUrlDomain(
                      router.asPath.replace(
                        `linga-bhairavi/${region}/${language}`,
                        ''
                      ),
                      'in',
                      language
                    )}
                    passHref
                    legacyBehavior
                  >
                    <Link
                      w="100%"
                      pl="15"
                      display="block"
                      _hover={{ textDecoration: 'none' }}
                    >
                      India
                    </Link>
                  </NextLink>
                </ListItem>
                <ListItem
                  fontSize={{ base: '14px', md: '14px' }}
                  lineHeight={{ base: '31px', md: '31px' }}
                  color="#B7987E"
                  fontWeight="400"
                  _hover={{
                    color: '#A33A2C',
                    fontWeight: '500',
                    fontFamily: "'FedraSansStd-medium'",
                  }}
                  fontFamily="'FedraSansStd-book'"
                  fontStyle="'normal'"
                  textAlign="left"
                >
                  <NextLink
                    href={refineUrlDomain(
                      router.asPath.replace(
                        `linga-bhairavi/${region}/${language}`,
                        ''
                      ),
                      'np',
                      language
                    )}
                    passHref
                    legacyBehavior
                  >
                    <Link
                      w="100%"
                      pl="15"
                      textDecor="none"
                      _hover={{ textDecoration: 'none' }}
                      display="block"
                    >
                      Nepal
                    </Link>
                  </NextLink>
                </ListItem>
                <ListItem
                  fontSize={{ base: '14px', md: '14px' }}
                  lineHeight={{ base: '31px', md: '31px' }}
                  color="#B7987E"
                  fontWeight="400"
                  _hover={{
                    color: '#A33A2C',
                    fontWeight: '500',
                    fontFamily: "'FedraSansStd-medium'",
                  }}
                  fontFamily="'FedraSansStd-book'"
                  fontStyle="'normal'"
                  textAlign="left"
                >
                  <NextLink
                    href={refineUrlDomain(
                      router.asPath.replace(
                        `linga-bhairavi/${region}/${language}`,
                        ''
                      ),
                      'global',
                      language
                    )}
                    passHref
                    legacyBehavior
                  >
                    <Link
                      w="100%"
                      pl="15"
                      textDecor="none"
                      _hover={{ textDecoration: 'none' }}
                      display="block"
                    >
                      Global
                    </Link>
                  </NextLink>
                </ListItem>
              </List>
            </PopoverBody>
          </PopoverContent>
        </Popover>
      </>
    );
  };

  const ListHasTextImg = ({ children, borderRight, topmenu }) => {
    return (
      <List>
        <ListItem
          role="nav-item"
          paddingLeft="15px"
          display="inline-flex"
          cursor="pointer"
          borderRight={borderRight ? '0px' : '1px solid #A9392D'}
        >
          <Box>
            <NextLink
              href={refineUrlDomain(topmenu.url, region, language)}
              passHref
              legacyBehavior
            >
              <Link
                _hover={{
                  textDecoration: 'none',
                }}
                target={
                  topmenu.url?.indexOf('http') > -1 &&
                  topmenu.url?.indexOf(config.cdnPath) < 0
                    ? '_blank'
                    : ''
                }
                // _active={() => console.log(topmenu)}
                className="menuLinktop"
                aria-label={`link to ${topmenu?.title} page`}
              >
                <Image
                  marginTop="-3px"
                  src={topmenu?.icon?.url}
                  alt={''}
                  _hover={{ content: `url(${topmenu.iconHover?.url})` }}
                  loading="eager"
                />
                <style global jsx>
                  {`
                    .menuLinktop:hover image:hover {
                      content: url(${topmenu.iconHover?.url});
                    }
                  `}
                </style>
                <Text
                  color="#A9392D"
                  fontWeight="400"
                  fontStyle="normal"
                  lineHeight="15px"
                  fontFamily="FedraSansStd-book"
                  fontSize="12px"
                  mx="25px"
                  marginTop={{ md: '-19px' }}
                  _hover={{
                    fontSize: '12px',
                    fontWeight: '500',
                    color: '#A9392D',
                  }}
                >
                  {topmenu?.title}
                </Text>
              </Link>
            </NextLink>
          </Box>

          {children}
        </ListItem>
      </List>
    );
  };

  return (
    <Flex
      borderBottom="1px solid rgba(0, 0, 0, 0.1)"
      w="100%"
      alignSelf="center"
      justifyContent="center"
      pb="15px"
      width="100%"
    >
      <List
        display="flex"
        alignItems="center"
        listStyleType="none"
        flexWrap="wrap"
        width="100%"
        justifyContent="flex-end"
      >
        {topMenuData?.data?.allIsoMainMenus?.map((topmenu, key) => {
          if (topmenu.key === 'cart') {
            if (pageData?.pageConfig?.breadcrumb_hide) {
              return;
            }
            return (
              <ListItem
                key={nanoid()}
                padding="0 20px"
                borderRight={'1px solid #A9392D'}
                display="inline-flex"
                cursor="pointer"
                userSelect="none"
                pos="relative"
                alignItems="center"
                //  key={key}
                maxH="17px"
                minH="17px"
                margin={0}
              >
                <Link
                  display={'inline-flex'}
                  alignItems="center"
                  justifyContent="center"
                  href={topmenu?.url || 'https://arpanam.sadhguru.org/cart'}
                  target={'_blank'}
                  p="0"
                  border="none"
                  aria-label={`link to ${topmenu?.title} page`}
                  margin="0"
                >
                  <>
                    <Image
                      src={topmenu?.icon?.url}
                      alt={''}
                      w="15px"
                      height="16px"
                      maxH="17px"
                      //  verticalAlign="baseline"
                      _hover={{
                        content: `url(${topmenu?.iconHover?.url})`,
                        width: '15px',
                        height: '16px',
                        //minH:"17px",
                        // pl: {md:'2px'},
                        backgroundSize: 'cover',
                      }}
                      objectFit="cover"
                      loading="eager"
                    />
                    {!isNaN(cartCookie) && cartCookie > 0 && (
                      <Box
                        as="span"
                        bgColor="#DB3030"
                        color="white"
                        fontSize="10px"
                        fontFamily="FedraSansStd-book"
                        position="absolute"
                        left="28px"
                        top="-6px"
                        borderRadius="50%"
                        padding="0 5px"
                        fontWeight="500"
                      >
                        {cartCookie}
                      </Box>
                    )}
                  </>
                </Link>
              </ListItem>
            );
          }
          //Commented language switcher since only one language is available
          //else if (topmenu.key === 'language-switcher') {
          // return <IconsListItem topmenu={topmenu} key={key} />;
          //}
          else if (topmenu.key === 'region-switcher') {
            return <RegionListItem topmenu={topmenu} key={nanoid()} />;
          } else if (topmenu.key == 'login') {
            return (
              <>
                <ListItem
                  key={nanoid()}
                  cursor="pointer"
                  borderRight={true}
                  padding="0 20px"
                  display="inline-flex"
                  userSelect="none"
                >
                  {!isLoggedIn && (
                    <>
                      <Box
                        mr="10px"
                        // onClick={() => setProfile(!profile)}
                        position="relative"
                      >
                        <Text
                          fontSize="body3"
                          fontFamily="FedraSansStd-book"
                          color="#A9392D"
                          lineHeight="15px"
                          fontWeight="600"
                          mb="3px"
                          textAlign="right"
                        >
                          <Link
                            href={loginURL}
                            _hover={{ textDecoration: 'none' }}
                          >
                            Login
                          </Link>
                        </Text>
                        <Text
                          fontSize="8px"
                          fontFamily="FedraSansStd-book"
                          color="#D09700"
                          fontStyle="italic"
                          lineHeight="10px"
                          fontWeight="400"
                          textAlign="right"
                        >
                          Track your ritual
                        </Text>
                      </Box>
                      <Flex
                        height="100%"
                        width="auto"
                        alignItems="center"
                        justifyContent="center"
                        cursor="pointer"
                      >
                        <Image
                          src={topmenu?.icon?.url}
                          alt={''}
                          width="30px"
                          height="30px"
                          mr="5px"
                          rounded="full"
                          cursor="pointer"
                          loading="eager"
                        />
                      </Flex>
                    </>
                  )}

                  {isLoggedIn && (
                    <>
                      <Popover
                        // trigger="hover"
                        isLazy
                        placement="bottom-end"
                        initialFocusRef={initRef}
                      >
                        {({ isOpen }) => (
                          <>
                            <Flex>
                              <Box
                                mr="10px"
                                // onClick={() => setProfile(!profile)}
                                position="relative"
                              >
                                <Text
                                  fontSize="body3"
                                  fontFamily="FedraSansStd-book"
                                  color="#A9392D"
                                  lineHeight="15px"
                                  textAlign="right"
                                  fontWeight="600"
                                  mb="3px"
                                >
                                  {userName}
                                </Text>
                                {/* {!isOpen && ( */}
                                <Text
                                  fontSize="8px"
                                  fontFamily="FedraSansStd-book"
                                  color="#D09700"
                                  fontStyle="italic"
                                  lineHeight="10px"
                                  fontWeight="400"
                                  textAlign="right"
                                >
                                  Profile, Settings, Rituals
                                </Text>
                                {/* )} */}
                              </Box>
                              <PopoverTrigger>
                                <Flex
                                  height="100%"
                                  width="auto"
                                  alignItems="center"
                                  justifyContent="center"
                                  cursor="pointer"
                                  // onClick={() => setProfile(!profile)}
                                >
                                  <Image
                                    src={userImage || topmenu?.icon?.url}
                                    width="30px"
                                    height="30px"
                                    alt=""
                                    mr="5px"
                                    rounded="full"
                                    cursor="pointer"
                                    loading="eager"
                                  />
                                  <Box>
                                    {!isOpen && (
                                      <TbChevronDown
                                        style={{ color: '#DB3030' }}
                                        fontWeight="700"
                                        //  onClick={event => setProfile(false)}
                                      />
                                    )}
                                  </Box>
                                  <Box>
                                    {isOpen && (
                                      <TbChevronUp
                                        style={{ color: '#DB3030' }}
                                        fontWeight="700"
                                        // onClick={event => setProfile(true)}
                                      />
                                    )}
                                  </Box>
                                </Flex>
                              </PopoverTrigger>
                            </Flex>

                            <PopoverContent
                              background="#FFFFFF"
                              border="1px solid #EDD1C2"
                              boxShadow="0px 20px 13px rgba(90, 63, 9, 0.035), 0px 8.14815px 6.51852px rgba(90, 63, 9, 0.0274815), 0px 1.85185px 3.14815px rgba(90, 63, 9, 0.0168519)"
                              borderRadius="9px"
                              outline="none"
                              _hover={{ outline: 'none' }}
                              _focus={{ outline: 'none' }}
                              width="149px"
                              transition="all 0.1s linear"
                              height="auto"
                              zIndex="auto"
                              // padding="12px"
                            >
                              <PopoverArrow
                                marginLeft={{ md: '-10px' }}
                                bg="#EDD1C2"
                                zIndex="-5"
                                transition="all 0.4s linear"
                              />
                              <PopoverBody padding="0px">
                                <Box>
                                  <List>
                                    <ListItem
                                      pl="5px"
                                      fontSize={{ base: '14px', md: '14px' }}
                                      lineHeight={{ base: '31px', md: '31px' }}
                                      color="#B7987E"
                                      fontWeight="400"
                                      _hover={{
                                        color: '#A33A2C',
                                        fontWeight: '500',
                                        fontFamily: "'FedraSansStd-medium'",
                                      }}
                                      fontFamily="FedraSansStd-book"
                                      fontStyle="'normal'"
                                      textAlign="left"
                                    >
                                      <Flex>
                                        <Link
                                          ml="8px"
                                          textDecor="none"
                                          _hover={{
                                            color: '#A33A2C',
                                            border: '0',
                                          }}
                                          // w="13px"
                                          // h="13px"
                                          target="_blank"
                                          aria-label={`link to ${topMenu?.submenuItems[0]?.title} page`}
                                          href={
                                            topMenu?.submenuItems[0]?.url ||
                                            'https://arpanam.sadhguru.org/account'
                                          }
                                        >
                                          <Image
                                            alt="view profile"
                                            w="13px"
                                            h="13px"
                                            mr="8px"
                                            src={
                                              topMenu?.submenuItems[0]?.icon
                                                ?.url
                                            }
                                            _hover={{
                                              content: `url(${topMenu?.submenuItems[0]?.iconHover?.url})`,
                                            }}
                                            loading='eager'
                                          />
                                          &nbsp;
                                          {topMenu?.submenuItems[0]?.title}
                                        </Link>
                                      </Flex>
                                      {/* )  */}
                                    </ListItem>
                                    <ListItem
                                      pl="5px"
                                      fontSize={{ base: '14px', md: '14px' }}
                                      lineHeight={{ base: '31px', md: '31px' }}
                                      color="#B7987E"
                                      fontWeight="400"
                                      _hover={{
                                        color: '#A33A2C',
                                        fontWeight: '500',
                                        fontFamily: "'FedraSansStd-medium'",
                                      }}
                                      fontFamily="'FedraSansStd-book'"
                                      fontStyle="'normal'"
                                      textAlign="left"
                                    >
                                      <Flex>
                                        <Link
                                          textDecor="none"
                                          _hover={{
                                            color: '#A33A2C',
                                            border: '0',
                                          }}
                                          onClick={logoutHandler}
                                          ml="8px"
                                          aria-label={`link ${topMenu?.submenuItems[1]?.title} page`}
                                        >
                                          <Image
                                            // alt={
                                            //   topMenu?.submenuItems[1]?.title
                                            // }
                                            alt="sign out"
                                            src={
                                              topMenu?.submenuItems[1]?.icon
                                                ?.url
                                            }
                                            _hover={{
                                              content: `url(${topMenu?.submenuItems[1]?.iconHover?.url})`,
                                            }}
                                            w="13px"
                                            h="13px"
                                            mr="10px"
                                            loading='eager'
                                          />
                                          {topMenu?.submenuItems[1]?.title}
                                        </Link>
                                      </Flex>
                                    </ListItem>
                                  </List>
                                </Box>
                              </PopoverBody>
                            </PopoverContent>
                          </>
                        )}
                      </Popover>
                    </>
                  )}
                </ListItem>
              </>
            );
          } else if (key > 2 && key < 5) {
            return <ListHasTextImg topmenu={topmenu} key={nanoid()} />;
          } else {
            null;
          }
        })}
      </List>
    </Flex>
  );
};

export default TopMenu;
