/* External Imports */
import {
  Box,
  Button,
  Container,
  Grid,
  Image,
  Link,
  Text
} from '@chakra-ui/react';
import { render } from 'datocms-structured-text-to-html-string';
import { nanoid } from 'nanoid';
import config from '@config';

/* Internal Imports */
import StructuredTextParser from '@components/Utility/ArticleParser/StructuredTextParser';

/**
 *
 * @param {Object} popUpData
 * @returns
 */
export const SubFooterList = ({ popUpData, getDevice }) => {
  const btnColorArr = ['#3F6635', '#363284', '#E56B14', '#8F1F1E'];
  const mobileBgArr = ['#2D3A82', '#FF5700', '#9C0009', '#D9A73D'];

  const MsrDesktopBottom = () => {
    return getDevice === 'msr-desktop' &&
      popUpData &&
      popUpData?.['msr-desktop-bottom'] ? (
      <Box
        // backgroundImage="url(https://images.sadhguru.org/mahashivratri/wp-content/themes/isha-mahashivarathri-2017/img/consecration-img.jpg)"
        backgroundImage={`url('${config.imgPath}/d/46272/1669974944-consecration-img.jpg')`}
        width="100%"
        display="flex"
        bgRepeat="no-repeat"
        bgSize="cover"
        py="30px"
      >
        <Container
          maxW={{ base: '100%', md: '620px', xl: '1140px' }}
          position="relative"
        >
          <Grid
            templateColumns={{
              base: 'repeat(1, 1fr)',
              md: 'repeat(2, minmax(0, 1fr));',
              xl: 'repeat(4, minmax(0, 1fr));',
            }}
            gap={{ base: '10px', md: '40px', xl: 4 }}
            width="100%"
            alignItems="baseline"
          >
            {popUpData &&
              popUpData?.['msr-desktop-bottom']?.map((item, i) => (
                <Box
                  w="100%"
                  textAlign={{
                    base: 'left',
                    md: 'center',
                  }}
                  key={nanoid(4)}
                >
                  {' '}
                  {i % 2 === 0 ? (
                    <>
                      <Box
                        background="#EDE1C9"
                        padding="20px"
                        fontFamily="Open Sans"
                        mb="5"
                        textAlign="center"
                      >
                        <Text
                          width="100%"
                          lineHeight="1.2"
                          fontSize="28px"
                          fontFamily="FedraSansStd-book"
                          color="#000"
                          fontWeight="600"
                        >
                          {item?.title}
                        </Text>
                        <Text
                          color="#945607"
                          fontSize="23px"
                          mb="14px"
                          fontWeight="300"
                          textAlign="center"
                          lineHeight="23px"
                        >
                          <StructuredTextParser
                            str={render(item?.description)}
                            fontFamily="FedraSansStd-book"
                            textAlign="center"
                            fontSize="14px"
                          />
                        </Text>
                        <Button
                          color="#fff"
                          border="none"
                          borderRadius="5px"
                          width="100%"
                          wordBreak="break-word"
                          whiteSpace="normal"
                          h="auto"
                          letterSpacing=".2px"
                          fontSize={{ base: '14px' }}
                          padding={{ base: '0' }}
                          background={btnColorArr[i]}
                          marginTop={{ base: '26px', sm: '0' }}
                          opacity="1"
                          fontFamily="FedraSansStd-book"
                          _hover={{
                            background: btnColorArr[i],
                            opacity: '.8',
                            border: 'none',
                          }}
                          _active={{
                            background: btnColorArr[i],
                          }}
                        >
                          <Link
                            _hover={{ textDecoration: 'none' }}
                            href={item?.url}
                            padding={{ base: '16px 20px' }}
                            w="100%"
                          >
                            {item?.label}
                          </Link>
                        </Button>
                      </Box>
                      <Image
                        src={item?.image?.url}
                        alt={`${item.title} image`}
                        width="70px"
                        m="30px 0"
                      />
                    </>
                  ) : (
                    <>
                      <Image
                        src={item?.image?.url}
                        alt={`${item?.title} image`}
                        width="70px"
                        m="30px 0"
                      />
                      <Box
                        background="#EDE1C9"
                        padding="20px"
                        fontFamily="Open Sans"
                        mb="5"
                        textAlign="center"
                      >
                        <Text
                          width="100%"
                          lineHeight="1.2"
                          fontSize="28px"
                          fontFamily="FedraSansStd-book"
                          color="#000"
                          fontWeight="600"
                        >
                          {item?.title}
                        </Text>
                        <Text
                          color="#945607"
                          fontSize="23px"
                          mb="27px"
                          fontWeight="300"
                          textAlign="center"
                          lineHeight="23px"
                        >
                          <StructuredTextParser
                            str={render(item?.description)}
                            fontFamily="FedraSansStd-book"
                            textAlign="center"
                            fontSize="14px"
                          />
                        </Text>
                        <Button
                          color="#fff"
                          border="none"
                          borderRadius="5px"
                          width="100%"
                          h="auto"
                          wordBreak="break-word"
                          whiteSpace="normal"
                          letterSpacing=".2px"
                          fontSize={{ base: '14px' }}
                          padding={{ base: '0' }}
                          background={btnColorArr[i]}
                          marginTop={{ base: '26px', sm: '0' }}
                          opacity="1"
                          fontFamily="FedraSansStd-book"
                          _hover={{
                            background: btnColorArr[i],
                            opacity: '.8',
                            border: 'none',
                          }}
                          _active={{
                            background: btnColorArr[i],
                          }}
                        >
                          <Link
                            _hover={{ textDecoration: 'none' }}
                            href={item?.url}
                            padding={{ base: '16px 20px' }}
                            w="100%"
                          >
                            {item?.label}
                          </Link>
                        </Button>
                      </Box>
                    </>
                  )}
                </Box>
              ))}
          </Grid>
        </Container>
      </Box>
    ) : null;
  };

  const MsrMobileSticky = () => {
    return getDevice === 'msr-mobile' &&
      popUpData &&
      popUpData?.['msr-mobile-sticky'] ? (
      <Box
        pos="fixed"
        display="flex"
        zIndex="9"
        left="0"
        right="0"
        width="100%"
        maxH="58px"
        height="full"
        bottom={0}
      >
        {popUpData &&
          popUpData?.['msr-mobile-sticky']?.map((item, i) => (
            <Box
              w={`${100 / 3}%`}
              bgColor={mobileBgArr[i]}
              p="8px"
              height="100%"
              whiteSpace="break-spaces"
              textAlign="center"
              display="flex"
              alignItems="center"
              justifyContent="center"
              lineHeight="16px"
            >
              <Text
                color="#fff"
                fontSize="14px"
                fontFamily="FedraSansStd-medium"
                // height="100%"
              >
                <Link _hover={{ textDecoration: 'none' }} href={item?.url}>
                  {item?.label}
                </Link>
              </Text>
            </Box>
          ))}
      </Box>
    ) : (
      ''
    );
  };

  return popUpData?.status !== 'failure' ? (
    <>
      <MsrMobileSticky />
      <MsrDesktopBottom />
    </>
  ) : null;
};
