/* Built In Imports */
import { useContext, useEffect, useState } from 'react';

/* External Imports */
import { Box, Link } from '@chakra-ui/react';
import parser from 'html-react-parser';
import filter from 'lodash/filter';

/* Internal Imports */
/* Components */
import LazyLoadImageComponent from '@components/UI/LazyLoadImageComponent';
import { getCookie } from '@components/Utility/utils';
import config from '@config';
import { AuthContext } from '@store/auth-context';

/* Services */
/* Styles */
import classes from '@styles/components/BottomWidget.module.css';

/**
 * Renders the Bottom widget component
 *
 * @param {object} popUpData - content from api
 * @param {string} region - region and country
 * @returns {ReactElement} Bottom widget component
 */

const BottomWidget = ({
  region,
  lang,
  url,
  popUpData,
  popUpBlackListedJson,
}) => {
  const authContext = useContext(AuthContext);
  const bottomWidgetCookie = getCookie('bottom_widget');
  const [isClose, setIsClose] = useState(true);

  useEffect(() => {
    let finalUrls = [];
    // console.log('popUp start', popUpData);
    const timer = setTimeout(() => {
      if (
        popUpBlackListedJson.length &&
        popUpBlackListedJson[0]?.blacklisted_url?.length
      ) {
        finalUrls = filter(
          popUpBlackListedJson[0]?.blacklisted_url,
          function (urlObj) {
            return urlObj?.url === url?.split('?')[0]?.split('#')[0];
          }
        );
        if (!finalUrls.length) {
          finalUrls = filter(
            popUpBlackListedJson[0]?.blacklisted_starts_with,
            function (urlObj) {
              return url?.startsWith(urlObj?.url);
            }
          );
        }
      }

      // console.log('popUp mid', popUpData, finalUrls, bottomWidgetCookie);
      if (popUpData?.status === 'failure' || bottomWidgetCookie === 'false') {
        // console.log(
        //   'popUp status failure',
        //   popUpData,
        //   finalUrls,
        //   bottomWidgetCookie
        // );
        setIsClose(true);
      } else if (
        !finalUrls.length &&
        Array.isArray(popUpData) &&
        popUpData.length > 0
      ) {
        // console.log('popUpData', popUpData, finalUrls);
        setIsClose(false);
        if (bottomWidgetCookie === undefined) {
          authContext.setBottomWidgetCookie(true);
        }
      }
    }, 1000);
    // console.log('popUp ends', popUpData, finalUrls, bottomWidgetCookie);
    return () => clearTimeout(timer);
  }, [popUpData, authContext, popUpBlackListedJson]);

  const WidgetHandler = () => {
    setIsClose(true);
    authContext.setBottomWidgetCookie(false);
  };

  return (
    <Box
      float="left"
      w="auto"
      display={
        (bottomWidgetCookie &&
          bottomWidgetCookie === 'false' &&
          authContext.region == 'in') ||
        isClose
          ? 'none'
          : 'inherit'
      }
    >
      <Box
        pos="fixed"
        display={{ base: 'block', sm: 'block', md: 'block', lg: 'block' }}
        w={{ base: '84%', md: '307px', lg: '307px' }}
        // w="307px"
        borderRadius="7px 7px 0 0"
        boxShadow="0 8px 9px 0 rgb(0 0 0 / 50%)"
        textAlign="center"
        bottom="0"
        right={{ base: '0', sm: '120px' }}
        bgColor={popUpData?.[0]?.backgroundColor?.hex}
        zIndex="99"
        minHeight={{ base: '105px', md: 'auto' }}
        left={{ base: '8%', md: 'inherit' }}
        // className={`${classes["bengaluru-popup"]} ${
        //   isClose ? classes.slideDown : ""
        // }`}
      >
        <Box
          pos="absolute"
          top="-13px"
          right="-5px"
          bgImage={`url('${config.imgPath}/d/46272/1663645892-ion-android-cancel-ionicons.png')`}
          w="30px"
          h="30px"
          bgRepeat="no-repeat"
          bgPosition="center"
          bgSize="30px"
          cursor="pointer"
          borderRadius="100%"
          display={{ base: 'none', md: 'none' }}
          onClick={() => {
            WidgetHandler();
          }}
        >
          &nbsp;
        </Box>
        <Box
          w={{ base: 'unset', md: 'unset' }}
          h={{ base: '186px', md: '186px' }}
          m="14px"
          float={{ base: 'none', md: 'left' }}
          borderRadius={{ base: '0px 0px 0px 0px', md: 'inherit' }}
          alignSelf={{ base: 'unset', sm: 'unset', md: 'unset', lg: 'unset' }}
          className={classes['bengaluru-popup-img']}
        >
          {' '}
          <Box display={{ base: 'block', md: 'block', lg: 'block' }}>
            <Link href={popUpData?.[0]?.buttonCtaLink}>
              <LazyLoadImageComponent
                alt={popUpData?.[0]?.bannerImage?.alt}
                title={
                  popUpData?.[0]?.bannerImage?.title || popUpData?.[0]?.title
                }
                data-entity-type="file"
                data-entity-uuid="3745e261-c945-4fcd-85de-99a4c6f5e4a7"
                src={popUpData?.[0]?.bannerImage?.url}
                textAlign="center"
              />
            </Link>
          </Box>
          <Box
            display={{ base: 'none', sm: 'none', md: 'none', lg: 'none' }}
            w="85%"
          >
            {parser(popUpData?.[0]?.mobileCtaText || '')}
          </Box>
        </Box>

        <Box
          float="left"
          w={{ base: '100%', md: '100%' }}
          bgColor="#000000"
          m={{ base: '0 auto' }}
          // mt="0"
          p={{ base: '0 15px', md: '0 20px' }}
          mb="10px"
          alignSelf={{ base: 'unset', sm: 'unset', md: 'unset', lg: 'unset' }}
        >
          <Link
            isExternal
            float={{ base: 'left', md: 'left' }}
            w={{ base: '174px', sm: '174px', md: '174px', lg: '174px' }}
            h={{ base: '47px', md: '32px' }}
            fontFamily="FedraSansStd-medium"
            fontSize="14px"
            textAlign="center"
            color={popUpData?.[0]?.buttonTextColor?.hex}
            p="0"
            lineHeight={{ base: '45px', md: '32px' }}
            textDecoration="none !important"
            bgColor={popUpData?.[0]?.buttonBackgroundColor?.hex}
            borderRadius="5px"
            mt={{ base: '0', md: '0' }}
            mb={{ base: '10px' }}
            href={popUpData?.[0]?.buttonCtaLink}
            display={{ base: 'block' }}
          >
            {popUpData?.[0]?.buttonCtaText}
          </Link>
          <Link
            w="70px"
            fontFamily="FedraSansStd-book"
            fontSize="16px"
            // fontWeight="normal"
            fontStyle="normal"
            letterSpacing="normal"
            textAlign="right"
            float="right"
            color="#ffffff !important"
            p={{ base: '10px 24px 10px 0', md: '4px 0' }}
            cursor="pointer"
            d={{ base: 'inherit', md: 'inherit' }}
            onClick={WidgetHandler}
            textDecoration="none !important"
            display={{ base: 'block', md: 'block' }}
          >
            Close
          </Link>
        </Box>
      </Box>
      <style jsx global>
        {`
          .popup-widget {
            float: left;
            text-align: left;
            color: #fff;
            font-size: 16px;
          }
          .popup-widget-span {
            font-size: 18px;
          }
          .popup-widget-bold {
            font-size: 18px;
          }
        `}
      </style>
    </Box>
  );
};

export default BottomWidget;
