/* Built In Imports */
import dynamic from 'next/dynamic';
import { useRouter } from 'next/router';
import { useContext, useEffect, useState } from 'react';
import { useMediaQuery } from 'react-responsive';

/* External Imports */
import { Box } from '@chakra-ui/react';

/* Internal Imports */
/* Components */
import config from '@config';
import Footer from '@msr/components/Footer';
import Header from '@msr/components/Header';
import { SubFooterList } from '@msr/components/UI/SubFooterList';
import { SubHeaderList } from '@msr/components/UI/SubHeaderList';
import { AuthContext } from '@store/auth-context';

/* Services */
import { fetchPopUpData } from 'services/msrService';

const ScrollToTop = dynamic(() => import('@components/Utility/ScrollToTop'));

const WithPreview = dynamic(() => import('@msr/components/PreviewMode'));

/**
 * Renders the Layout component.
 *
 * @param children.children
 * @param {object} children - Child Elements.
 * @returns {ReactElement} Layout component
 */
export default function MsrLayout({ children }) {
  const {
    headerMenuData,
    pageData,
    footerData,
    footerDetailData,
    region,
    lang,
    isPreview,
    headerVisible = true,
    page,
    secondMenuVisible = true,
    hideTopMenu = false,
  } = children.props;
  let pageConfig = '';

  const [currentUserRegion, setCurrentUserRegion] = useState(region);

  const [isClientApp, setIsClientApp] = useState(false);
  const [showHeader] = useState(headerVisible);

  const authContext = useContext(AuthContext);
  const [popUpData, setPopUpData] = useState('');
  const router = useRouter();
  const { client } = router.query;
  const url = router.asPath;
  const path = url?.split('?')?.[0];
  const isMobile = useMediaQuery({ maxWidth: 767 });
  const language = lang;

  useEffect(() => {
    let currentRegion = config.locales.find(
      locale => locale.region_sf === region
    );
    authContext.RegionAndLang(region, language);
    if (currentRegion) {
      setCurrentUserRegion(currentRegion.region);
    }
  }, [authContext, region, language]);

  useEffect(() => {
    if (
      router.asPath.includes('client=sadhguru-app') ||
      client === 'sadhguru-app'
    ) {
      setIsClientApp(true);
    }
  }, [client, router.asPath]);

  useEffect(() => {
    const data = fetchPopUpData(url, isMobile);
    data.then(res => {
      setPopUpData(res);
    });
  }, [path]);

  return (
    <Box className={`lang-${language || 'en'}`}>
      {/* header section */}
      <SubHeaderList
        popUpData={popUpData}
        getDevice={isMobile ? 'msr-mobile' : 'msr-desktop'}
        lang={language}
      />
      {!isClientApp && showHeader && pageConfig?.header != 'disable' ? (
        <Box pos="relative" float="left" w="100%">
          <Header
            headerMenuData={headerMenuData}
            availableLocals={pageData && pageData.availableLocales}
            currentUserRegion={currentUserRegion}
            setCurrentUserRegion={val => setCurrentUserRegion(val)}
            region={region}
            language={language}
            page={page}
            secondMenuVisible={secondMenuVisible}
            hideTopMenu={hideTopMenu}
            languageAlsoin={pageData?.alsoin}
            pageUrl={pageData?.url}
          />
        </Box>
      ) : (
        ''
      )}
      {/* main content to render */}
      {children}
      {/* Footer section */}
      {
        <SubFooterList
          popUpData={popUpData}
          getDevice={isMobile ? 'msr-mobile' : 'msr-desktop'}
        />
      }
      {!isClientApp && (footerData || footerDetailData) && (
        <Box pos="relative" float="left" w="100%">
          <Footer
            region={region}
            language={language}
            footerData={footerData}
            pageConfig={pageConfig}
            page={page}
          />
        </Box>
      )}
      {isPreview && <WithPreview isPreview={isPreview} />}
      <ScrollToTop />
    </Box>
  );
}
